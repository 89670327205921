export const handleJsonResponse = (
  response,
  successCallback,
  errorCallback
) => {
  if (response.ok) {
    response
      .json()
      .then((data) => successCallback(data))
      .catch((error) => errorCallback(error));
  } else {
    response
      .json()
      .then((data) => errorCallback(data.error))
      .catch((error) => errorCallback(error));
  }
};

export const handleBlobResponse = (
  response,
  successCallback,
  errorCallback
) => {
  if (response.ok) {
    response
      .blob()
      .then((data) => successCallback(data))
      .catch((error) => errorCallback(error));
  } else {
    errorCallback(response.statusText);
  }
};
