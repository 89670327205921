import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import LabelledOutline from "./general/LabelledOutline";

class ExternalAuthorManagement extends Component {
  state = {
    users: [],
    new_user_email: "",
  };

  componentDidMount() {
    fetch("/api/user")
      .then((response) => response.json())
      .then((user) => {
        if (user.external) {
          this.props.history.push("/login");
        } else {
          this.fetchUsers();
        }
      });
  }

  fetchUsers = () => {
    fetch("/api/external-users")
      .then((response) => response.json())
      .then((users) => this.setState({ users }));
  };

  addUser = () => {
    fetch("/api/external-users", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: this.state.new_user_email }),
    }).then(() => {
      this.setState({ new_user_email: "" });
      this.fetchUsers();
    });
  };

  deleteUser(user) {
    fetch(`/api/external-user/${user.id}`, {
      method: "DELETE",
    }).then(() => {
      this.fetchUsers();
    });
  }

  render() {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <AppBar position="static">
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <IconButton
                color="inherit"
                onClick={() => this.props.history.push("/")}
              >
                <ArrowBackIcon />
              </IconButton>
            </Box>
            <Typography
              variant="h5"
              style={{ width: "100%", textAlign: "center" }}
            >
              Manage External Authors
            </Typography>
          </Toolbar>
        </AppBar>
        {this.state.users.length > 0 && (
          <Box p={2}>
            <LabelledOutline label="Currently Authorized">
              <Box minWidth="400px">
                <List>
                  {this.state.users.map((user) => {
                    return (
                      <ListItem key={user.id}>
                        {user.email}

                        <ListItemSecondaryAction>
                          <Tooltip title="Revoke access">
                            <IconButton
                              edge="end"
                              onClick={() => this.deleteUser(user)}
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </LabelledOutline>
          </Box>
        )}
        <Box display="flex" alignItems="center">
          <TextField
            size={"small"}
            value={this.state.new_user_email}
            variant="outlined"
            label="External email address"
            onChange={(event) =>
              this.setState({ new_user_email: event.target.value })
            }
          />
          <Box p={1}>
            <Button
              color="primary"
              variant="contained"
              disabled={!this.state.new_user_email}
              onClick={this.addUser}
            >
              Add User
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default withRouter(ExternalAuthorManagement);
