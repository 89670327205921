import React, { useState, useEffect } from "react";

import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Grid,
  Container,
} from "@mui/material";

import DatasetVersionDownloadButton from "./DatasetVersionDownloadButton";

import SearchableTable from "../general/SearchableTable";
import DatasetUploadModal from "./DatasetUploadModal";
import { fetchDatasetVersions } from "../api/datasets";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const COLUMNS = [
  {
    key: "hash",
    label: "Github hash",
    type: "text",
  },
  {
    key: "files",
    label: "Files",
    type: "component",
    render: (datasetVersion) =>
      datasetVersion.files.map((file) => file.file_name).join(", "),
  },
  {
    key: "changelog",
    label: "Description",
    type: "text",
  },
  {
    key: "lessons",
    label: "Used on the following lessons",
    type: "component",
    render: (datasetVersion) => datasetVersion.lesson_sequences.join(", "),
  },
  {
    key: "upload_date",
    label: "Upload date",
    type: "component",
    render: (datasetVersion) => {
      const date = new Date(datasetVersion.created);
      return date.toLocaleString();
    },
  },
  {
    key: "download",
    label: "",
    type: "component",
    render: (datasetVersion) => {
      return <DatasetVersionDownloadButton datasetVersion={datasetVersion} />;
    },
  },
];

function DatasetVersionList(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [datasetVersions, setDatasetVersions] = useState([]);
  const [datasetName, setDatasetName] = useState("");
  const [datasetModalState, setDatasetModalState] = useState({
    isOpen: false,
    datasetId: null,
  });

  const loadDatasetVersions = () => {
    const datasetId = props.match.params.datasetId;
    setIsLoading(true);
    fetchDatasetVersions(
      datasetId,
      (datasetVersions) => {
        setIsLoading(false);
        setDatasetName(datasetVersions[0].name);
        setDatasetVersions(
          datasetVersions.sort((d1, d2) => {
            const date1 = new Date(d1.created);
            const date2 = new Date(d2.created);
            return date1 < date2 ? 1 : date1 > date2 ? -1 : 0;
          })
        );
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  useEffect(() => {
    loadDatasetVersions();
  }, []);

  return (
    <div>
      <AppBar position="static">
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Button
              style={{ color: "white" }}
              startIcon={<ArrowBackIcon />}
              onClick={() => props.history.push(`/datasets`)}
            >
              Datasets
            </Button>
          </Box>
          <Typography
            variant="h5"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {datasetName}
          </Typography>
          <div />
        </Toolbar>
      </AppBar>

      <Container>
        <DatasetUploadModal
          close={() => setDatasetModalState({ isOpen: false, datasetId: null })}
          isOpen={datasetModalState.isOpen}
          datasetId={datasetModalState.datasetId}
          successCallback={loadDatasetVersions}
        />

        <Box my={5}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography variant="h4">Dataset Versions</Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  setDatasetModalState({
                    isOpen: true,
                    datasetId: parseInt(props.match.params.datasetId),
                  })
                }
              >
                Upload New Version
              </Button>
            </Grid>
          </Grid>
        </Box>

        <SearchableTable
          cols={COLUMNS}
          rows={datasetVersions}
          isLoading={isLoading}
          rowsPerPage={10}
        />
      </Container>
    </div>
  );
}

export default DatasetVersionList;
