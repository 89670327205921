import React, { useState, useEffect } from "react";
import PageLayout from "../general/PageLayout";
import { Box, Alert } from "@mui/material";
import CourseTracks from "./CourseTracks";
import CourseLessons from "./CourseLessons";
import Loader from "../general/Loader";
import CourseRelease from "./CourseRelease";
import CourseMetadata from "./CourseMetadata";

export default function Course(props) {
  const courseId = props.match.params.courseId;
  const [isLoading, setIsLoading] = useState(false);
  const [course, setCourse] = useState();
  // We store the page title so that when the course data is updated the page title remains the same
  const [pageTitle, setPageTitle] = useState("");
  const [error, setError] = useState("");

  const getCourse = async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await fetch(`/api/course/${courseId}`);
      const course = await response.json();
      if (course.error) {
        setError(course.error);
      } else {
        setPageTitle(`${course.sequence} - ${course.name}`);
        setCourse(course);
      }
    } catch (error) {
      console.log(error);
      setError("Could not load course data. Try again later.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCourse();
  }, []);

  const renderCourse = () => {
    if (error) {
      return <Alert severity="error">{error}</Alert>;
    }
    if (!course || isLoading) {
      return (
        <Box mt={3}>
          <Loader loadMessage="Loading Course..." />
        </Box>
      );
    }
    return (
      <Box>
        {course.is_main && (
          <Alert severity="info">This course is read only.</Alert>
        )}
        <Box mt={3}>
          <CourseRelease course={course} />
        </Box>
        <Box mt={3}>
          <CourseTracks course={course} />
        </Box>
        <Box mt={3}>
          <CourseMetadata course={course} reloadCourse={getCourse} />
        </Box>
        <Box mt={3}>
          <CourseLessons course={course} />
        </Box>
      </Box>
    );
  };

  return (
    <PageLayout previousPath="/courses" title={pageTitle} isLoading={isLoading}>
      {renderCourse()}
    </PageLayout>
  );
}
