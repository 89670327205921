import React from "react";
import { Box, Stack } from "@mui/material";
import LessonMenu from "./LessonMenu";
import LessonShallowImport from "./LessonShallowImport";
import LessonConfiguration from "./LessonConfiguration";
import withLessonData from "../HOC/withLessonData";

function Lesson({ lesson }) {
  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <LessonMenu title={`Lesson ${lesson.sequence}`} save={() => {}} />
      <Stack width="100%">
        <LessonShallowImport lesson={lesson} />
        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection={{ xs: "column", md: "row" }}
          px={{ xs: 1, md: 0 }}
          mx={{ xs: 1, sm: 2, md: 6, lg: 10 }}
          mb={{ xs: 5, md: 3 }}
        >
          <LessonConfiguration lesson={lesson} />
        </Box>
      </Stack>
    </Box>
  );
}

export default withLessonData(Lesson);
