import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  AppBar,
  Toolbar,
  Button,
  Typography,
  Container,
  Skeleton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import User from "../User";

export default function PageLayout(props) {
  const history = useHistory();

  const renderTitle = () => {
    if (props.isLoading) {
      return (
        <Typography variant="h5">
          <Skeleton width="30vw" />
        </Typography>
      );
    }
    return (
      <Typography
        variant="h5"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {props.title}
      </Typography>
    );
  };

  return (
    <Box pb={4}>
      <AppBar position="static">
        <Toolbar style={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              position: "absolute",
              left: "1rem",
            }}
          >
            <Button
              style={{ color: "white" }}
              startIcon={<ArrowBackIcon />}
              onClick={() => history.push(props.previousPath)}
            >
              {props.previousLabel}
            </Button>
          </Box>
          {renderTitle()}
          <Box
            sx={{
              position: "absolute",
              right: "1rem",
            }}
          >
            <User />
          </Box>
        </Toolbar>
      </AppBar>

      <Container sx={{ mt: 2 }}>{props.children}</Container>
    </Box>
  );
}

PageLayout.propTypes = {
  previousLabel: PropTypes.string,
  previousPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

PageLayout.defaultProps = {
  previousLabel: "Back",
  isLoading: false,
};
