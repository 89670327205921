import React, { useState, useEffect } from "react";
import {
  TextField,
  IconButton,
  Typography,
  Chip,
  Grid,
  Box,
  Avatar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useDispatch } from "react-redux";
import { answerCheckerActions } from "../../store/answerCheckerSlice";
import { getListValueType } from "../../utils/pycheck";
import GenericInput from "./GenericInput";

export default function ListInput(props) {
  const [values, setValues] = useState([]);
  const [value, setValue] = useState("");
  const [valuePython, setValuePython] = useState("");

  const deleteValueAtIndex = (indexToRemove) => {
    const newValues = values.filter((value, index) => index !== indexToRemove);
    setValues(newValues);
    props.valueUpdatedCallback(newValues, convertToPython(newValues));
  };

  const deleteAllValues = () => {
    setValues([]);
    props.valueUpdatedCallback([], convertToPython([]));
  };

  const addValue = () => {
    const newValues = [...values, valuePython];
    setValues(newValues);
    props.valueUpdatedCallback(newValues, convertToPython(newValues));
    setValue("");
    setValuePython("");
  };

  const renderValues = () => {
    if (values.length === 0) {
      return (
        <Typography variant="overline" display="inline">
          Empty list
        </Typography>
      );
    }
    return values.map((value, index) => (
      <Chip
        key={`value-${index}`}
        size="small"
        avatar={<Avatar>{index}</Avatar>}
        label={value}
        onDelete={() => deleteValueAtIndex(index)}
        color="primary"
        variant="outlined"
      />
    ));
  };

  const convertToPython = (values) => {
    return `[${values.join(",")}]`;
  };

  const updateValue = (value, valuePython) => {
    setValue(value);
    setValuePython(valuePython);
  };

  const valueType = getListValueType(props.type);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={1}>{renderValues()}</Box>
      </Grid>
      <Grid item xs={10}>
        <GenericInput
          label="List element"
          value={value}
          type={valueType}
          valueUpdatedCallback={updateValue}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={() => addValue()} color="primary">
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={() => deleteAllValues()} color="secondary">
          <DeleteForeverIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
