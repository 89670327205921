import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Chip,
  Card,
  CardContent,
  IconButton,
  CardActions,
  CardHeader,
  Typography,
  Divider,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ForumIcon from "@mui/icons-material/Forum";
import TextFieldMarkdownEditor from "../general/TextFieldMarkdownEditor";
import LabelledOutline from "../general/LabelledOutline";
import SendIcon from "@mui/icons-material/Send";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { timestampToString } from "../utils";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "../general/ConfirmModal";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { useHistory, useParams } from "react-router-dom";

function Comment(props) {
  let history = useHistory();

  const params = useParams();

  const [showThread, setShowThread] = useState(false);
  const [collapsed, setCollaped] = useState(false);
  const [message, setMessage] = useState("");
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);

  const Message = (props) => (
    <Box my={1}>
      <Typography variant="subtitle2" display="inline">
        {props.message.user.name}
      </Typography>
      <Typography variant="caption" display="inline">
        {" "}
        {timestampToString(props.message.timestamp)}
      </Typography>
      <ReactMarkdown
        children={props.message.message}
        remarkPlugins={[remarkGfm]}
      />
    </Box>
  );

  const renderLabels = () => {
    return (
      <Grid container direction="row" spacing={1}>
        {props.comment.tags.map((tag) => (
          <Grid item key={tag}>
            <Chip label={tag} variant="outlined" color={"primary"} />
          </Grid>
        ))}
      </Grid>
    );
  };

  const replyHandler = () => {
    props.commentOnThreadHandler(props.comment, message);
    setMessage("");
  };

  const renderThread = () => {
    if (!showThread) return null;
    return (
      <LabelledOutline label="Thread">
        {props.comment.thread
          .sort((m1, m2) => new Date(m1.timestamp) - new Date(m2.timestamp))
          .map((message) => (
            <Message key={message.id} message={message} />
          ))}
        <Box mb={2}>
          <Divider light />
        </Box>
        <TextFieldMarkdownEditor
          code={message}
          updateCode={(newMessage) => setMessage(newMessage)}
        />
        <Grid container justifyContent="flex-end">
          <Button
            endIcon={<SendIcon />}
            onClick={replyHandler}
            disabled={message.trim().length === 0}
          >
            Reply
          </Button>
        </Grid>
      </LabelledOutline>
    );
  };

  const collapseHandler = () => {
    if (!collapsed) setShowThread(false);
    setCollaped(!collapsed);
  };

  const renderStatus = () => {
    if (!props.comment.completed) {
      return null;
    }
    if (!props.comment.approved) {
      return (
        <Grid item>
          <DoneIcon color="primary" />
        </Grid>
      );
    }
    if (props.comment.approved) {
      return (
        <Grid item>
          <DoneAllIcon color="primary" />
        </Grid>
      );
    }
  };

  const renderCompleteButton = () => {
    if (props.user.id === props.comment.user.id) return null;
    if (props.comment.approved) return null;
    return (
      <Button
        variant="outlined"
        color={props.comment.completed ? "primary" : undefined}
        onClick={() => props.completeHandler(props.comment)}
        startIcon={props.comment.completed && <DoneIcon />}
      >
        {props.comment.completed ? "Completed" : "Complete"}
      </Button>
    );
  };

  const renderApproveButton = () => {
    if (props.user.id !== props.comment.user.id) return null;
    if (!props.comment.completed) return null;
    return (
      <Button
        variant="outlined"
        color={props.comment.approved ? "primary" : undefined}
        onClick={() => props.approveHandler(props.comment)}
        startIcon={props.comment.approved && <DoneIcon />}
      >
        {props.comment.approved ? "Approved" : "Approve"}
      </Button>
    );
  };

  const renderGoToScreenButton = () => {
    if (props.comment.sequence + "" === params.sequence) return null;
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          history.push(
            `/lesson/${params.lessonId}/screen/${props.comment.screen_id}`
          )
        }
      >
        Go to screen
      </Button>
    );
  };

  return (
    <Box my={1}>
      <ConfirmModal
        isOpen={confirmModalIsOpen}
        cancel={() => setConfirmModalIsOpen(false)}
        confirm={() => props.archiveCommentHandler(props.comment)}
      />
      <Card>
        <CardHeader
          title={
            <Grid container justifyContent="space-between">
              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  {renderStatus()}
                  <Grid item>{props.comment.user.name}</Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  {props.comment.mandatory && (
                    <Grid item>
                      <Chip label="Mandatory" color="secondary" />
                    </Grid>
                  )}
                  <Grid item>
                    <IconButton onClick={collapseHandler}>
                      {collapsed ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
          subheader={timestampToString(props.comment.timestamp)}
        />
        {!collapsed && (
          <>
            <CardContent>
              {renderLabels()}
              <ReactMarkdown
                children={props.comment.comment}
                remarkPlugins={[remarkGfm]}
              />
              {renderThread()}
            </CardContent>
            <CardActions>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        startIcon={<ForumIcon />}
                        onClick={() => setShowThread(!showThread)}
                      >
                        {showThread ? "Hide" : "Show"} Thread
                      </Button>
                    </Grid>
                    <Grid item>{renderCompleteButton()}</Grid>
                    <Grid item>{renderApproveButton()}</Grid>
                    <Grid item>{renderGoToScreenButton()}</Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  {props.user.id === props.comment.user.id && (
                    <IconButton
                      color="secondary"
                      onClick={() => setConfirmModalIsOpen(true)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </CardActions>
          </>
        )}
      </Card>
    </Box>
  );
}

export default Comment;
