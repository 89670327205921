import { handleJsonResponse } from "./generic";

export const getScreenComments = (screenId, successCallback, errorCallback) => {
  fetch(`/api/screen/${screenId}/comments`).then((response) =>
    handleJsonResponse(response, successCallback, errorCallback)
  );
};

export const getLessonComments = (lessonId, successCallback, errorCallback) => {
  fetch(`/api/lesson/${lessonId}/comments`).then((response) =>
    handleJsonResponse(response, successCallback, errorCallback)
  );
};

export const commentOnScreen = (
  screenId,
  comment,
  tags,
  mandatory,
  successCallback,
  errorCallback
) => {
  fetch(`/api/screen/${screenId}/comments`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      comment: comment,
      tags: tags,
      mandatory: mandatory,
    }),
  }).then((response) =>
    handleJsonResponse(response, successCallback, errorCallback)
  );
};

export const archiveScreenComment = (
  commentId,
  successCallback,
  errorCallback
) => {
  fetch(`/api/comments/archive/${commentId}`, {
    method: "PATCH",
  }).then((response) =>
    handleJsonResponse(response, successCallback, errorCallback)
  );
};

export const commentOnThread = (
  commentId,
  message,
  successCallback,
  errorCallback
) => {
  fetch(`/api/comments/${commentId}/thread`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      message: message,
    }),
  }).then((response) =>
    handleJsonResponse(response, successCallback, errorCallback)
  );
};

export const toggleCompelted = (commentId, successCallback, errorCallback) => {
  fetch(`/api/comments/complete/${commentId}`, {
    method: "PATCH",
  }).then((response) =>
    handleJsonResponse(response, successCallback, errorCallback)
  );
};

export const toggleApproved = (commentId, successCallback, errorCallback) => {
  fetch(`/api/comments/approve/${commentId}`, {
    method: "PATCH",
  }).then((response) =>
    handleJsonResponse(response, successCallback, errorCallback)
  );
};
