import React, { useState } from "react";
import {
  Stack,
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  IconButton,
  Badge,
  Alert,
  LinearProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import SkillLevelSelect from "../general/SkillLevelSelect";
import WysiwygEditor from "../general/WysiwygEditor";
import ListInput from "../general/ListInput";

export default function CourseMetadata(props) {
  const [course, setCourse] = useState(props.course);
  const [hasChanges, setHasChanges] = useState(false);
  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);

  const disabled = saving || course.is_main;

  const updateField = (field, value) => {
    setHasChanges(true);
    setCourse({ ...course, [field]: value });
  };

  const handleAddObjective = () => {
    setHasChanges(true);
    setCourse({ ...course, objectives: [...course.objectives, ""] });
  };

  const editObjective = (index, value) => {
    setHasChanges(true);
    const newObjectives = course.objectives.map((objective, i) =>
      i === index ? value : objective
    );
    setCourse({ ...course, objectives: newObjectives });
  };

  const deleteObjective = (index) => {
    setHasChanges(true);
    const newObjectives = course.objectives.filter(
      (objective, i) => i !== index
    );
    setCourse({ ...course, objectives: newObjectives });
  };

  const handleSaveChanges = async () => {
    try {
      setError("");
      setSaving(true);
      setHasChanges(false);
      const response = await fetch(`/api/course/${props.course.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: course.name,
          description: course.description,
          short_description: course.short_description,
          objectives: course.objectives,
          slug: course.slug,
          skill_level: course.skill_level,
          skill_requirements: course.skill_requirements,
          duration: course.duration,
          overview: course.overview,
          motivation: course.motivation,
          outcomes: course.outcomes,
          replaces_course: course.replaces_course,
          default_paths:
            course.default_paths
              ?.split(",")
              .map((v) => parseInt(v.trim(), 10)) || [],
        }),
      });
      const data = await response.json();
      if (data.error) {
        setError(data.error);
      }
      props.reloadCourse();
    } catch (error) {
      console.log(error);
      setError(
        "An error occured while trying to save changes. Try again later."
      );
    } finally {
      setSaving(false);
    }
  };

  const renderHeader = () => (
    <Stack direction="row" justifyContent="flex-end">
      <Badge color="secondary" variant="dot" invisible={!hasChanges}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveChanges}
          disabled={disabled}
        >
          Save Changes
        </Button>
      </Badge>
    </Stack>
  );

  const renderObjective = (index) => (
    <Stack direction="row" key={`objective-${index}`}>
      <TextField
        fullWidth
        value={course.objectives[index]}
        onChange={(event) => editObjective(index, event.target.value)}
        disabled={disabled}
        size="small"
      />
      <IconButton
        color="secondary"
        onClick={() => deleteObjective(index)}
        disabled={disabled}
        size="small"
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  );

  const renderObjectives = () => (
    <Stack direction="column" spacing={1}>
      {course.objectives.map((objective, index) => renderObjective(index))}
      <Button fullWidth onClick={handleAddObjective} disabled={disabled}>
        <AddIcon />
      </Button>
    </Stack>
  );

  const renderLeftForm = () => {
    return (
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography variant="subtitle2">Course Title</Typography>
          <TextField
            onChange={(event) => updateField("name", event.target.value)}
            value={course.name}
            fullWidth
            disabled={disabled}
            size="small"
          />
        </Box>
        <Box>
          <Typography variant="subtitle2">Description</Typography>
          <TextField
            onChange={(event) => updateField("description", event.target.value)}
            value={course.description}
            minRows={3}
            multiline
            fullWidth
            disabled={disabled}
            size="small"
          />
        </Box>
        <Box>
          <Typography variant="subtitle2">Short Description</Typography>
          <TextField
            onChange={(event) =>
              updateField("short_description", event.target.value)
            }
            value={course.short_description}
            minRows={3}
            multiline
            fullWidth
            disabled={disabled}
            size="small"
          />
        </Box>
        <Box>
          <Typography variant="subtitle2">Overview</Typography>
          <TextField
            onChange={(event) => updateField("overview", event.target.value)}
            value={course.overview}
            minRows={3}
            multiline
            fullWidth
            disabled={disabled}
            size="small"
          />
        </Box>
        <Box>
          <Typography variant="subtitle2">Course prerequisites</Typography>
          <ListInput
            handleOnChange={(value) => updateField("skill_requirements", value)}
            values={course.skill_requirements}
            disabled={disabled}
          />
          <Typography variant="subtitle2">Learning Objectives</Typography>
          {renderObjectives()}
        </Box>
      </Stack>
    );
  };

  const renderRightForm = () => {
    return (
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography variant="subtitle2">Page Slug</Typography>
          <TextField
            onChange={(event) => updateField("slug", event.target.value)}
            value={course.slug}
            fullWidth
            disabled={disabled}
            size="small"
          />
        </Box>
        <Box>
          <Typography variant="subtitle2">Level</Typography>
          <SkillLevelSelect
            fullWidth
            value={course.skill_level}
            onChange={(event) => updateField("skill_level", event.target.value)}
            disabled={disabled}
            size="small"
          />
        </Box>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Typography variant="subtitle2">
              Time Estimate to Completion (hours)
            </Typography>
            <TextField
              onChange={(event) => updateField("duration", event.target.value)}
              value={course.duration || 0}
              disabled={disabled}
              type="number"
              size="small"
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
          </Box>
          <Box>
            <Typography variant="subtitle2">Replaces Course</Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <TextField
                onChange={(event) =>
                  updateField("replaces_course", event.target.value)
                }
                value={course.replaces_course || ""}
                disabled={disabled}
                type="number"
                size="small"
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Stack>
          </Box>
        </Stack>
        <Box>
          <Typography variant="subtitle2">Default Paths</Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField
              onChange={(event) =>
                updateField("default_paths", event.target.value)
              }
              value={course.default_paths || ""}
              disabled={disabled}
              size="small"
            />
          </Stack>
        </Box>
        <Box>
          <Typography variant="subtitle2">Motivation</Typography>
          <WysiwygEditor
            initValue={course.motivation || ""}
            handleOnChange={(value) => updateField("motivation", value)}
            readOnly={disabled}
          />
        </Box>
        <Box>
          <Typography variant="subtitle2">Outcomes</Typography>
          <WysiwygEditor
            initValue={course.outcomes || ""}
            handleOnChange={(value) => updateField("outcomes", value)}
            readOnly={disabled}
          />
        </Box>
      </Stack>
    );
  };

  return (
    <Grid container mt={2}>
      <Grid item xs={12} px={1} mb={3}>
        {renderHeader()}
      </Grid>
      {saving && (
        <Grid item xs={12} px={1} mb={3}>
          <LinearProgress />
        </Grid>
      )}
      {error && (
        <Grid item xs={12} px={1} mb={3}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      <Grid item xs={6} px={1}>
        {renderLeftForm()}
      </Grid>
      <Grid item xs={6} px={1}>
        {renderRightForm()}
      </Grid>
    </Grid>
  );
}
