import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { AppBar, Toolbar } from "@mui/material";
import User from "./User";

const AppLayout = (props) => {
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    fetch("/api/user")
      .then((response) => response.json())
      .then((user) => {
        setUserInfo({ email: user.email, external: user.external });
      });
  }, ["userInfo"]);

  return (
    <div>
      <AppBar position="static">
        <Toolbar variant="dense" className="dq-flex dq-justify-between">
          <div className="dq-space-x-6 dq-uppercase dq-text-md">
            {!userInfo.external && (
              <NavLink
                to="/tracks"
                activeStyle={{ color: "white" }}
                activeClassName="dq-font-bold"
                isActive={(match, location) => match && match.url === "/tracks"}
                className="dq-text-gray-300 hover:dq-text-white dq-no-underline"
              >
                Paths
              </NavLink>
            )}
            <NavLink
              to="/courses"
              activeStyle={{ color: "white" }}
              activeClassName="dq-font-bold"
              isActive={(match, location) => match && match.url === "/courses"}
              className="dq-text-gray-300 hover:dq-text-white dq-no-underline"
            >
              Courses
            </NavLink>
            <NavLink
              to="/"
              activeStyle={{ color: "white" }}
              activeClassName="dq-font-bold"
              isActive={(match, location) => match && match.url === "/"}
              className="dq-text-gray-300 hover:dq-text-white dq-no-underline"
            >
              Lessons
            </NavLink>
            {!userInfo.external && (
              <NavLink
                to="/datasets"
                activeStyle={{ color: "white" }}
                activeClassName="dq-font-bold"
                className="dq-text-gray-300 hover:dq-text-white dq-no-underline"
              >
                Datasets
              </NavLink>
            )}
            {!userInfo.external && (
              <NavLink
                to="/releases"
                activeStyle={{ color: "white" }}
                activeClassName="dq-font-bold"
                className="dq-text-gray-300 hover:dq-text-white dq-no-underline"
              >
                Manage Releases
              </NavLink>
            )}
            {!userInfo.external && (
              <NavLink
                to="/manage-external"
                activeStyle={{ color: "white" }}
                activeClassName="dq-font-bold"
                className="dq-text-gray-300 hover:dq-text-white dq-no-underline"
              >
                Manage External Authors
              </NavLink>
            )}
          </div>
          <div>
            <User />
          </div>
        </Toolbar>
      </AppBar>
      <div className="dq-mt-6">{props.children}</div>
    </div>
  );
};

export default withRouter(AppLayout);
