import { useEffect, useState } from "react";
import PropTypes from "prop-types";

function LongRunTracker(props) {
  let interval;
  const {
    screenId,
    sessionId,
    codeRunId,
    runnerName,
    poolTimeMs,
    successRunCallback,
    errorRunCallback,
  } = props;
  const [isTracking, setIsTracking] = useState(true);

  const getCodeRunResult = async () => {
    if (!isTracking) return;
    try {
      const response = await fetch(
        `/api/code_run_results/${screenId}/${runnerName}/${sessionId}/${codeRunId}`
      );
      if (response.status !== 200) {
        throw new Error(
          `Could not fetch code run results (error ${response.status})`
        );
      }
      const data = await response.json();
      if (data.error) {
        setIsTracking(false);
        errorRunCallback(data.error);
      } else if (!data.status) {
        setIsTracking(false);
        successRunCallback(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isTracking) {
      clearInterval(interval);
    } else {
      getCodeRunResult();
      interval = setInterval(getCodeRunResult, poolTimeMs);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isTracking]);

  useEffect(() => {
    setIsTracking(true);
  }, [runnerName, sessionId, codeRunId]);

  return null;
}

LongRunTracker.propTypes = {
  screenId: PropTypes.number.isRequired,
  sessionId: PropTypes.string.isRequired,
  codeRunId: PropTypes.string.isRequired,
  runnerName: PropTypes.string.isRequired,
  successRunCallback: PropTypes.func.isRequired,
  errorRunCallback: PropTypes.func.isRequired,
  poolTimeMs: PropTypes.number,
};

LongRunTracker.defaultProps = {
  poolTimeMs: 10000,
};

export default LongRunTracker;
