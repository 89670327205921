import React, { useState } from "react";
import { Box, Typography, LinearProgress, Button, Alert } from "@mui/material";
import { LabelledOutline } from "../general/LabelledOutline";
import { GetApp as GetAppIcon } from "@mui/icons-material";
import fileSaver from "file-saver";

export default function LessonExport({ lesson }) {
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState("");

  const download = async () => {
    setDownloading(true);
    try {
      const response = await fetch(`/api/lesson/${lesson.id}/export`);
      const responseData = await response.blob();
      fileSaver.saveAs(responseData, `${lesson.sequence}.zip`);
    } catch (error) {
      console.log(error);
      setError("An error occurred. Try again later.");
    }
    setDownloading(false);
  };

  return (
    <LabelledOutline label="Export">
      {error && <Alert severity="error">{error}</Alert>}
      <Typography variant="caption">
        Download all files required to publish the lesson.
      </Typography>
      {downloading && <LinearProgress color="primary" />}
      <Button
        variant="outlined"
        color="primary"
        disabled={downloading || lesson.is_shallow_import}
        startIcon={<GetAppIcon />}
        onClick={download}
        fullWidth
        size="small"
      >
        Download zip
      </Button>
    </LabelledOutline>
  );
}
