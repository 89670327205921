import React, { useState } from "react";
import LabelledOutline from "../general/LabelledOutline";
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  TextField,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { lessonDataActions } from "../store/lessonDataSlice";

export default function LessonListInput({
  label,
  handleOnChange,
  values,
  disabled,
  lessonId = null,
  newValueLabel = "New Value",
}) {
  const dispatch = useDispatch();
  const [newValue, setNewValue] = useState("");

  const handleValueChange = (index) => (event) => {
    const value = values.map((value, i) =>
      i === index ? event.target.value : value
    );
    handleOnChange(value);
  };

  const handleDeleteValue = (index) => () => {
    const value = values.filter((value, i) => i !== index);
    handleOnChange(value);
  };

  const handleAddValue = () => {
    const value = values ? [...values, newValue] : [newValue];
    setNewValue("");
    handleOnChange(value);
    if (lessonId)
      dispatch(
        lessonDataActions.removeInProgressField({
          lessonId: lessonId,
          fieldName: label,
        })
      );
  };

  const handleNewValueChange = (event) => {
    setNewValue(event.target.value);
    if (lessonId)
      dispatch(
        lessonDataActions.addInProgressField({
          lessonId: lessonId,
          fieldName: label,
        })
      );
  };

  return (
    <LabelledOutline label={label}>
      <List>
        {values?.map((value, i) => {
          return (
            <ListItem key={`${value}-${i}`}>
              <TextField
                fullWidth
                autoFocus
                value={value}
                onChange={handleValueChange(i)}
                disabled={disabled}
                size="small"
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={handleDeleteValue(i)}
                  disabled={disabled}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
        <ListItem key="add-new">
          <TextField
            label={newValueLabel}
            fullWidth
            variant="outlined"
            value={newValue}
            onChange={handleNewValueChange}
            disabled={disabled}
            size="small"
          />
          <ListItemSecondaryAction>
            <Tooltip title="Add new">
              <IconButton
                edge="end"
                onClick={handleAddValue}
                disabled={disabled}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </LabelledOutline>
  );
}
