import React from "react";
import { Box, Typography } from "@mui/material";

const CodePreview = ({ name, code }) => {
  return (
    <Box>
      <Typography pl={1} component={Box} variant="body2">
        {name} code preview:
      </Typography>
      <Box className="code-preview">{code}</Box>
    </Box>
  );
};

export default CodePreview;
