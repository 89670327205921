import React from "react";
import { FormControlLabel, Slider } from "@mui/material";

const styles = {
  floatSlider: {
    width: "150px",
    marginRight: "10px",
    marginLeft: "10px",
  },
};

export default function FloatToleranceInput(props) {
  const FLOAT_ZERO = 8; // exponent k such that we replace 1e-k by 0

  const floatValueText = (value) => {
    return value === FLOAT_ZERO ? "0" : value === 0 ? "1" : `1e-${value}`;
  };

  const onChangeHandler = (event, newValue) => {
    props.valueUpdatedCallback(newValue, floatValueText(newValue));
  };

  return (
    <FormControlLabel
      control={
        <Slider
          value={props.value}
          valueLabelFormat={floatValueText}
          aria-labelledby="discrete-slider"
          step={1}
          marks
          min={0}
          max={FLOAT_ZERO}
          valueLabelDisplay="auto"
          onChange={onChangeHandler}
          sx={styles.floatSlider}
          valueLabelDisplay="on"
        />
      }
    />
  );
}
