import React from "react";
import StringInput from "./StringInput";
import CodeInput from "./CodeInput";
import {
  isStringType,
  isListType,
  isDictType,
  isFloatTolerance,
} from "../../utils/pycheck";
import DictionaryInput from "./DictionaryInput";
import FloatToleranceInput from "./FloatToleranceInput";
import ListInput from "./ListInput";

function GenericInput(props) {
  if (props.type && isStringType(props.type)) {
    return (
      <StringInput
        label={props.label}
        value={props.value}
        valueUpdatedCallback={props.valueUpdatedCallback}
      />
    );
  }

  if (props.type && isDictType(props.type)) {
    return (
      <DictionaryInput
        label={props.label}
        value={props.value}
        type={props.type}
        valueUpdatedCallback={props.valueUpdatedCallback}
      />
    );
  }

  if (props.argument && isFloatTolerance(props.argument)) {
    return (
      <FloatToleranceInput
        label={props.label}
        value={props.value}
        type={props.type}
        valueUpdatedCallback={props.valueUpdatedCallback}
      />
    );
  }

  if (props.type && isListType(props.type)) {
    return (
      <ListInput
        label={props.label}
        value={props.value}
        type={props.type}
        valueUpdatedCallback={props.valueUpdatedCallback}
      />
    );
  }

  return (
    <CodeInput
      label={props.label}
      value={props.value}
      valueUpdatedCallback={props.valueUpdatedCallback}
    />
  );
}

export default GenericInput;
