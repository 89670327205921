import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const assessmentSkillsSlice = createSlice({
  name: "assessmentSkillsSlice",
  initialState: initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setScreenSkills(state, action) {
      const screenId = action.payload.screenId;
      const skills = action.payload.skills;
      state[screenId] = skills;
    },
  },
});

export const assessmentSkillActions = assessmentSkillsSlice.actions;

export default assessmentSkillsSlice.reducer;
