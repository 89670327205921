import React, { useState, useEffect, useCallback } from "react";
import PageLayout from "../general/PageLayout";
import { Box, Alert } from "@mui/material";
import Loader from "../general/Loader";
import TrackRelease from "./TrackRelease";
import TrackMetadata from "./TrackMetadata";
import Steps from "./Steps";

export default function Track(props) {
  const trackId = props.match.params.trackId;
  const [isLoading, setIsLoading] = useState(false);
  const [track, setTrack] = useState();
  // We store the page title so that when the track data is updated the page title remains the same
  const [pageTitle, setPageTitle] = useState("");
  const [error, setError] = useState("");

  const getTrack = useCallback(async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await fetch(`/api/track/${trackId}`);
      const track = await response.json();
      if (track.error) {
        setError(track.error);
      } else {
        setPageTitle(`${track.sequence} - ${track.name}`);
        setTrack(track);
      }
    } catch (error) {
      console.log(error);
      setError("Could not load the path data. Try again later.");
    }
    setIsLoading(false);
  }, [trackId]);

  useEffect(() => {
    getTrack();
  }, [getTrack]);

  const renderTrack = () => {
    if (error) {
      return <Alert severity="error">{error}</Alert>;
    }
    if (!track || isLoading) {
      return (
        <Box mt={3}>
          <Loader loadMessage="Loading Path..." />
        </Box>
      );
    }
    return (
      <Box>
        {track.is_main && (
          <Alert severity="info">This path is read only.</Alert>
        )}
        <Box mt={3}>
          <TrackRelease track={track} />
        </Box>
        <Box mt={3}>
          <TrackMetadata track={track} reloadTrack={getTrack} />
        </Box>
        <Box mt={3}>
          <Steps track={track} />
        </Box>
      </Box>
    );
  };

  return (
    <PageLayout previousPath="/tracks" title={pageTitle} isLoading={isLoading}>
      {renderTrack()}
    </PageLayout>
  );
}
