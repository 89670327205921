import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Typography, Stack, Skeleton, Alert } from "@mui/material";

export default function TrackRelease(props) {
  const [release, setRelease] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const getTrackRelease = useCallback(async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await fetch(`/api/release/${props.track.release_id}`);
      const release = await response.json();
      if (release.error) {
        setError(release.error);
      } else {
        setRelease(release);
      }
    } catch (error) {
      console.log(error);
      setError(
        "Could not load the relase data for this path. Try again later."
      );
    }
    setIsLoading(false);
  }, [props.track.release_id]);

  useEffect(() => {
    getTrackRelease();
  }, [getTrackRelease]);

  const renderRelease = () => {
    if (error) {
      return <Alert severity="error">{error}</Alert>;
    }
    if (isLoading) {
      return <Skeleton variant="text" width={200} display="inline-block" />;
    }
    return (
      <Typography display="inline-block">{release.version_major}</Typography>
    );
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography variant="h6" display="inline-block">
        Release:
      </Typography>
      {renderRelease()}
    </Stack>
  );
}

TrackRelease.propTypes = {
  track: PropTypes.object,
};

TrackRelease.defaultProps = {};
