import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, Alert, Stack } from "@mui/material";
import Loader from "../general/Loader";
import Step from "./Step";
import NewEntryForm from "../general/NewEntryForm";

export default function Steps(props) {
  const track = props.track;

  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState([]);
  const [error, setError] = useState("");

  const loadSteps = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(`/api/track/${track.id}/steps`);
      const steps = await response.json();
      setSteps(steps);
    } catch (error) {
      setError("Could not load the steps for this path. Try again later.");
    }
    setLoading(false);
  }, [track.id]);

  useEffect(() => {
    loadSteps();
  }, [loadSteps]);

  const renderSteps = () => {
    if (loading || !steps) {
      return <Loader loadMessage="Loading path steps..." />;
    }
    return (
      <Stack direction="column" spacing={1}>
        {error && <Alert severity="error">{error}</Alert>}
        {steps.map((step, index) => (
          <Step
            key={`step-${step.id}`}
            track={track}
            numberOfSteps={steps.length}
            step={step}
            index={index}
            reloadSteps={loadSteps}
          />
        ))}
      </Stack>
    );
  };

  const handleAddStep = async (_, name) => {
    try {
      let response = await fetch(`/api/step`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          release_id: track.release_id,
        }),
      });
      const step = await response.json();
      if (step.error) {
        setError(step.error);
      } else {
        response = await fetch(`/api/track/${track.id}/step/${step.id}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        });
        const steps = await response.json();
        if (steps.error) {
          setError(steps.error);
        } else {
          setSteps(steps);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 1 }}
      >
        <Typography variant="h6">Path Outline</Typography>
        <NewEntryForm
          variant="outlined"
          newLabel="New Step"
          addLabel="Add Step"
          nameLabel="Step Name"
          handleAdd={handleAddStep}
          isNumbered={false}
          disabled={track.is_main}
        />
      </Stack>

      <Stack direction="column" spacing={1}>
        {renderSteps()}
      </Stack>
    </Box>
  );
}
