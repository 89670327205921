import React, { useState } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import LabelledOutline from "../general/LabelledOutline";

import {
  Alert,
  Grid,
  LinearProgress,
  Button,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  ButtonGroup,
  TableBody,
  Typography,
  TextField,
} from "@mui/material";

import ReactMarkdown from "react-markdown";

function ScreenTest(props) {
  const [loading, setLoading] = useState(false);
  const [runResult, setRunResult] = useState();
  const [description, setDescription] = useState(props.screenTest.description);

  const handleRun = () => {
    setLoading(true);
    setRunResult();
    props.runCodeWithCallback(
      props.screenTest.code,
      (result) => {
        setLoading(false);
        setRunResult(formatRunResult(result));
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  };

  const formatRunResult = (result) => {
    return {
      test_pass: result.checks.pass,
      results: result.checks.results.map((res) => ({
        checker_id: res.checker_id,
        tag_id: res.tag_id,
        feedback: res.feedback,
        hint: res.hint,
        num_feedback: res.feedback.length,
        num_hints: res.hint.length,
        has_custom_feedback: res.has_custom_feedback,
        has_custom_hints: res.has_custom_hints,
      })),
    };
  };

  const runResultMatchesTestResult = () => {
    if (runResult.test_pass !== props.screenTest.test_pass) return false;

    if (props.screenTest.is_boolean_test) return true;

    if (runResult.results.length !== props.screenTest.results.length)
      return false;

    let matched = 0;
    runResult.results.forEach((runRes) => {
      props.screenTest.results.forEach((testRes) => {
        let matches = true;
        Object.keys(runRes)
          .filter(
            (attribute) => attribute !== "hint" && attribute !== "feedback"
          )
          .forEach((attribute) => {
            if (runRes[attribute] !== testRes[attribute]) {
              matches = false;
            }
          });
        if (matches) {
          matched += 1;
        }
      });
    });
    return matched === runResult.results.length;
  };

  const handleDelete = () => {
    setLoading(true);
    props.deleteTest(props.screenTest.id, () => {
      setLoading(false);
    });
  };

  const handleUpdateTest = () => {
    props.updateTest(description, runResult);
    setRunResult();
  };

  const processFeedback = (feedback) => {
    if (typeof feedback === "string") {
      return feedback;
    }
    return feedback.join("\n");
  };

  const renderFeedback = (resultData) => {
    if (!resultData) return null;
    if (resultData.test_pass) {
      return <ReactMarkdown>Correct!</ReactMarkdown>;
    }
    return resultData.results.map((result, index) => (
      <Box key={`pycheck-feedback-${index}`}>
        <ReactMarkdown>{processFeedback(result.feedback)}</ReactMarkdown>
        <ReactMarkdown>{processFeedback(result.hint)}</ReactMarkdown>
      </Box>
    ));
  };

  const renderResults = (label, resultData) => {
    if (props.screenTest.is_boolean_test) {
      return null;
    }

    return (
      <Box my={2}>
        <LabelledOutline label={label}>
          <Box m={1}>
            <Typography variant="subtitle2">Feedback</Typography>
            <Paper elevation={1} square>
              <Box p={1}>{renderFeedback(resultData)}</Box>
            </Paper>
          </Box>
          {!resultData.test_pass && (
            <Box m={1}>
              <Typography variant="subtitle2">Results</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Checker Id</TableCell>
                      <TableCell>Tag Id</TableCell>
                      <TableCell>Feedback count</TableCell>
                      <TableCell>Hint count</TableCell>
                      <TableCell>Has custom feedback?</TableCell>
                      <TableCell>Has custom hints?</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resultData.results.map((result, index) => (
                      <TableRow key={`pycheck-result-${index}`}>
                        <TableCell align="left">{result.checker_id}</TableCell>
                        <TableCell align="left">{result.tag_id}</TableCell>
                        <TableCell align="left">
                          {result.num_feedback}
                        </TableCell>
                        <TableCell align="left">{result.num_hints}</TableCell>
                        <TableCell align="left">
                          {result.has_custom_feedback ? "Yes" : "No"}
                        </TableCell>
                        <TableCell align="left">
                          {result.has_custom_hints ? "Yes" : "No"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </LabelledOutline>
      </Box>
    );
  };

  const renderRunResult = () => {
    if (runResultMatchesTestResult()) {
      return (
        <Alert severity="success">
          The run result matches the test result!
        </Alert>
      );
    }
    return (
      <>
        <Alert severity="warning">
          The run result doesn't match the test result. Here's the new result:
        </Alert>
        {renderPass("Run Answer Verdict", runResult)}
        {!props.screenTest.is_boolean_test &&
          renderResults("Run Result", runResult)}
      </>
    );
  };

  const renderDescription = () => {
    if (props.screenTest.is_default) {
      return (
        <Box mb={2}>
          <LabelledOutline label={"Description"}>
            <Typography>{props.screenTest.description}</Typography>
          </LabelledOutline>
        </Box>
      );
    }
    return (
      <Box mb={2}>
        <TextField
          variant="outlined"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          label="Description"
          fullWidth
        >
          {props.screenTest.description}
        </TextField>
      </Box>
    );
  };

  const renderPass = (label, resultData) => {
    if (resultData.test_pass) {
      return (
        <Box my={2}>
          <LabelledOutline label={label}>
            <Typography color="primary">CORRECT ANSWER</Typography>
          </LabelledOutline>
        </Box>
      );
    }
    return (
      <Box my={2}>
        <LabelledOutline label={label}>
          <Typography color="secondary">WRONG ANSWER</Typography>
        </LabelledOutline>
      </Box>
    );
  };

  return (
    props.isVisible && (
      <Box>
        {renderDescription()}
        <LabelledOutline label={"Code"}>
          {props.screenTest.code ? (
            <CodeMirror
              value={props.screenTest.code}
              options={{
                readOnly: loading,
                lineNumbers: true,
                mode: "python",
                theme: "monokai",
                lineWrapping: true,
                disabled: true,
              }}
            />
          ) : (
            "Empty code"
          )}
        </LabelledOutline>
        {loading && <LinearProgress />}
        {renderPass("Expected Answer Verdict", props.screenTest)}
        {renderResults("Expected Pycheck Results", props.screenTest)}
        {runResult && renderRunResult()}
        <Box m={1}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Button
                disabled={loading || props.screenTest.is_default}
                variant="contained"
                color="secondary"
                onClick={handleDelete}
              >
                Delete Test
              </Button>
            </Grid>
            <Grid item>
              <ButtonGroup variant="contained">
                <Button disabled={loading} onClick={handleRun} color="primary">
                  Run and Compare
                </Button>
                <Button onClick={handleUpdateTest} color="secondary">
                  Update Test
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  );
}

export default ScreenTest;
