import React, { useState, useEffect } from "react";
import sortBy from "lodash/sortBy";

import {
  Box,
  Grid,
  Alert,
  List,
  ListItem,
  Typography,
  Tooltip,
  FormControlLabel,
  FormControl,
  IconButton,
  Switch,
  LinearProgress,
  Button,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import LabelledOutline from "../general/LabelledOutline";
import { useDispatch, useSelector } from "react-redux";
import { lessonDataActions } from "../store/lessonDataSlice";
import { MAX_FILE_SIZE_MB } from "./constants";
import FileModeSelect from "../general/FileModeSelect";

let saveTimeout;

export default function LessonFiles({ lesson }) {
  const dispatch = useDispatch();
  const lessonData = useSelector((state) => state.lessonData);
  const screens = lessonData.lessonScreens[lesson.id];

  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);
  const [files, setFiles] = useState(lesson.data_files);

  useEffect(() => setFiles(lesson.data_files), [lesson.data_files]);

  const visibility_tooltip =
    "If this is on, learners will be able to preview the file contents in the lesson editor.";

  const fileSizeOk = (file) => {
    return file.size <= MAX_FILE_SIZE_MB * 1024 * 1024;
  };

  const uploadFile = async (file) => {
    if (!fileSizeOk(file)) {
      setError(`File exceeded maximum size of ${MAX_FILE_SIZE_MB} MB`);
      return;
    }
    setSaving(true);
    setError("");
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await fetch(`/api/lesson/${lesson.id}/datafile`, {
        method: "POST",
        body: formData,
      });
      const responseData = await response.json();
      if (responseData.error) {
        setError(responseData.error);
      } else {
        dispatch(
          lessonDataActions.updateLessonField({
            lessonId: lesson.id,
            field: "data_files",
            value: responseData,
            backend: true,
          })
        );
      }
    } catch (error) {
      console.log(error);
      setError(
        "An error occurred while trying to upload the file. Try again later."
      );
    }
    setSaving(false);
  };

  const changeFileWithDelay = (fileId, fieldName, newValue) => {
    const newFiles = lesson.data_files.map((dataFile) => {
      const newFile = { ...dataFile };
      if (newFile.id === fileId) newFile[fieldName] = newValue;
      return newFile;
    });
    setFiles(newFiles);

    if (saveTimeout) clearTimeout(saveTimeout);

    saveTimeout = setTimeout(
      () => changeFile(fileId, fieldName, newValue),
      1500
    );
  };

  const changeFile = async (fileId, fieldName, newValue) => {
    setError("");
    setSaving(true);
    let newFileValues = {
      ...lesson.data_files.find((dataFile) => dataFile.id === fileId),
    };
    newFileValues[fieldName] = newValue;
    newFileValues.screens = newFileValues.screens.map((screen) =>
      typeof screen === "number" ? screen : screen.id
    );

    try {
      const response = await fetch(`/api/datafile/${fileId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newFileValues),
      });
      const responseData = await response.json();
      if (responseData.error) {
        setError(responseData.error);
      } else {
        dispatch(
          lessonDataActions.updateLessonField({
            lessonId: lesson.id,
            field: "data_files",
            value: responseData,
            backend: true,
          })
        );
      }
    } catch (error) {
      console.log(error);
      setError("Could not change file. Try again later.");
    }
    setSaving(false);
  };

  const deleteFile = async (fileId) => {
    setSaving(true);
    setError("");
    try {
      const response = await fetch(`/api/datafile/${fileId}`, {
        method: "DELETE",
      });
      const responseData = await response.json();
      if (responseData.error) {
        setError(responseData.error);
      } else {
        dispatch(
          lessonDataActions.updateLessonField({
            lessonId: lesson.id,
            field: "data_files",
            value: responseData,
            backend: true,
          })
        );
      }
    } catch (error) {
      console.log(error);
      setError("Could not delete file. Try again later.");
    }
    setSaving(false);
  };

  const renderDeleteCTA = (file) => {
    const isCliCustomSetup =
      lesson.language === "cli" && file.file_name === "custom_setup.sh";

    return (
      <IconButton
        edge="end"
        onClick={() => deleteFile(file.id)}
        disabled={lesson.show_in_editor || isCliCustomSetup || saving}
      >
        <DeleteIcon />
      </IconButton>
    );
  };
  const renderVisibilityEdit = (file) => (
    <Tooltip title={visibility_tooltip}>
      <FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={file.show_in_editor}
              size="small"
              onChange={(event) =>
                changeFile(file.id, "show_in_editor", event.target.checked)
              }
              disabled={lesson.is_shallow_import || saving}
            />
          }
          label={<Typography variant="caption">Show in editor</Typography>}
          labelPlacement="end"
        />
      </FormControl>
    </Tooltip>
  );

  const renderPriorityEdit = (file) => (
    <Tooltip title="Defines if file should be prioritized in file viewer.">
      <FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={file.display_first}
              size="small"
              onChange={(event) =>
                changeFile(file.id, "display_first", event.target.checked)
              }
              disabled={lesson.display_first || saving}
            />
          }
          label={<Typography variant="caption">Display first</Typography>}
          labelPlacement="end"
        />
      </FormControl>
    </Tooltip>
  );

  const renderSelectMode = (file) => (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="caption" sx={{ width: "70px" }}>
        Mode
      </Typography>
      <FileModeSelect
        value={file.mode}
        size="small"
        fullWidth
        disabled={lesson.is_shallow_import || saving}
        onChange={(event) => changeFile(file.id, "mode", event.target.value)}
      />
    </Box>
  );

  const renderSelectScreen = (file) => (
    <Box display="flex" alignItems="center" gap={1}>
      <Tooltip title="No selection means that the file is displayed for all screens">
        <Typography variant="caption" sx={{ width: "70px" }}>
          Screens
        </Typography>
      </Tooltip>

      <Select
        value={file.screens.map((screen) => screen.id)}
        multiple
        fullWidth
        size="small"
        onChange={(event) => changeFile(file.id, "screens", event.target.value)}
      >
        {(screens || lesson.screens).map((screen) => (
          <MenuItem key={screen.id} value={screen.id}>
            {screen.sequence}.&nbsp;{screen.title}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );

  const renderFiles = () => {
    if (files.length === 0) {
      return (
        <Typography variant="caption">
          This lesson doesn't have files
        </Typography>
      );
    }
    return (
      <List>
        {sortBy(files, ["file_name"]).map((file, i) => {
          return (
            <ListItem key={file.id}>
              <Box
                display="flex"
                flexGrow="1"
                width="100%"
                flexDirection="column"
                justifyContent="center"
                sx={{
                  borderTop: i === 0 ? 0 : 1,
                  borderColor: "grey.300",
                  pt: 2,
                  mb: 1,
                }}
              >
                <Box
                  display="flex"
                  width="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>{file.file_name}</Typography>
                  <Box display="flex" alignItems="center">
                    {renderPriorityEdit(file)}
                    {renderVisibilityEdit(file)}
                    {renderDeleteCTA(file)}
                  </Box>
                </Box>
                <Grid ml={1} container alignItems="center" columnSpacing={2}>
                  <Grid item sm={12} md={12} xl={6} mt={1}>
                    <TextField
                      label="File alias"
                      fullWidth
                      value={file.alias || file.file_name}
                      onChange={(event) =>
                        changeFileWithDelay(
                          file.id,
                          "alias",
                          event.target.value
                        )
                      }
                      size="small"
                    />
                  </Grid>
                  <Grid item sm={12} md={12} xl={6} mt={1}>
                    {renderSelectScreen(file)}
                  </Grid>
                  <Grid item sm={12} md={6} mt={1}>
                    <TextField
                      label="File preview limit"
                      fullWidth
                      type="number"
                      value={file.lines}
                      onChange={(event) =>
                        changeFileWithDelay(
                          file.id,
                          "lines",
                          event.target.value
                        )
                      }
                      size="small"
                    />
                  </Grid>
                  <Grid item sm={12} md={6} mt={1}>
                    {renderSelectMode(file)}
                  </Grid>
                </Grid>
              </Box>
            </ListItem>
          );
        })}
      </List>
    );
  };

  return (
    <LabelledOutline label="Files">
      <Box display="flex" flexDirection="column">
        {error && <Alert severity="error">{error}</Alert>}
        {renderFiles()}
        <Box mb={2}>{saving && <LinearProgress color="primary" />}</Box>
        <Button
          variant="contained"
          component="label"
          disabled={lesson.is_shallow_import || saving}
          size="small"
        >
          Upload File
          <input
            type="file"
            hidden
            onChange={(event) => uploadFile(event.target.files[0])}
          />
        </Button>
      </Box>
    </LabelledOutline>
  );
}
