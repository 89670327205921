import React, { Component } from "react";
import { Box, Typography } from "@mui/material";
import { Controlled as CodeMirror } from "react-codemirror2";

import DataTable from "../DataTable";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/python/python";
import "codemirror/mode/r/r";
import "codemirror/mode/sql/sql";
import "codemirror/mode/shell/shell";
import "codemirror/mode/django/django";
import "codemirror/mode/css/css";
import "codemirror/mode/yaml/yaml";
import "codemirror/mode/spreadsheet/spreadsheet";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/dockerfile/dockerfile";
import "codemirror/mode/markdown/markdown";
import "codemirror/mode/diff/diff";

export default class CodeEditorFileView extends Component {
  renderPreview() {
    if (this.props.preview.type === "text" && !!this.props.preview.data) {
      return (
        <CodeMirror
          value={this.props.preview.data}
          className="dq-editor dq-pt-0 dq-border dq-border-gray-100"
          options={{
            readOnly: true,
            lineWrapping: true,
            theme: "monokai",
            mode: this.props.mode || "text",
            lineNumbers: true,
          }}
        />
      );
    } else if (this.props.preview.type === "table") {
      const { rows, headers } = this.props.preview.data;
      return <DataTable headers={headers} rows={rows} />;
    }
    return (
      <Typography>
        File preview is not currently supported for this type.
      </Typography>
    );
  }

  render() {
    return (
      <Box className="editor-code-code">
        <Box className="editor-table-container" sx={{ maxHeight: "70vh" }}>
          {this.renderPreview()}
        </Box>
      </Box>
    );
  }
}
