import React, { useState, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

import { fetchDatasets } from "../api/datasets";
import SearchableTable from "../general/SearchableTable";

import PropTypes from "prop-types";

function DatasetSelectionModal(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [datasets, setDatasets] = useState([]);
  const [selectedDatasets, setSelectedDatasets] = useState([]);

  const COLUMNS = [
    {
      key: "latest",
      label: "Latest version id",
      type: "component",
      render: (dataset, col) => dataset.latest_version_id,
    },
    {
      key: "name",
      label: "Name",
      type: "text",
    },
    {
      key: "description",
      label: "Description",
      type: "text",
    },
    {
      key: "version_count",
      label: "Number of versions",
      type: "component",
      render: (dataset, col) => dataset.version_count,
    },
    {
      key: "last_update",
      label: "Last update",
      type: "component",
      render: (dataset, col) => {
        const date = new Date(dataset.last_update);
        return date.toLocaleString();
      },
    },
  ];

  const FILTERS = [];

  const toggleDatasetSelection = (dataset) => {
    if (selectedDatasets.includes(dataset.latest_version_id)) {
      setSelectedDatasets(
        selectedDatasets.filter((id) => id !== dataset.latest_version_id)
      );
    } else {
      setSelectedDatasets([...selectedDatasets, dataset.latest_version_id]);
    }
  };

  const canAdd = () => {
    return true;
  };

  const handleAddToLesson = () => {
    props.handleAddDatasetsToLesson(selectedDatasets);
    props.close();
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    fetchDatasets(
      (datasets) => {
        if (!isMounted) return;
        const lessonDatasetIds = props.lessonDatasets.map(
          (datasetVersion) => datasetVersion.dataset_id
        );
        datasets = datasets.filter(
          (dataset) =>
            !lessonDatasetIds.includes(dataset.id) && !dataset.archived
        );
        setIsLoading(false);
        setDatasets(datasets);
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
      }
    );
    return () => {
      isMounted = false;
    };
  }, [props.lessonDatasets]);

  useEffect(() => {
    setSelectedDatasets([]);
  }, [props.close]);

  return (
    <Dialog open={props.isOpen} onClose={props.close} maxWidth="lg" fullWidth>
      <DialogTitle align="center">Choose datasets</DialogTitle>
      <DialogContent>
        <SearchableTable
          cols={COLUMNS}
          rows={datasets}
          selectMode="multiple"
          isLoading={isLoading}
          filters={FILTERS}
          rowSelectionCallback={toggleDatasetSelection}
          rowsPerPage={5}
        />
      </DialogContent>
      <DialogActions>
        <Grid
          container
          item
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={4}>
            <Button fullWidth variant="contained" onClick={() => props.close()}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              disabled={!canAdd()}
              color="primary"
              variant="contained"
              onClick={handleAddToLesson}
            >
              Add to lesson
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

DatasetSelectionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

DatasetSelectionModal.defaultProps = {
  isOpen: false,
};

export default DatasetSelectionModal;
