import React from "react";
import { Snackbar, Alert, IconButton } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { errorActions } from "../store/errorSlice";
import CloseIcon from "@mui/icons-material/Close";

export default function ScreenError({ screen }) {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.error.screenError)[screen.id];

  const handleClose = () => {
    dispatch(
      errorActions.setScreenError({
        screenId: screen.id,
        error: "",
      })
    );
  };

  return (
    <Snackbar
      open={error}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        severity="error"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        {error}
      </Alert>
    </Snackbar>
  );
}
