import React from "react";
import PropTypes from "prop-types";
import CodeMirror from "codemirror";
import { Box } from "@mui/material";
import CodeOutput from "../screens/CodeOutput";

require("codemirror/lib/codemirror.css");
require("codemirror/theme/monokai.css");
require("codemirror/mode/python/python");
require("codemirror/mode/r/r");
require("codemirror/mode/sql/sql");
require("codemirror/mode/shell/shell");
require("codemirror/addon/comment/comment");

const CM_LANGUAGE_MAP = {
  r: "r",
  python: "python",
  sql: "text/x-sql",
  cli: "shell",
};

const propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  language: PropTypes.oneOf(Object.keys(CM_LANGUAGE_MAP)).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  showOutput: PropTypes.bool,
};

const CodeMirrorTextArea = ({
  id,
  value,
  setValue,
  language,
  showOutput = true,
  children = null,
}) => {
  const codeMirrorRef = React.useRef();
  const textAreaRef = React.useRef();

  const tabMapping = (cm) => {
    if (cm?.somethingSelected()) {
      cm?.indentSelection("add");
      return;
    }
    cm?.execCommand("insertSoftTab");
  };

  const shiftTabMapping = (cm) => cm?.indentSelection("subtract");

  const toggleComment = (cm) =>
    cm?.toggleComment({
      indent: true,
    });

  React.useEffect(() => {
    textAreaRef.current.value = value;
    codeMirrorRef.current = new CodeMirror.fromTextArea(textAreaRef.current, {
      lineNumbers: true,
      mode: CM_LANGUAGE_MAP[language] || "text",
      theme: "monokai",
      lineWrapping: true,
      indentUnit: 4,
      extraKeys: {
        Tab: tabMapping,
        "Shift-Tab": shiftTabMapping,
        "Ctrl-/": toggleComment,
        "Cmd-/": toggleComment,
      },
    });
    codeMirrorRef.current.on("change", (doc) => setValue(doc.getValue()));

    return () => {
      codeMirrorRef.current.toTextArea();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, language, setValue]);

  return (
    <Box className="editor-code-code">
      {children}
      <textarea
        id={id}
        defaultValue={value}
        ref={(ref) => {
          textAreaRef.current = ref;
        }}
      />
      {showOutput && <CodeOutput />}
    </Box>
  );
};

CodeMirrorTextArea.propTypes = propTypes;
export default CodeMirrorTextArea;
