import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import {
  Box,
  Grid,
  Checkbox,
  Radio,
  Toolbar,
  Button,
  Paper,
  ButtonGroup,
  Typography,
} from "@mui/material";

import checkMultipleChoiceQuestion from "./checkMultipleChoiceQuestion";
import PropTypes from "prop-types";

function MultipleChoiceQuestion(props) {
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [checkResult, setCheckResult] = useState();

  const handleClick = (answerIndex) => {
    if (selectedAnswers.includes(answerIndex)) {
      setSelectedAnswers(
        selectedAnswers.filter((index) => index !== answerIndex)
      );
    } else {
      if (props.question.select_type === "single") {
        setSelectedAnswers([answerIndex]);
      } else {
        setSelectedAnswers([...selectedAnswers, answerIndex]);
      }
    }
  };

  const submitAnswer = () => {
    const checkResult = checkMultipleChoiceQuestion(
      props.question,
      selectedAnswers
    );
    setCheckResult(checkResult);
  };

  const clearAnswersAndFeedback = () => {
    setCheckResult();
    setSelectedAnswers([]);
  };

  const renderFeedback = (answer, index) => {
    if (answer.feedback_message.trim().length === 0) return null;

    if (checkResult && checkResult.results[index]) {
      return (
        <Box
          ml={1}
          px={1}
          py={0.25}
          bgcolor={checkResult.results[index].correct ? "#DCFCDB" : "#FFCCCC"}
        >
          <ReactMarkdown
            children={checkResult.results[index].feedback_message}
            remarkPlugins={[remarkGfm]}
          />
        </Box>
      );
    }

    return null;
  };

  return (
    <Box m={1}>
      <Paper elevation={0} variant="outlined">
        <Box px={2} pt={1}>
          {checkResult &&
            (checkResult.correct ? (
              <Typography variant="h6" color="primary">
                Correct!
              </Typography>
            ) : (
              <Typography variant="h6" color="secondary">
                Wrong answer
              </Typography>
            ))}
          <ReactMarkdown
            children={props.question.question}
            remarkPlugins={[remarkGfm]}
          />
          {props.question.answer_options.map((answer, index) => (
            <Box key={`answer-${index}`}>
              <Grid container direction="row" alignItems="center">
                <Grid item onClick={() => handleClick(index)}>
                  {props.question.select_type === "single" ? (
                    <Radio
                      color="primary"
                      checked={selectedAnswers.includes(index)}
                    />
                  ) : (
                    <Checkbox
                      color="primary"
                      checked={selectedAnswers.includes(index)}
                    />
                  )}
                </Grid>
                <Grid item onClick={() => handleClick(index)}>
                  <ReactMarkdown
                    children={answer.label}
                    remarkPlugins={[remarkGfm]}
                  />
                </Grid>
              </Grid>
              {renderFeedback(answer, index)}
            </Box>
          ))}
          <Toolbar>
            <Grid container direction="row" justifyContent="flex-end">
              <ButtonGroup>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={clearAnswersAndFeedback}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitAnswer}
                >
                  Submit Answer
                </Button>
              </ButtonGroup>
            </Grid>
          </Toolbar>
        </Box>
      </Paper>
    </Box>
  );
}

MultipleChoiceQuestion.propTypes = {
  question: PropTypes.object.isRequired,
};

export default MultipleChoiceQuestion;
