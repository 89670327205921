import React from "react";
import Modal from "../general/Modal";
import CheckerEditor from "./CheckerEditor";
import CheckerViewer from "./CheckerViewer";
import { Button, Grid, FormControlLabel, Switch } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { answerCheckerActions } from "../store/answerCheckerSlice";
import { getCheckerCode } from "../utils/pycheck";

const ANSWER_CHECKER_CODE_PREFIX = [
  "def __CUSTOM_CHECK__(check_info):",
  "\tac = StackAnswerChecker(check_info)",
];

const ANSWER_CHECKER_CODE_SUFFIX = ["\treturn ac.check_answer()"];

function PycheckEditorModal(props) {
  const dispatch = useDispatch();
  const answerChecker = useSelector((state) => state.answerChecker);

  const renderEditor = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <CheckerEditor language={props.language} />
        </Grid>
        <Grid item xs={6}>
          <CheckerViewer />
        </Grid>
      </Grid>
    );
  };

  const addCheckerHandler = () => {
    dispatch(answerCheckerActions.addChecker(answerChecker.currentChecker));
  };

  const buildCheckerHandler = () => {
    let codeLines = answerChecker.builtCheckers.map(
      (checker) =>
        `\t${getCheckerCode(checker.metadata.name, checker.arguments)}`
    );
    codeLines = [
      ...ANSWER_CHECKER_CODE_PREFIX,
      ...codeLines,
      ...ANSWER_CHECKER_CODE_SUFFIX,
    ];
    const code = codeLines.join("\n");
    props.buildCheckerCallback(code);
    dispatch(answerCheckerActions.reset());
    props.closeHandler();
  };

  const hasOptionalArguments = () => {
    if (!answerChecker.currentChecker.metadata) return false;
    return (
      answerChecker.currentChecker.metadata.arguments.filter(
        (argument) => argument.default.length > 0
      ).length > 0
    );
  };

  const renderActions = () => {
    return (
      <Grid container direction="row">
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <FormControlLabel
              control={
                <Switch
                  checked={answerChecker.viewOptions.showDoc}
                  onChange={() =>
                    dispatch(answerCheckerActions.toggleShowDoc())
                  }
                  color="primary"
                />
              }
              label={"Show doc"}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={answerChecker.viewOptions.showOptional}
                  onChange={() =>
                    dispatch(answerCheckerActions.toggleShowOptional())
                  }
                  color="primary"
                  disabled={!hasOptionalArguments()}
                />
              }
              label={"Show optional args"}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={addCheckerHandler}
            >
              Add Checker
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={buildCheckerHandler}
            >
              Build Checker
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Modal
      title="Pycheck Editor"
      isOpen={props.isOpen}
      closeHandler={props.closeHandler}
      muiDialog={{ maxWidth: "xl" }}
      content={renderEditor()}
      actions={renderActions()}
    />
  );
}

export default PycheckEditorModal;
