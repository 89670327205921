import React, { useState } from "react";

import {
  Alert,
  IconButton,
  DialogTitle,
  FormControl,
  DialogContent,
  Dialog,
  Button,
  CircularProgress,
  Box,
  Tabs,
  Tab,
  TextField,
  Grid,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { importLessonsIntoRelease } from "./api";
import LabelledOutline from "./general/LabelledOutline";
import { createReleaseFromZip } from "./api/releases";

const styles = {
  closeButton: {
    position: "absolute",
    top: "0",
    right: "0",
    "&:hover": {
      cursor: "pointer",
      color: "#F50057",
    },
  },
};

function CreateImportReleaseForm(props) {
  const [releaseName, setReleaseName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [lessonList, setLessonList] = useState("");
  const [error, setError] = useState("");
  const [importErrors, setImportErrors] = useState();
  const [loading, setLoading] = useState({ isLoading: false, info: "" });
  const [file, setFile] = useState();

  const releaseNameIsValid = () => releaseName !== "";
  const branchNameIsValid = () => !props.isCreateMode || branchName !== "";
  const lessonListIsValid = () =>
    !props.isCreateMode || splitLessonList().length > 0;

  const fieldsAreValid = () => {
    if (!releaseNameIsValid()) {
      setError("The name cannot be empty.");
      return false;
    }
    if (!branchNameIsValid()) {
      setError("The branch name cannot be empty.");
      return false;
    }

    return true;
  };

  const createFromZip = () => {
    setLoading({ isLoading: true, info: "Creating from zip" });
    setError("");

    createReleaseFromZip(
      releaseName,
      branchName,
      file,
      () => {
        setLoading({ isLoading: false, info: "" });
        props.releaseAddedCallback();
      },
      (error) => {
        setLoading({ isLoading: false, info: "" });
        setError(error);
      }
    );
  };

  const createOrImportRelease = () => {
    if (!fieldsAreValid()) return;

    if (props.isZip) {
      createFromZip();
      return;
    }

    setLoading({
      isLoading: true,
      info: `${props.isCreateMode ? "Creating" : "Importing"} release.`,
    });
    setError("");
    const body = {
      name: releaseName,
      version_major: branchName,
      version_minor: null,
      create_new: props.isCreateMode,
    };
    fetch("/api/releases", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((data) => data.json())
      .then((response) => {
        setLoading({ isLoading: false, info: "" });
        if (response.error) {
          setError(response.error);
          return;
        }

        const release = response;

        const lessons = splitLessonList();
        if (props.isCreateMode && lessons.length > 0) {
          setLoading({ isLoading: true, info: "Importing lessons." });
          importLessonsIntoRelease(
            lessons,
            release,
            handleLessonsImported,
            (error) => {
              setLoading({ isLoading: false, info: "" });
              setError("An error occurred while validating the lesson list.");
            }
          );
        } else {
          props.releaseAddedCallback();
        }
      })
      .catch((error) => {
        setLoading({ isLoading: false, info: "" });
        setError(
          `Could not ${props.isCreateMode ? "create" : "import"} the release.`
        );
      });
  };

  const handleLessonsImported = (response) => {
    if (response.error) {
      setError(response.error);
      setLoading({ isLoading: false, info: "" });
    } else {
      const invalidLessons = response.invalidLessons;
      const lessonSequences = Object.keys(invalidLessons || {});
      setLoading({ isLoading: false, info: "" });
      if (lessonSequences.length > 0) {
        setError(`
          ${lessonSequences.length === 1 ? "Lesson" : "Lessons"}
          ${lessonSequences.join(", ")}
          could not be imported.`);
        setImportErrors(invalidLessons);
      } else {
        props.releaseAddedCallback();
      }
    }
  };

  const handleLessonListChange = (event) => {
    const value = event.target.value;
    const match = value.match(new RegExp("[0-9 ,]+"));
    if (value === "" || (match && match[0] === value)) {
      setLessonList(event.target.value);
    }
  };

  const renderImportErrors = () => {
    if (!importErrors) return null;
    return (
      <>
        {Object.keys(importErrors).map((sequence) => (
          <Box p={1} key={sequence}>
            <Alert severity="error">
              {sequence}: {importErrors[sequence]}
            </Alert>
          </Box>
        ))}
      </>
    );
  };

  const splitLessonList = () => {
    return lessonList
      .split(new RegExp("[, ][, ]*"))
      .filter((lesson) => lesson.length > 0);
  };

  return (
    <FormControl size="small">
      {error !== "" ? (
        <Box p={1}>
          <Alert severity="error">{error}</Alert>
          {renderImportErrors()}
        </Box>
      ) : null}
      <TextField
        margin="dense"
        error={error !== "" && !releaseNameIsValid()}
        value={releaseName}
        label="Name"
        onChange={(event) => setReleaseName(event.target.value)}
      />
      <TextField
        margin="dense"
        error={error !== "" && !branchNameIsValid()}
        value={branchName}
        label="Branch name"
        onChange={(event) => setBranchName(event.target.value)}
      />
      {props.isCreateMode && (
        <TextField
          margin="dense"
          error={error !== "" && !lessonListIsValid()}
          value={lessonList}
          label="Lesson list"
          onChange={handleLessonListChange}
        />
      )}
      {props.isZip && (
        <LabelledOutline label="Zip File">
          {file && <Typography>{file.name}</Typography>}
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            component="label"
            disabled={false}
          >
            choose zip file
            <input
              type="file"
              accept=".zip"
              hidden
              onChange={(event) => setFile(event.target.files[0])}
            />
          </Button>
        </LabelledOutline>
      )}
      {loading.info && <Alert severity="info">{loading.info}</Alert>}
      <Button
        color="primary"
        variant="contained"
        disabled={loading.isLoading}
        onClick={createOrImportRelease}
        className="dq-mt-5"
      >
        {loading.isLoading ? (
          <CircularProgress size={24} />
        ) : props.isCreateMode ? (
          "Create"
        ) : (
          "Import"
        )}
      </Button>
    </FormControl>
  );
}

function AddReleaseModal(props) {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleClose = (needsUpdate) => {
    props.closeModal(needsUpdate);
  };

  const TABS = [
    <CreateImportReleaseForm
      isCreateMode={false}
      isZip={false}
      releaseAddedCallback={() => handleClose(true)}
    />,
    <CreateImportReleaseForm
      isCreateMode={true}
      isZip={false}
      releaseAddedCallback={() => handleClose(true)}
    />,
    <CreateImportReleaseForm
      isCreateMode={false}
      isZip={true}
      releaseAddedCallback={() => handleClose(true)}
    />,
  ];

  const renderActiveTab = () => {
    return TABS[activeTab];
  };

  return (
    <Dialog
      onClose={() => handleClose(false)}
      open={props.open}
      maxWidth={"sm"}
      fullWidth
    >
      <IconButton sx={styles.closeButton} onClick={() => handleClose(false)}>
        <CloseIcon />
      </IconButton>
      <DialogTitle>Add Release</DialogTitle>
      <DialogContent dividers>
        <Tabs
          variant="fullWidth"
          value={activeTab}
          onChange={(event, newTab) => setActiveTab(newTab)}
        >
          <Tab label="Import Existing" />
          <Tab label="Create New" />
          <Tab label="Create from Zip" />
        </Tabs>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {renderActiveTab()}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default AddReleaseModal;
