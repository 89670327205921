import React, { useState, useMemo } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import CodeEditorFileView from "./CodeEditorFileView";
import CliCodeEditor from "./code-editors/CliCodeEditor";
import DefaultCodeEditor from "./code-editors/DefaultCodeEditor";
import EditorActions from "./EditorActions";
import AuthoringProjectPanels from "../AuthoringProjectPanels";
import { FILE_VIEWER_MODE } from "../lessons/constants";
import { CM_MODE_MAP } from "../general/constants";
import orderBy from "lodash/orderBy";

const DEFAULT_TAB = "default";
export default function ScreenCode({ lesson, screen, saveScreen }) {
  const filesToShow = useMemo(
    () =>
      orderBy(
        lesson.data_files.filter((file) => {
          const fileScreens = file.screens?.map((screen) => screen.id);
          return (
            file.show_in_editor &&
            (!fileScreens.length || fileScreens.indexOf(screen.id) !== -1)
          );
        }),
        ["display_first", "file_name"],
        ["desc", "asc"]
      ),
    [lesson.data_files, screen]
  );

  const isFileViewer = useMemo(
    () => lesson.mode === FILE_VIEWER_MODE,
    [lesson.mode]
  );

  const [currentView, setCurrentView] = useState(DEFAULT_TAB);

  const renderFileTabs = () => (
    <Tabs
      value={currentView}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      onChange={(event, newValue) => setCurrentView(newValue)}
    >
      <Tab
        key={DEFAULT_TAB}
        value={DEFAULT_TAB}
        label={isFileViewer ? "Answer code" : "Code"}
      />
      {filesToShow.map((file) => (
        <Tab
          key={file.file_name}
          value={file.file_name}
          label={file.alias || file.file_name}
        />
      ))}
    </Tabs>
  );

  const renderCodeEditor = () => {
    if (lesson.language === "cli") {
      return <CliCodeEditor lesson={lesson} screen={screen} />;
    }
    return (
      <DefaultCodeEditor
        lesson={lesson}
        screen={screen}
        answer_only={isFileViewer}
      />
    );
  };

  const getCurrentFile = (newView) =>
    filesToShow.find((file) =>
      newView ? file.file_name === newView : file.file_name === currentView
    );
  const renderCurrentView = () => {
    if (!filesToShow && !lesson) return;

    if (currentView === "default") {
      return renderCodeEditor();
    }

    if (!filesToShow.length) return <div>No files available for preview</div>;

    let currentFile = getCurrentFile();

    if (!currentFile) {
      setCurrentView(filesToShow[0].file_name);
      currentFile = getCurrentFile(filesToShow[0].file_name);
    }

    return (
      <CodeEditorFileView
        key={currentFile.file_name}
        preview={currentFile.preview}
        mode={CM_MODE_MAP[currentFile.mode]}
      />
    );
  };

  if (lesson.enabled_features.indexOf("JupyterLab") !== -1) {
    return (
      <Box className="editor-code dq-relative">
        <AuthoringProjectPanels lesson_id={lesson.id} />
      </Box>
    );
  }

  return (
    <>
      {renderFileTabs()}
      {renderCurrentView()}
      <EditorActions lesson={lesson} screen={screen} saveScreen={saveScreen} />
    </>
  );
}
