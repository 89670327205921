import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { codeEditorActions } from "../store/codeEditorSlice";
import withCodeEditor from "../HOC/withCodeEditor";
import { errorActions } from "../store/errorSlice";
import { Button, ButtonGroup, LinearProgress, Stack } from "@mui/material";
import ScreenTestsModal from "../pycheck-components/ScreenTestsModal";
import { CUSTOM_TEST_TAB, REMOTE_RUN_CODE_TAB } from "./code-tabs/constants";
import LongRunTracker from "./LongRunTracker";

const MAX_RETRIES = 3;

const EditorActions = ({ lesson, screen, saveScreen, codeEditor }) => {
  const dispatch = useDispatch();

  const [running, setRunning] = useState(false);
  const [viewingTests, setViewingTests] = useState(false);
  const [savingTest, setSavingTest] = useState(false);
  const [longRunInfo, setLongRunInfo] = useState();
  const [retryCount, setRetryCount] = useState(0);

  const currentTab = codeEditor.currentTab;
  const customTest = codeEditor.customTest;

  const isTest = currentTab === CUSTOM_TEST_TAB;
  const isRunAndDeploy = currentTab === REMOTE_RUN_CODE_TAB;

  const saveTest = async () => {
    if (!customTest.customCheckOutput) return;
    setSavingTest(true);
    try {
      const response = await fetch(
        `/api/lesson/${lesson.id}/screen/${screen.id}/save-screen-test`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            code: customTest.code,
            checkOutput: customTest.customCheckOutput,
          }),
        }
      );
      const responseData = await response.json();
      if (responseData.error) {
      } else {
        dispatch(codeEditorActions.resetCustomTest(screen.id));
      }
    } catch (error) {
      console.log(error);
    }
    setSavingTest(false);
  };

  const runCode = async (code, successCallback, errorCallback) => {
    setRunning(true);
    try {
      await saveScreen();
      const body = {
        lesson_id: lesson.id,
        screen_id: screen.id,
        code,
      };
      if (isRunAndDeploy) {
        body.deploy_code = code;
        body.code = `${screen.initial_code}\n${screen.answer_code}`;
      }

      const response = await fetch("/api/run-code", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      if (response.status !== 200) {
        dispatch(
          errorActions.setScreenError({
            screenId: screen.id,
            error: `Could not fetch code run results`,
          })
        );
      }
      const responseData = await response.json();
      if (responseData.error) {
        processCodeRunError(responseData.error, errorCallback);
      } else {
        if (responseData.session_id) {
          processLongCodeRun(responseData);
        } else {
          processCodeCompletion(responseData, successCallback);
        }
      }
    } catch (error) {
      processCodeRunError(error, errorCallback);
    }
  };

  const processLongCodeRun = (longRunInfo) => {
    setLongRunInfo(longRunInfo);
  };

  const processCodeRunError = (error, errorCallback = null) => {
    if (errorCallback) {
      errorCallback(error);
    } else {
      dispatch(
        errorActions.setScreenError({
          screenId: screen.id,
          error: error,
        })
      );
    }
    setRunning(false);
  };

  const processCodeCompletion = (runResult, successCallback = null) => {
    if (successCallback) {
      successCallback(runResult);
    } else {
      if (isTest) {
        dispatch(
          codeEditorActions.updateCustomTest({
            screenId: screen.id,
            customTest: {
              output: runResult.output,
              variables: runResult.vars,
              customCheckOutput: runResult.checks,
            },
          })
        );
      } else if (isRunAndDeploy) {
        if (runResult.external_ip) {
          dispatch(
            codeEditorActions.updateRunDeployResult({
              screenId: screen.id,
              runDeployResult: runResult,
            })
          );
          setRetryCount(0);
        } else if (retryCount < MAX_RETRIES)
          setTimeout(() => {
            runCode(screen.remote_run_code);
            setRetryCount(retryCount + 1);
          }, 2000);
      } else {
        dispatch(
          codeEditorActions.updateRunResult({
            screenId: screen.id,
            runResult: {
              output: runResult.output,
              variables: runResult.vars,
              customCheckOutput: runResult.checks,
            },
          })
        );
      }
    }
    setRunning(false);
  };

  const renderTestControls = () => {
    if (currentTab !== CUSTOM_TEST_TAB) return null;

    return (
      <ButtonGroup>
        <Button
          variant="contained"
          color="primary"
          onClick={saveTest}
          disabled={running || savingTest || !customTest.customCheckOutput}
        >
          Save Test
        </Button>
        {viewingTests && (
          <ScreenTestsModal
            isOpen={viewingTests}
            close={() => setViewingTests(false)}
            lessonId={lesson.id}
            screenId={screen.id}
            runCodeWithCallback={runCode}
          />
        )}
        <Button
          variant="contained"
          onClick={() => setViewingTests(true)}
          disabled={savingTest}
        >
          View Tests
        </Button>
      </ButtonGroup>
    );
  };

  const renderButtonLabel = () => {
    if (running) return "Running";
    if (isTest) return "Run Test";
    if (isRunAndDeploy) return "Run and Deploy";
    return "Run Code";
  };

  const handleRunCodeClick = () => {
    let code = screen.answer_code;
    if (isRunAndDeploy) code = screen.remote_run_code;
    if (isTest) code = customTest.code;

    runCode(code);
  };

  if (lesson.language === "cli" || !lesson.has_runner) {
    return null;
  }

  return (
    <>
      {(running || savingTest) && <LinearProgress />}
      {longRunInfo && (
        <LongRunTracker
          screenId={screen.id}
          sessionId={longRunInfo.session_id}
          codeRunId={longRunInfo.code_run_id}
          runnerName={longRunInfo.runner_name}
          successRunCallback={processCodeCompletion}
          errorRunCallback={processCodeRunError}
        />
      )}
      <Stack direction="row" mt={1} justifyContent="space-between">
        <Button
          variant="contained"
          color="primary"
          onClick={handleRunCodeClick}
          disabled={running}
        >
          {renderButtonLabel()}
        </Button>
        {renderTestControls()}
      </Stack>
    </>
  );
};

export default withCodeEditor(EditorActions);
