import React, { useEffect, useState } from "react";
import { fetchCheckerMetadata } from "../api/pycheck";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import CheckerArgument from "./CheckerArgument";
import LabelledOutline from "../general/LabelledOutline";
import {
  Autocomplete,
  Box,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { answerCheckerActions } from "../store/answerCheckerSlice";

function CheckerEditor(props) {
  const dispatch = useDispatch();
  const answerChecker = useSelector((state) => state.answerChecker);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);
    fetchCheckerMetadata(
      (checkerMetadata) => {
        if (!mounted) return;
        checkerMetadata = checkerMetadata.filter(
          (checker) => checker.language === props.language
        );
        dispatch(
          answerCheckerActions.initializeCheckerMetadataAndGroups(
            checkerMetadata
          )
        );
        setIsLoading(false);
      },
      (error) => {
        if (!mounted) return;
        setIsLoading(false);
        console.log(error);
      }
    );
    return () => {
      mounted = false;
    };
  }, []);

  const groupUpdateHandler = (newGroup) => {
    dispatch(answerCheckerActions.setCurrentCheckerGroup(newGroup));
  };

  const updateSelectedChecker = (newChecker) => {
    dispatch(answerCheckerActions.selectChecker(newChecker));
  };

  const renderCheckerGroups = () => (
    <Autocomplete
      value={answerChecker.selectedGroupName}
      onChange={(event, newGroup) => groupUpdateHandler(newGroup)}
      options={answerChecker.checkerGroupNames}
      getOptionLabel={(group) => group}
      renderInput={(params) => (
        <TextField {...params} label="Checker Group" variant="outlined" />
      )}
    />
  );

  const renderCheckerSearchBar = () => (
    <Autocomplete
      value={answerChecker.currentChecker.metadata}
      onChange={(event, newChecker) => updateSelectedChecker(newChecker)}
      options={answerChecker.checkersInSelectedGroup}
      getOptionLabel={(checker) => checker.name}
      renderInput={(params) => (
        <TextField {...params} label="Checker Selector" variant="outlined" />
      )}
    />
  );

  const renderCheckerDesciption = () => {
    if (!answerChecker.currentChecker.metadata) return null;
    if (!answerChecker.viewOptions.showDoc) return null;
    return (
      <LabelledOutline label="Description">
        <ReactMarkdown
          children={answerChecker.currentChecker.metadata.description}
          remarkPlugins={[remarkGfm]}
        />
      </LabelledOutline>
    );
  };

  const renderArguments = () => {
    if (!answerChecker.currentChecker.metadata) return null;
    if (answerChecker.currentChecker.metadata.arguments.length === 0) {
      return (
        <LabelledOutline label="Arguments">
          <Typography>No arguments</Typography>
        </LabelledOutline>
      );
    }
    return (
      <LabelledOutline label="Arguments">
        {answerChecker.currentChecker.metadata.arguments.map(
          (argument, index) => {
            if (
              answerChecker.viewOptions.showOptional ||
              argument.default.length === 0
            ) {
              return (
                <CheckerArgument
                  key={`${argument.name}-${index}`}
                  argument={{ ...argument, argumentIndex: index }}
                />
              );
            }
            return null;
          }
        )}
      </LabelledOutline>
    );
  };

  return (
    <Box>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Box my={1}>{renderCheckerGroups()}</Box>
          <Box my={1}>{renderCheckerSearchBar()}</Box>
          {renderCheckerDesciption()}
          {renderArguments()}
        </>
      )}
    </Box>
  );
}

export default CheckerEditor;
