import React from "react";
import { Box } from "@mui/material";
import DataFileEditor from "../datafiles/DataFileEditor";
import { LabelledOutline } from "../general/LabelledOutline";

export default function LessonSetupFiles({ lesson }) {
  if (lesson.language !== "cli") {
    return null;
  }

  if (!lesson.data_files.find((f) => f.file_name === "custom_setup.sh")) {
    return null;
  }

  return (
    <LabelledOutline label="Setup Files">
      <Box display="flex" flexDirection="column" p={2}>
        <DataFileEditor
          lessonId={lesson.id}
          fileName="custom_setup.sh"
          language="cli"
        />
      </Box>
    </LabelledOutline>
  );
}
