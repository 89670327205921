import React, { useMemo } from "react";
import CodeMirrorTextArea from "../../code/CodeMirrorTextArea";
import { useDispatch } from "react-redux";
import { lessonDataActions } from "../../store/lessonDataSlice";
import {
  Box,
  Tooltip,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CodePreview from "./CodePreview";

const RUNNABLE_TOGGLE_TOOLTIP =
  "If this is turned on, display code will always run before any answer code. " +
  "Turn this off to run answer code alone. " +
  "For example, this should be used if the display code is intended to be edited by learners.";

export default function AnswerCodeTab({ lesson, screen }) {
  const dispatch = useDispatch();

  const renderRunnableDisplayToggle = () => {
    if (!screen.display_code || screen.display_code.trim().length === 0) {
      return null;
    }
    return (
      <Box
        p={0.5}
        color="white"
        bgcolor="primary.main"
        display="flex"
        flexDirection="column"
      >
        <Tooltip title={RUNNABLE_TOGGLE_TOOLTIP} arrow>
          <FormControl style={{ display: "flex", alignSelf: "flex-end" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={screen.should_run_display_code}
                  size="small"
                  onChange={(event) =>
                    dispatch(
                      lessonDataActions.updateScreenField({
                        screenId: screen.id,
                        field: "should_run_display_code",
                        value: event.target.checked,
                      })
                    )
                  }
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "white",
                    },
                  }}
                />
              }
              label="Run display code with answer"
              labelPlacement="end"
            />
          </FormControl>
        </Tooltip>
        {screen.should_run_display_code && (
          <CodePreview name="Display" code={screen.display_code} />
        )}
      </Box>
    );
  };

  const answerEditor = useMemo(
    () => (
      <CodeMirrorTextArea
        id={`codemirror-editor-answer-code`}
        value={screen.answer_code || ""}
        language={lesson.language}
        setValue={(newCode) =>
          dispatch(
            lessonDataActions.updateScreenField({
              screenId: screen.id,
              field: "answer_code",
              value: newCode,
            })
          )
        }
      >
        {renderRunnableDisplayToggle()}
      </CodeMirrorTextArea>
    ),
    [
      screen.id,
      lesson.language,
      screen.display_code,
      screen.should_run_display_code,
    ]
  );

  return answerEditor;
}
