import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

class User extends Component {
  state = {
    email: "",
    name: "",
  };

  componentDidMount() {
    fetch("/api/user")
      .then((response) => response.json())
      .then((user) => {
        if (!user.email) this.props.history.push("/login");
        else this.setState({ email: user.email, name: user.name });
      });
  }

  render() {
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        <IconButton
          size="small"
          color="inherit"
          onClick={() => this.props.history.push("/login")}
        >
          <AccountCircleIcon />
        </IconButton>
        <Typography variant="body2">
          {this.state.name} ({this.state.email})
        </Typography>
      </Box>
    );
  }
}

export default withRouter(User);
