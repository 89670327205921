import React, { useState } from "react";
import { Stack, Alert, Button, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { lessonDataActions } from "../store/lessonDataSlice";

export default function LessonShallowImport(props) {
  const lesson = props.lesson;

  const [importingLesson, setImportingLesson] = useState(false);
  const [importError, setImportError] = useState("");

  const dispatch = useDispatch();

  const handleImportLesson = async () => {
    setImportError("");
    setImportingLesson(true);

    try {
      const response = await fetch(`/api/lesson/${lesson.id}/import`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });
      const responseData = await response.json();
      if (responseData.error) {
        setImportError(responseData.error);
      } else {
        dispatch(
          lessonDataActions.setLessonData({
            lesson: responseData.lesson,
            backend: true,
          })
        );
        responseData.screen.forEach((screen) => {
          dispatch(
            lessonDataActions.setScreenData({
              screen,
              backend: true,
            })
          );
        });
      }
    } catch (error) {
      console.log(error);
      setImportError(
        "An error occurred while importing the lesson. Try again later."
      );
    }

    setImportingLesson(false);
  };

  if (!lesson.is_shallow_import) {
    return null;
  }

  return (
    <Stack my={2} spacing={1}>
      <Alert
        severity="info"
        action={
          <Button
            color="inherit"
            size="small"
            varaint="contained"
            onClick={handleImportLesson}
            disabled={importingLesson}
          >
            {importingLesson ? <CircularProgress size={24} /> : "Import Lesson"}
          </Button>
        }
      >
        This lesson is not fully imported yet
      </Alert>
      {importError && <Alert severity="error">{importError}</Alert>}
    </Stack>
  );
}
