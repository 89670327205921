import React from "react";
import LessonPackages from "./LessonPackages";
import AnswerCheckingControls from "./AnswerCheckingControls";
import LessonFiles from "./LessonFiles";
import LessonDatasets from "./LessonDatasets";
import LessonSetupFiles from "./LessonSetupFiles";

export default function LessonRunnerSpec({ lesson }) {
  return (
    <>
      <AnswerCheckingControls lesson={lesson} />
      <LessonPackages lesson={lesson} />
      <LessonFiles lesson={lesson} />
      <LessonSetupFiles lesson={lesson} />
      <LessonDatasets lesson={lesson} />
    </>
  );
}
