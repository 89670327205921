import { configureStore } from "@reduxjs/toolkit";
import answerCheckerReducer from "./answerCheckerSlice";
import lessonStatusReducer from "./lessonStatusSlice";
import lessonDataReducer from "./lessonDataSlice";
import errorReducer from "./errorSlice";
import codeEditorReducer from "./codeEditorSlice";
import assessmentSkillReducer from "./assessmentSkillsSlice";
import multipleChoiceQuestionsReducer from "./multipleChoiceQuestionsSlice";

const store = configureStore({
  reducer: {
    answerChecker: answerCheckerReducer,
    lessonStatus: lessonStatusReducer,
    lessonData: lessonDataReducer,
    error: errorReducer,
    codeEditor: codeEditorReducer,
    assessmentSkills: assessmentSkillReducer,
    multipleChoiceQuestions: multipleChoiceQuestionsReducer,
  },
});

export default store;
