import React from "react";
import { Box } from "@mui/material";
import StepMetadata from "./StepMetadata";
import { LabelledOutline } from "../general/LabelledOutline";
import StepCourses from "./StepCourses";

export default function Step(props) {
  const track = props.track;
  const step = props.step;
  const stepIndex = props.index;
  const numberOfSteps = props.numberOfSteps;

  return (
    <Box my={4}>
      <LabelledOutline label={`Step ${stepIndex + 1}`}>
        <StepMetadata
          track={track}
          step={step}
          stepIndex={stepIndex}
          numberOfSteps={numberOfSteps}
          reloadSteps={props.reloadSteps}
        />
        <StepCourses step={step} />
      </LabelledOutline>
    </Box>
  );
}
