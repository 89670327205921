import React from "react";
import CodePreview from "./CodePreview";
import { buildAnswerCode } from "./utils";
import { Box } from "@mui/material";

export default function AnswerPreview({ screen }) {
  let answerCode = buildAnswerCode(screen);

  if (answerCode.trim().length === 0) {
    return null;
  }

  return (
    <Box
      p={0.5}
      color="white"
      bgcolor="primary.main"
      display="flex"
      flexDirection="column"
    >
      <CodePreview name="Answer" code={answerCode} />
    </Box>
  );
}
