import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  Box,
  IconButton,
  TextField,
  Typography,
  LinearProgress,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveIcon from "@mui/icons-material/Save";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { assessmentSkillActions } from "../store/assessmentSkillsSlice";

function SkillEditor(props) {
  const MAX_POINTS = 5;

  const [name, setName] = useState(props.skill.name);
  const [points, setPoints] = useState(props.skill.point_value);

  return (
    <Grid
      container
      direction="row"
      spacing={1}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={9}>
        <TextField
          disabled={props.isLoading}
          fullWidth
          value={props.skill.name}
          size="small"
          label="name"
          onChange={(event) => setName(event.target.value)}
          value={name}
        />
      </Grid>
      <Grid item xs={1}>
        <FormControl fullWidth>
          <InputLabel>points</InputLabel>
          <Select
            disabled={props.isLoading}
            size="small"
            label="points"
            value={points}
            onChange={(event) => setPoints(event.target.value)}
          >
            {[...Array(MAX_POINTS).keys()].map((p) => (
              <MenuItem key={`points-${p}`} value={p + 1}>
                {p + 1}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          color="secondary"
          disabled={props.isLoading}
          onClick={() => props.deleteSkill(props.skill)}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          color="primary"
          disabled={
            props.isLoading ||
            (name === props.skill.name && points === props.skill.point_value) ||
            props.skill.name.trim().length === 0
          }
          onClick={() => props.saveSkill(props.skill.id, name, points)}
        >
          <SaveIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

function AssessmentSkills(props) {
  const params = useParams();
  const lessonId = params.lessonId;
  const screenId = params.screenId;

  const dispatch = useDispatch();

  const assessmentSkills = useSelector((state) => state.assessmentSkills)[
    screenId
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [newSkillName, setNewSkillName] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    // Reset state when we change screens
    setIsLoading(false);
    setNewSkillName("");
    setError("");
  }, [screenId]);

  const loadSkills = async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await fetch(
        `/api/lesson/${lessonId}/screen/${screenId}/skills`
      );
      const responseData = await response.json();
      if (responseData.error) {
        setError(responseData.error);
      } else {
        dispatch(
          assessmentSkillActions.setScreenSkills({
            screenId,
            skills: responseData,
          })
        );
      }
    } catch (error) {
      console.log(error);
      setError("A problem occurred while loading the assessment skills.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadSkills();
  }, []);

  const handleAddSkill = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `/api/lesson/${lessonId}/screen/${screenId}/skill`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: newSkillName,
            point_value: 1,
          }),
        }
      );
      const responseData = await response.json();
      if (responseData.error) {
        setError(responseData.error);
      } else {
        dispatch(
          assessmentSkillActions.setScreenSkills({
            screenId,
            skills: responseData,
          })
        );
      }
    } catch (error) {
      console.log(error);
      setError("A problem occurred while loading the assessment skills.");
    }
    setNewSkillName("");
    setIsLoading(false);
  };

  const deleteSkill = async (skill) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `/api/lesson/${lessonId}/screen/${screenId}/skill`,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            skillId: skill.id,
          }),
        }
      );
      const responseData = await response.json();
      if (responseData.error) {
        setError(responseData.error);
      } else {
        dispatch(
          assessmentSkillActions.setScreenSkills({
            screenId,
            skills: responseData,
          })
        );
      }
    } catch (error) {
      console.log(error);
      setError("A problem occurred while deleting the assessment skills.");
    }
    setIsLoading(false);
  };

  const saveSkill = async (skillId, skillName, skillPoints) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `/api/lesson/${lessonId}/screen/${screenId}/skill`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            skillId: skillId,
            name: skillName,
            point_value: skillPoints,
          }),
        }
      );
      const responseData = await response.json();
      if (responseData.error) {
        setError(responseData.error);
      } else {
        dispatch(
          assessmentSkillActions.setScreenSkills({
            screenId,
            skills: responseData,
          })
        );
      }
    } catch (error) {
      console.log(error);
      setError("A problem occurred while saving the assessment skills.");
    }
    setIsLoading(false);
  };

  const renderSkills = () => {
    if (!assessmentSkills) return null;

    return assessmentSkills.map((skill) => (
      <Box key={`skill-${skill.id}`} p={2}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid item>
            <SkillEditor
              isLoading={isLoading}
              skill={skill}
              setIsLoading={setIsLoading}
              lessonId={lessonId}
              screenId={screenId}
              deleteSkill={deleteSkill}
              saveSkill={saveSkill}
            />
          </Grid>
        </Grid>
      </Box>
    ));
  };

  return (
    <Box p={2}>
      {error && <Alert severity="error">{error}</Alert>}
      <Accordion variant="outlined">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ margin: 0 }}>
          <Typography variant="button" style={{ fontWeight: "bold" }}>
            Assessment Skills
          </Typography>
        </AccordionSummary>
        {renderSkills()}
        <Box p={2}>
          <Grid
            container
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={10}>
              <TextField
                disabled={isLoading}
                fullWidth
                variant="outlined"
                label="New skill name"
                value={newSkillName}
                onChange={(event) => setNewSkillName(event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                color="primary"
                onClick={handleAddSkill}
                disabled={isLoading || newSkillName.trim().length === 0}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        {isLoading && <LinearProgress />}
      </Accordion>
    </Box>
  );
}

export default AssessmentSkills;
