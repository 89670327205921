import { createSlice } from "@reduxjs/toolkit";

export const initialScreenStatus = {
  saving: false,
};

const initialState = {
  lessons: {},
  screens: {},
  screenStatus: {},
  initialLessons: {},
  initialScreens: {},
  lessonScreens: {},
  inProgressFields: {},
};

const lessonDataSlice = createSlice({
  name: "lessonDataSlice",
  initialState: initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setLessonData(state, action) {
      const lesson = action.payload.lesson;
      state.lessons[lesson.id] = lesson;
      if (action.payload.backend) {
        state.initialLessons[lesson.id] = lesson;
      }
    },
    addInProgressField(state, action) {
      const lessonId = action.payload.lessonId;
      const fieldName = action.payload.fieldName;

      if (!state.inProgressFields[lessonId])
        state.inProgressFields[lessonId] = [];
      if (state.inProgressFields[lessonId].indexOf(fieldName) === -1)
        state.inProgressFields[lessonId].push(fieldName);
    },
    removeInProgressField(state, action) {
      const lessonId = action.payload.lessonId;
      state.inProgressFields[lessonId] = state.inProgressFields[
        lessonId
      ].filter((fieldName) => fieldName !== action.payload.fieldName);
    },
    setLessonScreens(state, action) {
      const lessonId = action.payload.lessonId;
      const screens = action.payload.screens;
      screens.forEach((screen) => {
        state.screens[screen.id] = screen;
        if (action.payload.backend) {
          state.initialScreens[screen.id] = screen;
        }
        state.screenStatus[screen.id] = initialScreenStatus;
      });
      state.lessonScreens[lessonId] = screens;
    },
    setScreenData(state, action) {
      const screen = action.payload.screen;
      state.screens[screen.id] = screen;
      if (action.payload.backend) {
        state.initialScreens[screen.id] = screen;
      }
    },
    updateLessonField(state, action) {
      const lessonId = action.payload.lessonId;
      const field = action.payload.field;
      const value = action.payload.value;
      state.lessons[lessonId][field] = value;
      if (action.payload.backend) {
        state.initialLessons[lessonId][field] = value;
      }
    },
    enableCustomAnswerChecking(state, action) {
      const lessonId = action.payload;
      state.lessons[lessonId].custom_check_enabled = true;
      state.lessons[lessonId].legacy_check_enabled = false;
      if (state.lessons[lessonId].language !== "cli") {
        const packages = state.lessons[lessonId].packages;
        state.lessons[lessonId].packages = [
          ...packages,
          {
            name: "pycheck",
            version: "",
            show_in_editor: true,
          },
        ];
      }
    },
    disableCustomAnswerChecking(state, action) {
      const lessonId = action.payload;
      state.lessons[lessonId].custom_check_enabled = false;
      if (state.lessons[lessonId].language !== "cli") {
        const packages = state.lessons[lessonId].packages;
        state.lessons[lessonId].packages = packages.filter(
          (p) => p.name !== "pycheck"
        );
      }
    },
    enableLegacyAnswerChecking(state, action) {
      const lessonId = action.payload;
      state.lessons[lessonId].custom_check_enabled = false;
      state.lessons[lessonId].legacy_check_enabled = true;
      if (state.lessons[lessonId].language !== "cli") {
        const packages = state.lessons[lessonId].packages;
        state.lessons[lessonId].packages = packages.filter(
          (p) => p.name !== "pycheck"
        );
      }
    },
    disableLegacyAnswerChecking(state, action) {
      const lessonId = action.payload;
      state.lessons[lessonId].legacy_check_enabled = false;
    },
    addPackage(state, action) {
      const lessonId = action.payload.lessonId;
      const packageName = action.payload.packageName;
      const packages = state.lessons[lessonId].packages;
      if (packages.filter((p) => p.name === packageName).length === 0) {
        state.lessons[lessonId].packages = [
          ...packages,
          {
            name: packageName,
            version: "",
            show_in_editor: true,
          },
        ];
      }
      if (packageName === "pycheck") {
        state.lessons[lessonId].custom_check_enabled = true;
        state.lessons[lessonId].legacy_check_enabled = false;
      }
    },
    removePackage(state, action) {
      const lessonId = action.payload.lessonId;
      const packageName = action.payload.packageName;
      const packages = state.lessons[lessonId].packages;
      state.lessons[lessonId].packages = packages.filter(
        (p) => p.name !== packageName
      );
      if (packageName === "pycheck") {
        state.lessons[lessonId].custom_check_enabled = false;
      }
    },
    updatePackageVersion(state, action) {
      const lessonId = action.payload.lessonId;
      const packageName = action.payload.packageName;
      const packageVersion = action.payload.packageVersion;
      const packages = state.lessons[lessonId].packages;
      state.lessons[lessonId].packages = packages.map((p) =>
        p.name !== packageName
          ? p
          : {
              ...p,
              version: packageVersion,
            }
      );
    },
    resetBuildStatus(state, action) {
      const lessonId = action.payload;
      state.lessons[lessonId].dependencies_ready = false;
      state.lessons[lessonId].dependency_build_error = "";
    },
    setPackagesAfterBuild(state, action) {
      const lessonId = action.payload.lessonId;
      const packages = action.payload.packages;
      const custom_check_enabled = action.payload.custom_check_enabled;
      const legacy_check_enabled = action.payload.legacy_check_enabled;
      const last_build = action.payload.last_build;
      state.lessons[lessonId].packages = packages;
      state.lessons[lessonId].custom_check_enabled = custom_check_enabled;
      state.lessons[lessonId].legacy_check_enabled = legacy_check_enabled;
      state.lessons[lessonId].last_build = last_build;
      state.lessons[lessonId].dependencies_ready = false;
      state.lessons[lessonId].dependency_build_error = null;
      if (action.payload.backend) {
        state.initialLessons[lessonId].packages = packages;
        state.initialLessons[lessonId].custom_check_enabled =
          custom_check_enabled;
        state.initialLessons[lessonId].legacy_check_enabled =
          legacy_check_enabled;
        state.initialLessons[lessonId].last_build = last_build;
        state.initialLessons[lessonId].dependencies_ready = false;
        state.initialLessons[lessonId].dependency_build_error = null;
      }
    },
    setBuildStatus(state, action) {
      const lessonId = action.payload.lessonId;
      state.lessons[lessonId].dependencies_ready =
        action.payload.dependencies_ready;
      state.lessons[lessonId].dependency_build_error =
        action.payload.dependency_build_error;
      if (action.payload.backend) {
        state.initialLessons[lessonId].dependencies_ready =
          action.payload.dependencies_ready;
        state.initialLessons[lessonId].dependency_build_error =
          action.payload.dependency_build_error;
      }
    },
    updateScreenField(state, action) {
      const screenId = action.payload.screenId;
      const field = action.payload.field;
      const value = action.payload.value;
      state.screens[screenId][field] = value;
      if (action.payload.backend) {
        state.initialScreens[screenId][field] = value;
      }
    },
    updateScreenData(state, action) {
      const screenId = action.payload.screenId;
      const data = action.payload.data;
      state.screens[screenId] = {
        ...state.screens[screenId],
        ...data,
      };
      if (action.payload.backend) {
        state.initialScreens[screenId] = {
          ...state.initialScreens[screenId],
          ...data,
        };
      }
    },
    startSavingScreen(state, action) {
      const screenId = action.payload;
      state.screenStatus[screenId].saving = true;
    },
    stopSavingScreen(state, action) {
      const screenId = action.payload;
      state.screenStatus[screenId].saving = false;
    },
  },
});

export const lessonDataActions = lessonDataSlice.actions;

export default lessonDataSlice.reducer;
