import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

export default class DataTable extends Component {
  render() {
    return (
      <Table size="small" className="data-table">
        <TableHead>
          <TableRow>
            {this.props.headers.map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.rows.map((row, index) => (
            <TableRow key={index}>
              {row.map((item, item_index) => (
                <TableCell key={`${index}-${item_index}`}>{item}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}
