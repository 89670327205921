import React from "react";
import Modal from "../general/Modal";

import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from "@mui/material";

const styles = {
  image: {
    "&:hover": {
      cursor: "pointer",
      border: "2px solid #F50057",
    },
  },
};

function ImageGalleryModal(props) {
  const imageClickHandler = (image) => {
    props.imageSelectedCallback(image);
    props.closeHandler();
  };

  const renderImages = () => {
    return props.images.length === 0 ? (
      <Typography align="center">No images yet.</Typography>
    ) : (
      <ImageList cols={3}>
        {props.images.map((image) => (
          <ImageListItem
            key={image.s3_url}
            sx={styles.image}
            onClick={() => imageClickHandler(image)}
          >
            <img src={`${image.s3_url}`} />
            <ImageListItemBar title={image.file_name} />
          </ImageListItem>
        ))}
      </ImageList>
    );
  };

  return (
    <Modal
      isOpen={props.isOpen}
      closeHandler={props.closeHandler}
      title="Lesson Images"
      content={renderImages()}
      muiDialog={{ maxWidth: "md" }}
    />
  );
}

export default ImageGalleryModal;
