import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import PropTypes from "prop-types";

function ConfirmModal(props) {
  return (
    <Dialog open={props.isOpen} onClose={props.cancel} maxWidth="sm" fullWidth>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>{props.message}</DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={props.cancel}>
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            props.confirm();
            props.cancel();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  message: PropTypes.string,
};

ConfirmModal.defaultProps = {
  isOpen: false,
  message: "Are you sure?",
};

export default ConfirmModal;
