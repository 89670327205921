import React from "react";
import CodeTabs from "../code-tabs/CodeTabs";
import {
  INITIAL_CODE_TAB,
  DISPLAY_CODE_TAB,
  ANSWER_CODE_TAB,
  CUSTOM_CHECK_TAB,
  CUSTOM_TEST_TAB,
  LEGACY_CHECK_TAB,
  REMOTE_RUN_CODE_TAB,
} from "../code-tabs/constants";

export default function DefaultEditor({ lesson, screen, answer_only }) {
  if (answer_only)
    return (
      <CodeTabs
        lesson={lesson}
        screen={screen}
        tabs={[ANSWER_CODE_TAB]}
        defaultTab={ANSWER_CODE_TAB}
      />
    );

  let DEFAULT_TAB = DISPLAY_CODE_TAB;

  let tabs = [INITIAL_CODE_TAB, DISPLAY_CODE_TAB, ANSWER_CODE_TAB];

  if (lesson.custom_check_enabled) {
    tabs.push(CUSTOM_CHECK_TAB);
    tabs.push(CUSTOM_TEST_TAB);
  }

  if (lesson.legacy_check_enabled) {
    tabs.push(LEGACY_CHECK_TAB);
  }

  if (screen.remote_run_code) {
    tabs.push(REMOTE_RUN_CODE_TAB);
    DEFAULT_TAB = REMOTE_RUN_CODE_TAB;
  }

  return (
    <CodeTabs
      lesson={lesson}
      screen={screen}
      tabs={tabs}
      defaultTab={DEFAULT_TAB}
    />
  );
}
