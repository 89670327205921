import React from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MultipleChoiceQuestion from "../multiple-choice-questions/MultipleChoiceQuestion";
import { useDispatch, useSelector } from "react-redux";
import { errorActions } from "../store/errorSlice";
import { multipleChoiceQuestionsActions } from "../store/multipleChoiceQuestionsSlice";
import { useParams } from "react-router-dom";

export default function MCQSection({ lesson }) {
  const params = useParams();
  const lessonId = params.lessonId;
  const screenId = params.screenId;

  const dispatch = useDispatch();
  const multipleChoiceQuestions = useSelector(
    (state) => state.multipleChoiceQuestions
  )[screenId];

  const createMultipleChoiceQuestion = async () => {
    try {
      const response = await fetch(
        `/api/lesson/${lessonId}/screen/${screenId}/mcq`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({}),
        }
      );
      const responseData = await response.json();
      if (responseData.error) {
        dispatch(
          errorActions.setScreenError({
            screenId: screenId,
            error: responseData.error,
          })
        );
      } else {
        dispatch(
          multipleChoiceQuestionsActions.setScreenMcqs({
            screenId: screenId,
            multipleChoiceQuestions: responseData,
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        errorActions.setScreenError({
          screenId: screenId,
          error:
            "An error occurred while trying to create the multiple choice question",
        })
      );
    }
  };

  const saveMultipleChoiceQuestion = async (question, message = "") => {
    try {
      const response = await fetch(
        `/api/lesson/${lessonId}/screen/${screenId}/mcq`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...question,
            questionId: question.id,
            commitMessage: message,
          }),
        }
      );
      const responseData = await response.json();
      if (responseData.error) {
        dispatch(
          errorActions.setScreenError({
            screenId: screenId,
            error: responseData.error,
          })
        );
      } else {
        dispatch(
          multipleChoiceQuestionsActions.setScreenMcqs({
            screenId: screenId,
            multipleChoiceQuestions: responseData,
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        errorActions.setScreenError({
          screenId: screenId,
          error:
            "An error occurred while trying to save the multiple choice question",
        })
      );
    }
  };

  const deleteQuestion = async (question) => {
    try {
      const response = await fetch(
        `/api/lesson/${lessonId}/screen/${screenId}/mcq`,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            questionId: question.id,
          }),
        }
      );
      const responseData = await response.json();
      if (responseData.error) {
        dispatch(
          errorActions.setScreenError({
            screenId: screenId,
            error: responseData.error,
          })
        );
      } else {
        dispatch(
          multipleChoiceQuestionsActions.setScreenMcqs({
            screenId: screenId,
            multipleChoiceQuestions: responseData,
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        errorActions.setScreenError({
          screenId: screenId,
          error:
            "An error occurred while trying to delete the multiple choice question",
        })
      );
    }
  };

  const renderQuestions = () => {
    if (!multipleChoiceQuestions) return null;
    return multipleChoiceQuestions.map((question, index) => (
      <MultipleChoiceQuestion
        key={`question-${index}`}
        screenId={screenId}
        question={question}
        questionIndex={index}
        deleteQuestion={deleteQuestion}
        lessonId={lessonId}
        isAssessment={lesson.is_assessment}
        saveQuestion={saveMultipleChoiceQuestion}
      />
    ));
  };

  return (
    <Box>
      <Box>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Typography variant="h6" display="inline">
              Multiple Choice Questions
            </Typography>
          </Grid>
          <Grid item>
            <IconButton edge="end" onClick={createMultipleChoiceQuestion}>
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      {renderQuestions()}
    </Box>
  );
}
