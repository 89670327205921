import React, { useEffect } from "react";
import { Box } from "@mui/material";
import TextSection from "./TextSection";
import MCQSection from "./MCQSection";
import scriptjs from "scriptjs";
import AssessmentSkills from "./AssessmentSkills";

const MATHJAX_SRC =
  "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.0/MathJax.js?config=TeX-MML-AM_CHTML";
let gMathjaxLoaded = false;
let gMathjaxConfigured = false;

// try again after a second to catch bug: https://gitlab.dataquest.io/dataquestio/planning/issues/715
const MATHJAX_CATCH_TIME = 1000;

export default function ScreenText({ lesson, screen, saveScreen }) {
  /**
   * Configure MathJax.
   *
   * Set a flag so that we only do this once per page view.
   *
   */
  const configureMathJax = () => {
    if (!window.MathJax) {
      return;
    }
    if (!gMathjaxConfigured) {
      window.MathJax.Hub.Config({
        messageStyle: "none",
        elements: ["screen-text-mathjax-container"],
        tex2jax: {
          inlineMath: [],
          displayMath: [],
          processEscapes: true,
        },
      });
      gMathjaxConfigured = true;
    }
  };

  /**
   * Set default typeset for MathJax.
   *
   * Set a timeout and do it again do defeat a race condition.
   *
   * @see https://gitlab.dataquest.io/dataquestio/planning/issues/715
   *
   */
  const setMathJaxTypeset = () => {
    if (!window.MathJax) {
      return;
    }
    window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
    setTimeout(() => {
      window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
    }, MATHJAX_CATCH_TIME);
  };

  useEffect(() => {
    if (!gMathjaxLoaded) {
      scriptjs(MATHJAX_SRC, () => {
        gMathjaxLoaded = true;
      });
    }
  }, []);

  configureMathJax();
  useEffect(() => {
    setMathJaxTypeset();
  }, [lesson, screen]);

  return (
    <Box id="screen-text-mathjax-container" className="editor-text">
      {lesson.is_assessment && (
        <AssessmentSkills
          screenId={screen.id}
          lessonId={lesson.id}
          handleChange={() => {}}
        />
      )}
      <TextSection
        title={screen.type === "takeaways" ? "Takeaways" : "Learn"}
        lesson={lesson}
        screen={screen}
        section="body"
        saveScreen={saveScreen}
      />
      {screen.type !== "takeaways" && (
        <>
          <TextSection
            title="Instructions"
            lesson={lesson}
            screen={screen}
            section="instructions"
            saveScreen={saveScreen}
          />
          <TextSection
            title="Hint"
            lesson={lesson}
            screen={screen}
            section="hint"
            saveScreen={saveScreen}
          />
        </>
      )}
      {screen.type !== "takeaways" && <MCQSection lesson={lesson} />}
      <TextSection
        title="Custom chat prompt"
        lesson={lesson}
        screen={screen}
        section="chat_prompt"
        saveScreen={saveScreen}
        textOnly
      />
    </Box>
  );
}
