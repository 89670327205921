import { FEATURES, FEATURES_WO_PACKAGES } from "./constants";

/*
  Converts a list of features into a dictionary contining a key
  for each possible feature and having boolean values indicating
  whether the feature was present in the list.

  For example, if all features where A, B, C, D and featureList
  was [A, C] then the result would be:

  {
    A: true,
    B: false,
    C: true,
    D: false
  }
*/
export const convertFeatureListToDict = (featureList) => {
  let features = {};
  FEATURES.forEach((feature) => {
    features[feature] = featureList.indexOf(feature) !== -1;
  });
  return features;
};

export const buildLessonMetadataUpdateBody = (lesson, saveMessage = "") => {
  return {
    allowed_plans: lesson.allowed_plans,
    author: lesson.author,
    app_ports: lesson.app_ports,
    code_run_timeout: lesson.code_run_timeout,
    description: lesson.description,
    features: convertFeatureListToDict(lesson.enabled_features),
    is_beta: lesson.is_beta,
    language: lesson.language,
    language_version: lesson.language_version,
    legacy_check_enabled: lesson.legacy_check_enabled,
    meta_title: lesson.meta_title,
    meta_description: lesson.meta_description,
    overview: lesson.overview,
    preview: lesson.preview,
    objectives: lesson.objectives,
    packages: lesson.packages,
    related_lesson: lesson.related_lesson,
    related_lessons: lesson.related_lessons,
    skill_level: lesson.skill_level,
    skill_topics: lesson.skill_topics,
    skill_requirements: lesson.skill_requirements,
    solution_notebook: lesson.solution_notebook,
    title: lesson.title,
    tools: lesson.tools,
    type: lesson.type,
    saveMessage: saveMessage,
    sql_max_rows: lesson.sql_max_rows,
  };
};

export const fixUndefinedValues = (lesson) => {
  let fixedLesson = { ...lesson };
  fixedLesson.tools = lesson.tools || [];
  return fixedLesson;
};

export function checkPackagesSupport(featureList) {
  return (
    FEATURES_WO_PACKAGES.filter((feature) => featureList.includes(feature))
      .length === 0
  );
}

export function supportsLegacyAnswerChecking(lesson) {
  return ["python", "sql", "r"].includes(lesson.language);
}

export function supportsCustomAnswerChecking(lesson) {
  return lesson.language !== "r";
}
