import React, { useMemo } from "react";
import CodeMirrorTextArea from "../../code/CodeMirrorTextArea";
import { useDispatch } from "react-redux";
import { lessonDataActions } from "../../store/lessonDataSlice";

export default function SimpleCodeTab({
  lesson,
  screen,
  field,
  defaultValue = "",
}) {
  const dispatch = useDispatch();

  const codeEditor = useMemo(
    () => (
      <CodeMirrorTextArea
        id={`codemirror-editor-${field}`}
        value={screen[field] || defaultValue}
        language={lesson.language}
        setValue={(newCode) =>
          dispatch(
            lessonDataActions.updateScreenField({
              screenId: screen.id,
              field: field,
              value: newCode,
            })
          )
        }
      />
    ),
    [lesson.language, screen.id]
  );

  return codeEditor;
}
