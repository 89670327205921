import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Box, Paper } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { answerCheckerActions } from "../store/answerCheckerSlice";
import GenericInput from "./argument-inputs/GenericInput";
import { getInitialValue } from "../utils/pycheck";

function CheckerArgument(props) {
  const [value, setValue] = useState(getInitialValue(props.argument));
  const answerChecker = useSelector((state) => state.answerChecker);
  const dispatch = useDispatch();

  const updateArgumentValue = (value, valuePython) => {
    setValue(value);
    dispatch(
      answerCheckerActions.updateCheckerArgument({
        [props.argument.name]: valuePython,
      })
    );
  };

  return (
    <Paper>
      <Box p={1}>
        <Box>
          <GenericInput
            label={props.argument.name}
            argument={props.argument}
            type={props.argument.type}
            value={value}
            valueUpdatedCallback={updateArgumentValue}
          />
        </Box>
        {answerChecker.viewOptions.showDoc && (
          <Box mt={1}>
            <ReactMarkdown
              children={`${props.argument.description}`}
              remarkPlugins={[remarkGfm]}
            />
          </Box>
        )}
      </Box>
    </Paper>
  );
}

export default CheckerArgument;
