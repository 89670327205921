import flatMap from "lodash/flatMap";
import unescape from "lodash/unescape";

export function processCleanupHTML(html) {
  html = String(html);
  html = html.replace(/&amp;/g, "&");
  html = html.replace(/&gt;/g, ">");
  html = html.replace(/&lt;/g, "<");
  html = html.replace(/\$\$/g, "");
  return html;
}

export function processLinks(html) {
  const TARGET = 'target="_blank"';
  const A_TAG = "<a ";
  let parts = html.split(A_TAG);
  parts = parts.map((value, index) => {
    if (index === 0) return value;

    if (value.includes(TARGET)) return value;
    return `${TARGET} ${value}`;
  });
  return parts.join(A_TAG);
}

export function processWithMathJax(html) {
  let parts = html.split("<mathjax>");
  parts = parts.map((value, index) => {
    if (index === 0) return value;

    const subs = value.split("</mathjax>");

    const contents = subs[0].match(/^\$(.*)\$$/)
      ? subs[0].slice(1, -1)
      : subs[0];

    return '<script type="math/tex">' + contents + "</script>" + subs[1];
  });

  return parts.join("");
}

/**
 * Parse and processs <pre> tags in an HTML string
 * @param {string} html
 * @returns {array}
 */
export function processPreCode(html) {
  // split html string in an array
  let parts = html.split("<pre><code");

  // loop over each part
  parts = flatMap(parts, (value, index) => {
    if (index === 0) return value;

    // join the opening <pre> tag that we split above with the current value
    let fullValue = `<pre><code${value}`;

    // index of opening <code> tag
    let codeOpenStart = fullValue.indexOf("<code");
    let codeOpenEnd = fullValue.indexOf(">", codeOpenStart);
    // index of the closing </code> tag
    let codeCloseStart = fullValue.indexOf("</code", codeOpenEnd);
    // get the <code> element value
    let codeText = fullValue.substring(codeOpenEnd + 1, codeCloseStart);

    // build an HTML document that we use to access the current class / language
    let doc = new DOMParser().parseFromString(fullValue, "text/html");
    // lookup the code element in the documenent
    let code = doc.querySelector("pre code");

    // TODO: figure out why we are doing this, don't want to remove in case
    // there are edge cases that I'm unware of. -DKH
    let decodedCodeText = unescape(codeText).trim();

    // build code value object
    let codeVal = {
      code: decodedCodeText,
    };

    // get class name from <code> element
    if (code.classList !== undefined && code.classList.length > 0) {
      codeVal.language = code.classList[0]; // e.g. python
    }

    const subs = value.split("</code></pre>");

    return [codeVal, subs[1]];
  });

  return parts;
}
