import React, { useState } from "react";
import {
  Box,
  LinearProgress,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DatasetSelectionModal from "../datasets/DatasetSelectionModal";
import DatasetUploadModal from "../datasets/DatasetUploadModal";
import DatasetVersionsModal from "../datasets/DatasetVersionsModal";
import DatasetVersionDownloadButton from "../datasets/DatasetVersionDownloadButton";
import LabelledOutline from "../general/LabelledOutline";
import {
  removeDatasetFromLesson,
  addDatasetsToLesson,
  updateLessonDatasetVersion,
} from "../api/datasets";

function LessonDatasets({ lesson }) {
  const [datasetVersions, setDatasetVersions] = useState(
    lesson.dataset_versions
  );
  const [isLoading, setIsLoading] = useState(false);
  const [datasetSelectionModalIsOpen, setDatasetSelectionModalIsOpen] =
    useState(false);
  const [datasetUploadModalIsOpen, setDatasetUploadModalIsOpen] =
    useState(false);
  const [datasetVersionModalState, setDatasetVersionModalState] = useState({
    isOpen: false,
    datasetVersion: null,
  });

  const newDatasetCallback = (datasetVersion) => {
    setIsLoading(true);
    addDatasetsToLesson(
      lesson.id,
      datasetVersion.id,
      (datasetVersions) => {
        setIsLoading(false);
        setDatasetVersions(datasetVersions);
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  const handleAddDatasetsToLesson = (selectedDatasets) => {
    setIsLoading(true);
    addDatasetsToLesson(
      lesson.id,
      selectedDatasets,
      (datasetVersions) => {
        setIsLoading(false);
        setDatasetVersions(datasetVersions);
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  const handleChangeDatasetVersion = (oldDatasetVersion, newDatasetVersion) => {
    setIsLoading(true);
    updateLessonDatasetVersion(
      lesson.id,
      oldDatasetVersion.id,
      newDatasetVersion.id,
      (datasetVersions) => {
        setIsLoading(false);
        setDatasetVersions(datasetVersions);
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  const handleDeleteDatasetFromLesson = (dataset) => {
    setIsLoading(true);
    removeDatasetFromLesson(
      lesson.id,
      dataset.id,
      (datasetVersions) => {
        setIsLoading(false);
        setDatasetVersions(datasetVersions);
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  const renderDataset = (datasetVersion) => (
    <Paper
      key={datasetVersion.id}
      elevation={0}
      p={1}
      style={{ backgroundColor: "#e8f0fe" }}
    >
      <Box p={1} m={1}>
        <ListItem>
          <ListItemText
            primary={datasetVersion.name}
            secondary={datasetVersion.hash}
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              color="secondary"
              onClick={() => handleDeleteDatasetFromLesson(datasetVersion)}
            >
              <DeleteIcon />
            </IconButton>
            <DatasetVersionDownloadButton
              datasetVersion={datasetVersion}
              isIcon
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Button
          size="small"
          color="secondary"
          onClick={() =>
            setDatasetVersionModalState({
              isOpen: true,
              datasetVersion: datasetVersion,
            })
          }
        >
          Change version
        </Button>
      </Box>
    </Paper>
  );

  const renderModals = () => (
    <>
      <DatasetSelectionModal
        lessonId={lesson.id}
        isOpen={datasetSelectionModalIsOpen}
        close={() => setDatasetSelectionModalIsOpen(false)}
        lessonDatasets={datasetVersions}
        handleAddDatasetsToLesson={handleAddDatasetsToLesson}
      />
      <DatasetUploadModal
        isOpen={datasetUploadModalIsOpen}
        close={() => setDatasetUploadModalIsOpen(false)}
        successCallback={newDatasetCallback}
      />
      <DatasetVersionsModal
        isOpen={datasetVersionModalState.isOpen}
        close={() =>
          setDatasetVersionModalState({ isOpen: false, datasetVersion: null })
        }
        handleChangeDatasetVersion={handleChangeDatasetVersion}
        datasetVersion={datasetVersionModalState.datasetVersion}
      />
    </>
  );

  return (
    <LabelledOutline label="Datasets">
      <Box display="flex" flexDirection="column">
        {renderModals()}
        {isLoading && <LinearProgress />}

        <List>
          {datasetVersions.map((datasetVersion) =>
            renderDataset(datasetVersion)
          )}
        </List>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid
            container
            xs={6}
            item
            direction="column"
            alignItems="stretch"
            justifyContent="center"
          >
            <Button
              onClick={() => setDatasetSelectionModalIsOpen(true)}
              variant="outlined"
              color="primary"
              disabled={!lesson.id || isLoading || lesson.is_shallow_import}
              size="small"
            >
              Choose dataset
            </Button>
          </Grid>
          <Grid
            container
            xs={6}
            item
            direction="column"
            alignItems="stretch"
            justifyContent="center"
          >
            <Button
              onClick={() => setDatasetUploadModalIsOpen(true)}
              variant="contained"
              color="primary"
              disabled={!lesson.id || isLoading || lesson.is_shallow_import}
              size="small"
            >
              New dataset
            </Button>
          </Grid>
        </Grid>
      </Box>
    </LabelledOutline>
  );
}

export default LessonDatasets;
