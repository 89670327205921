import { handleJsonResponse, handleBlobResponse } from "./generic";

export const fetchDatasets = (successCallback, errorCallback) => {
  fetch("/api/datasets").then((response) =>
    handleJsonResponse(response, successCallback, errorCallback)
  );
};

export const fetchLessonDatasets = (
  lessonId,
  successCallback,
  errorCallback
) => {
  fetch(`/api/lesson/${lessonId}/dataset`).then((response) =>
    handleJsonResponse(response, successCallback, errorCallback)
  );
};

export const fetchDatasetVersions = (
  datasetId,
  successCallback,
  errorCallback
) => {
  fetch(`/api/dataset/${datasetId}/versions`).then((response) =>
    handleJsonResponse(response, successCallback, errorCallback)
  );
};

export const addDatasetsToLesson = (
  lessonId,
  datasetVersionIds,
  successCallback,
  errorCallback
) => {
  if (!Array.isArray(datasetVersionIds)) {
    datasetVersionIds = [datasetVersionIds];
  }
  fetch(`/api/lesson/${lessonId}/dataset`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ datasetVersionIds: datasetVersionIds }),
  }).then((response) =>
    handleJsonResponse(response, successCallback, errorCallback)
  );
};

export const removeDatasetFromLesson = (
  lessonId,
  datasetVersionId,
  successCallback,
  errorCallback
) => {
  fetch(`/api/lesson/${lessonId}/dataset/${datasetVersionId}`, {
    method: "DELETE",
  }).then((response) =>
    handleJsonResponse(response, successCallback, errorCallback)
  );
};

export const updateLessonDatasetVersion = (
  lessonId,
  oldDatasetVersionId,
  newDatasetVersionId,
  successCallback,
  errorCallback
) => {
  fetch(
    `/api/lesson/${lessonId}/dataset/${oldDatasetVersionId}/${newDatasetVersionId}`,
    {
      method: "PATCH",
    }
  ).then((response) =>
    handleJsonResponse(response, successCallback, errorCallback)
  );
};

export const downloadDataset = (
  datasetId,
  datasetVersionId,
  successCallback,
  errorCallback
) => {
  if (!datasetId) {
    errorCallback("Invalid dataset id.");
    return;
  }
  const body = datasetVersionId ? { datasetVersionId: datasetVersionId } : {};
  fetch(`/api/dataset/${datasetId}/download`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  }).then((response) =>
    handleBlobResponse(response, successCallback, errorCallback)
  );
};

export const archiveDataset = (datasetId, successCallback, errorCallback) => {
  fetch(`/api/dataset/${datasetId}`, { method: "DELETE" }).then((response) =>
    handleJsonResponse(response, successCallback, errorCallback)
  );
};

export const unarchiveDataset = (datasetId, successCallback, errorCallback) => {
  fetch(`/api/dataset/${datasetId}/unarchive`, { method: "PATCH" }).then(
    (response) => handleJsonResponse(response, successCallback, errorCallback)
  );
};
