import React, { useState } from "react";
import fileSaver from "file-saver";
import GetAppIcon from "@mui/icons-material/GetApp";

import { IconButton, Button, CircularProgress } from "@mui/material";

import { downloadDataset } from "../api/datasets";

function DatasetVersionDownloadButton(props) {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = () => {
    setIsDownloading(true);
    downloadDataset(
      props.datasetVersion.dataset_id,
      props.datasetVersion.id,
      (datasetBlob) => {
        setIsDownloading(false);
        fileSaver.saveAs(datasetBlob, `${props.datasetVersion.name}.zip`);
      },
      (error) => {
        setIsDownloading(false);
        console.log(error);
      }
    );
  };

  if (props.isIcon) {
    return (
      <IconButton
        color="primary"
        onClick={handleDownload}
        disabled={isDownloading}
      >
        {isDownloading ? <CircularProgress size={24} /> : <GetAppIcon />}
      </IconButton>
    );
  }

  return (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      onClick={handleDownload}
      disabled={isDownloading}
    >
      {isDownloading ? <CircularProgress size={24} /> : "Download"}
    </Button>
  );
}

export default DatasetVersionDownloadButton;
