export function importLessonsIntoRelease(
  lessons,
  release,
  successCallback,
  errorCallback
) {
  return fetch("/api/import-lessons", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      name: release.name,
      version_major: release.version_major,
      version_minor: release.version_minor,
      lessons: lessons,
    }),
  })
    .then((data) => data.json())
    .then((response) => successCallback(response))
    .catch((error) => errorCallback(error));
}

export function deleteMultipleChoiceQuestion(
  lessonId,
  screenId,
  question,
  successCallback,
  errorCallback
) {
  fetch(`/api/lesson/${lessonId}/screen/${screenId}/mcq`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ questionId: question.id }),
  })
    .then(() => successCallback())
    .catch((error) => errorCallback(error));
}

export function createOrUpdateMultipleChoiceQuestion(
  lessonId,
  screenId,
  question,
  successCallback,
  errorCallback,
  saveMessage = ""
) {
  const questionData = buildMultipleChoiceQuestionData(question);
  let body = { ...questionData, commitMessage: saveMessage };
  const isUpdate = !!question.id;
  if (isUpdate) {
    body = { ...body, questionId: question.id };
  }
  fetch(`/api/lesson/${lessonId}/screen/${screenId}/mcq`, {
    method: isUpdate ? "PUT" : "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((data) => data.json())
    .then((question) => successCallback(question))
    .catch((error) => errorCallback(error));
}

export function buildMultipleChoiceQuestionData(question) {
  return {
    select_type: question.select_type,
    question: question.question.trim(),
    answer_options: question.answer_options.map((answer) => ({
      label: answer.label.trim(),
      valid: answer.valid,
      feedback_message: answer.feedback_message.trim(),
      skill_id: answer.skill_id,
    })),
  };
}
