import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
} from "@mui/material";
import { makePythonString, isListType } from "../../utils/pycheck";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

export default function StringInput(props) {
  const [isEmptyString, setIsEmptyString] = useState(false);

  const onChangeHandler = (event) => {
    const newValue = event.target.value;
    props.valueUpdatedCallback(newValue, makePythonString(newValue));
  };

  const checkboxChangeHandler = (event) => {
    const newIsEmptyString = !isEmptyString;
    if (newIsEmptyString) {
      props.valueUpdatedCallback("", makePythonString(""));
    }
    setIsEmptyString(newIsEmptyString);
  };

  return (
    <Grid>
      <TextField
        label={props.label}
        fullWidth
        value={props.value}
        onChange={onChangeHandler}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isEmptyString}
      />
      <FormControlLabel
        control={
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            name="checkedI"
            color="primary"
            checked={isEmptyString}
            onChange={checkboxChangeHandler}
          />
        }
        label={<Typography variant="caption">Empty string</Typography>}
      />
    </Grid>
  );
}
