import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Stack,
  Alert,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import TooltipButton from "../general/TooltipButton";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SearchableTable from "../general/SearchableTable";
import { useHistory } from "react-router-dom";
import NewEntryForm from "../general/NewEntryForm";
import MakeSelectionModal from "../general/MakeSelectionModal";

export default function StepCourses(props) {
  const step = props.step;
  const history = useHistory();

  const [courses, setCourses] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [addingCourses, setAddingCourses] = useState(false);

  const FILTERS = [];

  const COLUMNS = [
    {
      key: "move",
      label: "",
      type: "component",
      render: (course, col, rowIndex, colIndex) => {
        return (
          <Stack direction="column">
            {rowIndex > 0 && (
              <TooltipButton
                title="Move course up"
                isIconButton={true}
                onClick={() => handleMove(course, true)}
                disabled={step.is_main}
              >
                <ArrowDropUpIcon />
              </TooltipButton>
            )}
            {rowIndex < courses.length - 1 && (
              <TooltipButton
                title="Move course up"
                isIconButton={true}
                onClick={() => handleMove(course, false)}
                disabled={step.is_main}
              >
                <ArrowDropDownIcon />
              </TooltipButton>
            )}
          </Stack>
        );
      },
    },
    {
      key: "name",
      label: "Course Title",
      type: "text",
    },
    {
      key: "release_name",
      label: "Release",
      type: "text",
    },
    {
      key: "skill_level",
      label: "Level",
      type: "text",
    },
    {
      key: "num_lessons",
      label: "Lessons",
      type: "text",
    },
    {
      key: "num_projects",
      label: "Projects",
      type: "text",
    },
    {
      key: "duration",
      label: "Time Estimate",
      type: "component",
      render: (course, col) => (course.duration ? `${course.duration}h` : ""),
    },
    {
      key: "deprecated",
      label: "Deprecated",
      type: "component",
      render: (course, col) => (course.deprecated ? "Yes" : "No"),
    },
    {
      key: "edit",
      label: "",
      type: "component",
      render: (course, col) => (
        <IconButton
          onClick={() => history.push(`/course/${course.id}`)}
          disabled={step.is_main}
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      key: "delete",
      label: "",
      type: "component",
      render: (course, col) => (
        <IconButton
          color="secondary"
          onClick={() => handleDelete(course)}
          disabled={step.is_main}
        >
          <CloseIcon />
        </IconButton>
      ),
    },
  ];

  const handleDelete = async (course) => {
    setLoading(true);
    setError("");
    try {
      const response = await fetch(`/api/step/${step.id}/course/${course.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });
      const courses = await response.json();
      if (courses.error) {
        setError(courses.error);
      } else {
        setCourses(courses);
      }
    } catch (error) {
      console.log(error);
      setError(
        "An error occured while trying to delete the course. Try again later."
      );
    }
    setLoading(false);
  };

  const handleMove = async (course, swapWithPrevious) => {
    setLoading(true);
    setError("");
    try {
      const response = await fetch(
        `/api/step/${step.id}/course/${course.id}/swap`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            swap_with_previous: swapWithPrevious,
          }),
        }
      );
      const courses = await response.json();
      if (courses.error) {
        setError(courses.error);
      } else {
        setCourses(courses);
      }
    } catch (error) {
      console.log(error);
      setError(
        "An error occured while trying to move the course. Try again later."
      );
    }
    setLoading(false);
  };

  const loadCourses = useCallback(async () => {
    try {
      setLoading(true);
      setError("");
      const response = await fetch(`/api/step/${step.id}/courses`);
      const courses = await response.json();
      if (courses.error) {
        setError(courses.error);
      } else {
        setCourses(courses);
      }
    } catch (error) {
      console.log(error);
      setError(
        "An error occured while loading the courses in this step. Try again later."
      );
    }
    setLoading(false);
  }, [step.id]);

  const addNewCourse = async (courseSequence, courseName) => {
    setError("");
    try {
      let response = await fetch(`/api/course`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sequence: courseSequence,
          name: courseName,
          release_id: step.release_id,
        }),
      });
      const course = await response.json();
      if (course.error) {
        setError(course.error);
        return;
      }
      response = await fetch(`/api/step/${step.id}/course/${course.id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });
      const courses = await response.json();
      if (courses.error) {
        setError(courses.error);
        return;
      }
      setCourses(courses);
    } catch (error) {
      console.log(error);
      setError("An error occured. Try again later.");
    }
  };

  const renderControls = () => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={1}
      >
        <NewEntryForm
          variant="outlined"
          newLabel="New Course"
          addLabel="Create Course"
          nameLabel="Course Name"
          handleAdd={addNewCourse}
          disabled={step.is_main}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={() => setAddingCourses(true)}
          disabled={step.is_main}
        >
          Add Courses
        </Button>
      </Stack>
    );
  };

  useEffect(() => {
    loadCourses();
  }, [loadCourses]);

  const loadAllCourses = async () => {
    const response = await fetch(`/api/courses`);
    return await response.json();
  };

  const addCourseToStep = async (course) => {
    const response = await fetch(`/api/step/${step.id}/course/${course.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    return await response.json();
  };

  const closeHandler = () => {
    setAddingCourses(false);
    loadCourses();
  };

  return (
    <Box p={1}>
      <Typography variant="subtitle2">Courses</Typography>
      {error && (
        <Alert sx={{ mb: 2 }} severity="error">
          {error}
        </Alert>
      )}
      <MakeSelectionModal
        title="Add Courses to Step"
        selectHeader="Select Course"
        selectionHeader="Selected Courses"
        loadMessage="Loading Courses..."
        emptySelectionText="No Courses Selected"
        noElementsText="No Courses"
        isOpen={addingCourses}
        closeHandler={closeHandler}
        renderElement={(course) => course.name}
        loadElements={loadAllCourses}
        addElement={addCourseToStep}
      />
      {renderControls()}
      <SearchableTable
        cols={COLUMNS}
        rows={courses}
        isLoading={loading}
        filters={FILTERS}
        rowsPerPage={courses.length}
        searchable={false}
      />
    </Box>
  );
}
