import React, { useState, useEffect } from "react";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";

import {
  IconButton,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@mui/material";

import ScreenTest from "./ScreenTest";

const styles = {
  closeButton: {
    position: "absolute",
    top: "15px",
    right: "15px",
    "&:hover": {
      cursor: "pointer",
      color: "#F50057",
    },
  },
};

function ScreenTestsModal(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [screenTests, setScreenTests] = useState([]);

  const handleClose = () => {
    props.close();
  };

  const fetchScreenTests = () => {
    setLoading(true);
    fetch(
      `/api/lesson/${props.lessonId}/screen/${props.screenId}/load-screen-tests`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: {},
      }
    )
      .then((data) => data.json())
      .then((screenTests) => {
        setLoading(false);
        setScreenTests(screenTests.sort((x, y) => x.id - y.id));
      });
  };

  const showPrevious = () => {
    if (!hasPrevious()) return;
    setCurrentIndex(Math.max(currentIndex - 1, 0));
  };

  const showNext = () => {
    if (!hasNext()) return;
    setCurrentIndex(Math.min(currentIndex + 1, screenTests.length - 1));
  };

  useEffect(() => {
    fetchScreenTests();
  }, []);

  const renderScreenTests = () => {
    if (screenTests.length === 0) {
      return <Typography>This screen doesn't have tests yet.</Typography>;
    }
    return screenTests.map((screenTest, index) => (
      <ScreenTest
        key={screenTest.id}
        isVisible={index === currentIndex}
        screenTest={screenTest}
        runCodeWithCallback={props.runCodeWithCallback}
        updateTest={updateTest}
        deleteTest={deleteTest}
        customCheckCode={props.customCheckCode}
      />
    ));
  };

  const hasPrevious = () => {
    return screenTests && screenTests.length > 0 && currentIndex > 0;
  };

  const hasNext = () => {
    return (
      screenTests &&
      screenTests.length > 0 &&
      currentIndex < screenTests.length - 1
    );
  };

  const updateTest = (description, runResult) => {
    const body = {
      testId: screenTests[currentIndex].id,
      description: description,
      runResult: runResult,
    };
    fetch(`/api/lesson/update-screen-test`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((data) => data.json())
      .then((updatedScreenTest) => {
        setScreenTests(
          screenTests.map((screenTest) =>
            screenTest.id === updatedScreenTest.id
              ? updatedScreenTest
              : screenTest
          )
        );
      })
      .catch((error) => console.log(error));
  };

  const deleteTest = (callback) => {
    const body = {
      testId: screenTests[currentIndex].id,
    };
    fetch(`/api/lesson/delete-screen-test`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((data) => data.json())
      .then(() => {
        setScreenTests(
          screenTests.filter(
            (screenTest) => screenTest.id !== screenTests[currentIndex].id
          )
        );
        setCurrentIndex(Math.max(0, currentIndex - 1));
        callback();
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.isOpen}
        fullWidth
        maxWidth="xl"
      >
        <CloseIcon sx={styles.closeButton} onClick={handleClose} />
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Screen Tests
        </DialogTitle>
        <DialogContent dividers>
          {loading || !screenTests ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Grid>
          ) : (
            renderScreenTests()
          )}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <IconButton disabled={!hasPrevious()} onClick={showPrevious}>
                  <ArrowBackIosIcon />
                </IconButton>
                <Typography>
                  {screenTests && screenTests.length > 0 ? currentIndex + 1 : 0}{" "}
                  / {screenTests.length}
                </Typography>
                <IconButton disabled={!hasNext()} onClick={showNext}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ScreenTestsModal;
