import { handleJsonResponse } from "./generic";

function request(endpoint, method, body, successCallback, errorCallback) {
  fetch(endpoint, {
    method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  }).then((response) =>
    handleJsonResponse(response, successCallback, errorCallback)
  );
}

export function runCode(
  lessonId,
  code,
  screenIndex,
  successCallback,
  errorCallback
) {
  const body = {
    lesson_id: lessonId,
    code: code,
    custom_checker_id: screenIndex,
  };
  request("/api/run-code", "POST", body, successCallback, errorCallback);
}

export function getRunnerConnection(lessonId, successCallback, errorCallback) {
  const body = { lesson_id: lessonId };
  request(
    "/api/get-runner-connection",
    "POST",
    body,
    successCallback,
    errorCallback
  );
}

export function runScreenTest(screenTestId, successCallback, errorCallback) {
  const body = { screenTestId };
  request("/api/run-screen-test", "POST", body, successCallback, errorCallback);
}
