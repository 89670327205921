import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const multipleChoiceQuestionsSlice = createSlice({
  name: "assessmentSkillsSlice",
  initialState: initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setScreenMcqs(state, action) {
      const screenId = action.payload.screenId;
      const multipleChoiceQuestions = action.payload.multipleChoiceQuestions;
      state[screenId] = multipleChoiceQuestions;
    },
    updateMcq(state, action) {
      const screenId = action.payload.screenId;
      const questionIndex = action.payload.questionIndex;
      const data = action.payload.data;
      state[screenId][questionIndex] = {
        ...state[screenId][questionIndex],
        ...data,
      };
    },
  },
});

export const multipleChoiceQuestionsActions =
  multipleChoiceQuestionsSlice.actions;

export default multipleChoiceQuestionsSlice.reducer;
