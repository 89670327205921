import React, { useState } from "react";
import { checkPackagesSupport } from "./utils";
import {
  Typography,
  ListItem,
  Box,
  TextField,
  ListItemSecondaryAction,
  IconButton,
  List,
  Button,
  Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { lessonDataActions } from "../store/lessonDataSlice";
import { LabelledOutline } from "../general/LabelledOutline";
import BuildNotification from "./BuildNotification";
import ConfirmModal from "../general/ConfirmModal";

export default function LessonPackages({ lesson }) {
  const dispatch = useDispatch();
  const [newPackageName, setNewPackageName] = useState("");
  const [saveWarning, setSaveWarning] = useState(false);
  const [error, setError] = useState("");
  const [building, setBuilding] = useState(false);
  const [deletingPycheck, setDeletingPycheck] = useState(false);

  const buildPackages = async () => {
    if (newPackageName !== "" && !saveWarning) {
      setSaveWarning(true);
      return;
    }
    setError("");
    setBuilding(true);
    setSaveWarning(false);
    setNewPackageName("");

    dispatch(lessonDataActions.resetBuildStatus(lesson.id));
    try {
      const response = await fetch(`/api/lesson/${lesson.id}/packages`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          packages: lesson.packages,
          legacy_check_enabled: lesson.legacy_check_enabled,
          custom_check_enabled: lesson.custom_check_enabled,
        }),
      });
      const responseData = await response.json();
      if (responseData.error) {
        setError(responseData.error);
      } else {
        const payload = {
          lessonId: lesson.id,
          packages: responseData.packages,
          legacy_check_enabled: responseData.legacy_check_enabled,
          custom_check_enabled: responseData.custom_check_enabled,
          last_build: responseData.last_build,
          backend: true,
        };
        dispatch(lessonDataActions.setPackagesAfterBuild(payload));
      }
    } catch (error) {
      console.log(error);
      setError(
        "An error occurred while trying to build packages. Try again later."
      );
    }
    setBuilding(false);
  };

  const renderPackage = (packageDetails) => {
    if (!packageDetails.show_in_editor) return null;

    let isPycheck = packageDetails.name === "pycheck";
    return (
      <Box key={packageDetails.name}>
        <ListItem>
          <Box className="package-dependency">
            <Box display="flex" flexDirection="column">
              <Typography>{packageDetails.name}</Typography>
              {isPycheck ? (
                <Typography color="secondary" variant="caption">
                  Custom answer checking
                </Typography>
              ) : null}
            </Box>
            <TextField
              required={true}
              label={isPycheck ? "Commit hash" : "Version"}
              value={packageDetails.version}
              onChange={(event) =>
                dispatch(
                  lessonDataActions.updatePackageVersion({
                    lessonId: lesson.id,
                    packageName: packageDetails.name,
                    packageVersion: event.target.value,
                  })
                )
              }
              size="small"
              disabled={lesson.is_shallow_import || building}
            />
          </Box>
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              onClick={() => handleDeletePackage(packageDetails)}
              disabled={lesson.is_shallow_import || building}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </Box>
    );
  };

  const handleAddPackage = () => {
    dispatch(
      lessonDataActions.addPackage({
        lessonId: lesson.id,
        packageName: newPackageName,
      })
    );
    dispatch(
      lessonDataActions.removeInProgressField({
        lessonId: lesson.id,
        fieldName: "Package name",
      })
    );

    setSaveWarning(false);
    setNewPackageName("");
  };

  const handleNewPackageChange = (event) => {
    setNewPackageName(event.target.value);
    setSaveWarning(false);

    dispatch(
      lessonDataActions.addInProgressField({
        lessonId: lesson.id,
        fieldName: "Package name",
      })
    );
  };

  const handleDeletePackage = (packageDetails) => {
    if (packageDetails.name === "pycheck") {
      setDeletingPycheck(true);
    } else {
      deletePackage(packageDetails.name);
    }
  };

  const deletePackage = (packageName) => {
    dispatch(
      lessonDataActions.removePackage({
        lessonId: lesson.id,
        packageName,
      })
    );
  };

  const renderPackages = () => {
    if (lesson.language === "cli") return null;
    return (
      <List>
        {lesson.packages.map((packageDetails) => renderPackage(packageDetails))}
        <ListItem>
          <TextField
            label="Package name"
            fullWidth
            variant="outlined"
            disabled={lesson.is_shallow_import || !lesson.dependencies_ready}
            value={newPackageName}
            onChange={handleNewPackageChange}
            size="small"
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              disabled={
                lesson.is_shallow_import ||
                newPackageName.length === 0 ||
                building
              }
              onClick={handleAddPackage}
            >
              <AddIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    );
  };

  if (!checkPackagesSupport(lesson.enabled_features)) return null;

  return (
    <LabelledOutline label="Packages">
      <ConfirmModal
        isOpen={deletingPycheck}
        cancel={() => setDeletingPycheck(false)}
        confirm={() => deletePackage("pycheck")}
        message={`Are you sure that you want to remove custom answer checking?`}
      />
      <Box display="flex" flexDirection="column">
        <BuildNotification lesson={lesson} />
        {error && <Alert severity="error">{error}</Alert>}
        {saveWarning && (
          <Alert severity="warning">
            You have incomplete package changes. Please finalize the input or
            click on Build again to discard them.
          </Alert>
        )}
        {renderPackages()}
        <Button
          variant="contained"
          disabled={lesson.is_shallow_import || building}
          onClick={buildPackages}
          size="small"
          sx={{
            mt: 1,
          }}
        >
          Build
        </Button>
      </Box>
    </LabelledOutline>
  );
}
