import React, { Component, useState } from "react";

import { withRouter, NavLink } from "react-router-dom";

import {
  Box,
  Button,
  IconButton,
  CircularProgress,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DisableableLink from "./general/DisableableLink";
import LessonListInputModal from "./LessonListInputModal";
import AddReleaseModal from "./AddReleaseModal";
import EditReleaseModal from "./EditReleaseModal";
import { PUBLISHED_STATUS } from "./constants";

function ReleaseRow(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lessons, setLessons] = useState([]);

  const fetchReleaseLessons = () => {
    setLoading(true);
    fetch("/api/get-lessons", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: props.release.name,
        version_major: props.release.version_major,
        version_minor: props.release.version_minor,
      }),
    })
      .then((data) => data.json())
      .then((body) => {
        setLoading(false);
        setLessons(body.lessons);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderLessonTable = () => {
    if (lessons) {
      return (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Number</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Title</TableCell>
                <TableCell align="center">Author</TableCell>
                <TableCell align="center">Updated</TableCell>
                <TableCell align="center">Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lessons.map((lesson, index) => (
                <TableRow key={lesson.id}>
                  <TableCell align="left">{lesson.sequence}</TableCell>
                  <TableCell align="left">{lesson.type}</TableCell>
                  <TableCell align="left">{lesson.title}</TableCell>
                  <TableCell align="center">{lesson.author}</TableCell>
                  <TableCell align="center">{lesson.last_updated}</TableCell>
                  <TableCell align="center">
                    <DisableableLink
                      to={`/lesson/${lesson.id}`}
                      disabled={lesson.release_status === PUBLISHED_STATUS}
                    >
                      <IconButton
                        edge="end"
                        disabled={lesson.release_status === PUBLISHED_STATUS}
                      >
                        <EditIcon />
                      </IconButton>
                    </DisableableLink>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
    return (
      <Typography>No lessons where imported for this release yet.</Typography>
    );
  };

  const renderReleaseLessons = () => (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box>{loading ? <CircularProgress /> : renderLessonTable()}</Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );

  const renderPullRequest = () => {
    if (!props.release.pull_request) {
      return null;
    }

    return (
      <Button
        endIcon={<OpenInNewIcon />}
        target="_blank"
        href={props.release.pull_request.url}
      >
        {props.release.pull_request.status}
      </Button>
    );
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open);
              fetchReleaseLessons();
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{props.release.name}</TableCell>
        <TableCell align="left">{props.release.version_major}</TableCell>
        <TableCell align="left">{props.release.version_minor}</TableCell>
        <TableCell align="left">{renderPullRequest()}</TableCell>
        {/*
        <TableCell align="center">
          <IconButton
            onClick={() => alert("TODO: backend route to delete release.")}
            disabled={props.release.is_main}
          >
            <DeleteIcon/>
          </IconButton>
        </TableCell>
        */}
        <TableCell align="center">
          <IconButton onClick={props.importHandler}>
            <SystemUpdateAltIcon />
          </IconButton>
        </TableCell>
        <TableCell align="center">
          <IconButton
            onClick={props.editHandler}
            disabled={props.release.is_main}
          >
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {open && renderReleaseLessons()}
    </React.Fragment>
  );
}

class ReleaseList extends Component {
  state = {
    releases: [],
    addingRelease: false,
    editingRelease: false,
    importingLessons: false,
    user: {},
    selectedRelease: null,
  };

  componentDidMount() {
    this.fetchReleases();
  }

  fetchReleases() {
    fetch("/api/releases")
      .then((data) => data.json())
      .then((releases) => {
        releases = {
          releases: releases
            .map((release) => ({
              ...release,
              actions: { sequence: release.sequence },
            }))
            .sort((a, b) => a.sequence - b.sequence),
        };
        return this.setState(releases);
      });
  }

  renderTable = () => (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Branch name</TableCell>
            <TableCell align="left">Commit</TableCell>
            <TableCell align="left">Pull Request</TableCell>
            {/*<TableCell align="center">Delete</TableCell>*/}
            <TableCell align="center">Import</TableCell>
            <TableCell align="center">Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.releases.map((row) => (
            <ReleaseRow
              key={`${row.name}-${row.version_major}-${row.version_minor}`}
              release={row}
              importHandler={() =>
                this.setState({ selectedRelease: row, importingLessons: true })
              }
              editHandler={() =>
                this.setState({ selectedRelease: row, editingRelease: true })
              }
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  closeAndUpdate = (needsUpdate) => {
    if (needsUpdate) {
      this.fetchReleases();
    }
    this.setState({
      addingRelease: false,
      editingRelease: false,
      importingLessons: false,
    });
  };

  renderModal = () => {
    if (this.state.addingRelease) {
      return (
        <AddReleaseModal
          open={this.state.addingRelease}
          closeModal={this.closeAndUpdate}
        />
      );
    }
    if (this.state.editingRelease) {
      return (
        <EditReleaseModal
          open={this.state.editingRelease}
          closeModal={this.closeAndUpdate}
          release={this.state.selectedRelease}
          releases={this.state.releases}
        />
      );
    }
    if (this.state.importingLessons) {
      return (
        <LessonListInputModal
          open={this.state.importingLessons}
          closeModal={this.closeAndUpdate}
          release={this.state.selectedRelease}
        />
      );
    }
    return null;
  };

  render() {
    return (
      <>
        {this.renderModal()}
        <Box display="flex" alignItems="center" flexDirection="column">
          <div className="dq-flex dq-w-4/5 dq-my-5 dq-justify-between">
            <h1 className="dq-font-bold dq-text-2xl dq-w-2/3 dq-m-0">
              Releases
            </h1>
            <Button
              color="primary"
              variant="contained"
              disabled={this.state.addingRelease}
              onClick={() => this.setState({ addingRelease: true })}
            >
              Add Release
            </Button>
          </div>
          <div className="dq-flex dq-w-4/5 dq-my-5 dq-justify-between">
            {this.renderTable()}
          </div>
        </Box>
      </>
    );
  }
}

export default withRouter(ReleaseList);
