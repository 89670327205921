export function buildAnswerCode(screen) {
  let answerCode = "";
  if (
    screen.should_run_display_code &&
    screen.display_code &&
    screen.display_code.trim().length > 0
  ) {
    answerCode += screen.display_code;
  }
  if (screen.answer_code) {
    if (answerCode.trim().length > 0) {
      answerCode += "\n\n";
    }
    answerCode += screen.answer_code;
  }
  return answerCode;
}
