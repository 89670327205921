export default function checkMultipleChoiceQuestion(question, selectedAnswers) {
  let validCount = 0;
  let validSelected = 0;
  const results = {};
  for (
    var answerIndex = 0;
    answerIndex < question.answer_options.length;
    answerIndex++
  ) {
    const answer = question.answer_options[answerIndex];
    validCount += answer.valid;
    if (selectedAnswers.includes(answerIndex)) {
      validSelected += answer.valid;
      results[answerIndex] = {
        correct: answer.valid,
        feedback_message: answer.feedback_message,
      };
    }
  }
  return {
    correct:
      validCount === validSelected &&
      Object.keys(results).length === validSelected,
    results: results,
  };
}
