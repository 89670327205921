import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const styles = {
  closeButton: {
    position: "absolute",
    top: "15px",
    right: "15px",
    "&:hover": {
      cursor: "pointer",
      color: "#F50057",
    },
  },
};

function Modal(props) {
  return (
    <Dialog
      onClose={props.closeHandler}
      aria-labelledby="customized-dialog-title"
      open={props.isOpen}
      fullWidth
      {...props.muiDialog}
    >
      <CloseIcon sx={styles.closeButton} onClick={props.closeHandler} />
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>{props.content}</DialogContent>
      <DialogActions>{props.actions}</DialogActions>
    </Dialog>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  muiDialog: PropTypes.object,
  title: PropTypes.string,
  content: PropTypes.element,
  actions: PropTypes.element,
};

Modal.defaultProps = {
  isOpen: false,
  closeHandler: () => {},
  muiDialog: {},
};

export default Modal;
