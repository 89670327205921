import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default class ConfirmDelete extends Component {
  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <DialogTitle>Delete {this.props.type}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {this.props.name}? This can't be
            undone.
          </DialogContentText>
          <DialogActions>
            <Button
              color="secondary"
              variant="contained"
              onClick={this.props.delete}
            >
              Delete
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.props.onClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}
