import React from "react";
import { useSelector } from "react-redux";
import { Box, List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { CUSTOM_TEST_TAB } from "./code-tabs/constants";

export default function ChecksSection({ lesson, screen }) {
  const codeEditor = useSelector((state) => state.codeEditor)[screen.id];

  const currentTab = codeEditor.currentTab;
  const customCheckOutput = codeEditor.runResult.customCheckOutput;
  const testCheckOutput = codeEditor.customTest.customCheckOutput;

  if (!lesson.custom_check_enabled && !lesson.legacy_check_enable) return null;

  if (codeEditor.currentTab === CUSTOM_TEST_TAB) {
    if (!testCheckOutput) return null;
  } else {
    if (!customCheckOutput) return null;
  }

  const renderCustomCheckMessages = (header, messages) => {
    if (!messages?.length) return;

    return (
      <Box>
        <Box style={{ fontWeight: "bold" }}>{header}:</Box>
        <ul>
          {messages.map((message, index) => (
            <ReactMarkdown
              key={`${index}-${message}`}
              children={message}
              remarkPlugins={[remarkGfm]}
            />
          ))}
        </ul>
      </Box>
    );
  };

  const renderFailedChecks = (customCheckOutput) => {
    const { results, meta } = customCheckOutput;
    if (!results?.length && !meta?.stderr && !meta?.error) return null;
    const feedback = results?.flatMap((result) => result.feedback);
    const hints = results?.flatMap((result) => result.hint);
    return (
      <Box className="check-messages">
        {renderCustomCheckMessages("Feedback messages", feedback)}
        {renderCustomCheckMessages("Hints", hints)}
        {!!meta?.stderr && (
          <code className="dq-bg-transparent dq-text-white">{meta.stderr}</code>
        )}
        {meta?.error}
      </Box>
    );
  };

  const renderGraderSection = (graderOutput) => {
    const feedbackMessages = graderOutput.answer_checker_feedback;

    return (
      <Box className="output-custom">
        <Box
          p={1}
          color="white"
          bgcolor={graderOutput.correct ? "primary.main" : "error.main"}
        >
          Assessment Results
        </Box>
        {this.renderTextOutput(
          feedbackMessages ? feedbackMessages.join(", ") : ""
        )}
        <List dense className="output-text">
          {graderOutput.skill_names.map((skill_name) => {
            const pass = graderOutput.skill_results[skill_name] > 0;
            return (
              <ListItem key={skill_name}>
                <ListItemIcon>
                  {pass ? (
                    <CheckIcon fontSize="small" style={{ color: "#fff" }} />
                  ) : (
                    <CloseIcon fontSize="small" color="error" />
                  )}
                </ListItemIcon>
                <ListItemText primary={skill_name} />
              </ListItem>
            );
          })}
        </List>
      </Box>
    );
  };

  const renderCustomCheckOutput = (header, customCheckOutput) => (
    <Box className="output-custom">
      <Box>
        <Box
          p={1}
          color="white"
          bgcolor={customCheckOutput.pass ? "primary.main" : "error.main"}
        >
          {header}
        </Box>
        {customCheckOutput.pass ? (
          <Box className="output-text">All passed.</Box>
        ) : (
          <Box className="output-text output-text-stderr">
            Wrong answer: {customCheckOutput.results?.length || "All"} check(s)
            failed.
          </Box>
        )}
        {renderFailedChecks(customCheckOutput)}
      </Box>
    </Box>
  );

  const renderTestResultSection = () => {
    if (!testCheckOutput) return null;
    return renderCustomCheckOutput("Test result", testCheckOutput);
  };

  if (currentTab === CUSTOM_TEST_TAB) {
    return renderTestResultSection();
  }

  if (
    customCheckOutput.grader &&
    Object.keys(customCheckOutput.grader).length > 0
  ) {
    return renderGraderSection(customCheckOutput.grader);
  }

  return renderCustomCheckOutput("Custom checks", customCheckOutput);
}
