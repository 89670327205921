import React, { Component } from "react";
import CodeMirror from "codemirror";
import {
  AppBar,
  Box,
  Button,
  LinearProgress,
  Paper,
  Toolbar,
  Typography,
  ButtonGroup,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import {
  Save as SaveIcon,
  InsertPhoto as InsertPhotoIcon,
  VideoCallOutlined as VideoCallOutlinedIcon,
  AddPhotoAlternate as AddPhotoAlternateIcon,
} from "@mui/icons-material";

import Modal from "./general/Modal";
import SaveModal from "./general/SaveModal";
import VideoFormModal from "./modals/VideoFormModal";
import ImageGalleryModal from "./modals/ImageGalleryModal";
import store from "./store";
import { fetchLesson } from "./store/lessonStatusSlice";

require("codemirror/lib/codemirror.css");
require("codemirror/mode/markdown/markdown");
const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB

export default class MarkdownEditor extends Component {
  state = {
    codemirror: null,
    textarea_ref: null,
    current_text: "",
    uploading: false,
    upload_error: false,
    images: [],
    saveModalIsOpen: false,
    imageModalIsOpen: false,
    imageErrorModalIsOpen: false,
    imageOverwriteFile: null,
    warningMessage: "",
  };

  componentDidMount() {
    this.setState({ current_text: this.props.value });
    if (!this.props.textOnly) this.getImages();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.textarea_ref && !prevState.textarea_ref) {
      const codemirror = new CodeMirror.fromTextArea(this.state.textarea_ref, {
        lineNumbers: false,
        mode: "markdown",
        indentUnit: 4,
        lineWrapping: true,
      });
      codemirror.on("change", this.updateMarkdown);
      this.setState({ codemirror });
    }
  }

  set_text_area_ref = (ref) => {
    this.setState({ textarea_ref: ref });
  };

  updateMarkdown = (doc) => {
    this.setState({ upload_error: false });
    this.props.handleChange(doc.getValue());
  };

  insertImage = (image) => {
    this.insertAtCursor(this.formatImageMarkdown(image.s3_url));
  };

  handleImageWarningOnClose = () => {
    this.setState({
      warningMessage: "",
    });
  };

  handleImageErrorOnClose = () =>
    this.setState({ imageErrorModalIsOpen: false });

  handleImageOverwriteOnConfirm = () => {
    this.uploadImage(this.state.imageOverwriteFile, true).then(() =>
      this.handleImageOverwriteModalOnClose()
    );
  };

  handleImageOverwriteModalOnClose = () =>
    this.setState({ imageOverwriteFile: null });

  renderImageWarning = () =>
    this.state.warningMessage && (
      <MuiAlert
        elevation={6}
        variant="outlined"
        onClose={this.handleImageWarningOnClose}
        severity="warning"
        sx={{ width: "80%" }}
      >
        {this.state.warningMessage}
      </MuiAlert>
    );

  render() {
    return (
      <>
        <Modal
          isOpen={this.state.imageErrorModalIsOpen}
          closeHandler={this.handleImageErrorOnClose}
          title="Upload error"
          content={
            <Box data-test-selector="file-upload-size-err">
              Selected image exceeds the maximum recommended size of{" "}
              {MAX_IMAGE_SIZE / 1024 / 1024} MB.
            </Box>
          }
          actions={
            <Button
              color="secondary"
              variant="contained"
              onClick={this.handleImageErrorOnClose}
            >
              Cancel
            </Button>
          }
        />
        <Modal
          isOpen={!!this.state.imageOverwriteFile}
          closeHandler={this.handleImageOverwriteModalOnClose}
          title="Image already exists"
          content={
            <Box data-test-selector="file-upload-overwrite-err">
              An image with the same name already exists. Are you sure you want
              to overwrite it?
            </Box>
          }
          actions={
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={this.handleImageOverwriteOnConfirm}
              >
                Overwrite
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={this.handleImageOverwriteModalOnClose}
              >
                Cancel
              </Button>
            </>
          }
        />
        <SaveModal
          isOpen={this.state.saveModalIsOpen}
          close={() => this.setState({ saveModalIsOpen: false })}
          save={this.props.save}
        />
        <ImageGalleryModal
          images={this.state.images}
          imageSelectedCallback={this.insertImage}
          isOpen={this.state.imageModalIsOpen}
          closeHandler={() => this.setState({ imageModalIsOpen: false })}
        />
        <VideoFormModal
          isOpen={this.state.videoModalIsOpen}
          addVideo={this.addVideo}
          closeHandler={() => this.setState({ videoModalIsOpen: false })}
        />
        <Box className="markdown-editor">
          <AppBar
            variant="outlined"
            color="transparent"
            position="static"
            className="markdown-menu"
          >
            <Toolbar
              disableGutters
              variant="dense"
              className="dq-flex dq-flex-col dq-space-y-2"
            >
              <ButtonGroup className="dq-self-start">
                <Button
                  className="dq-m-0 dq-px-2"
                  variant="outlined"
                  startIcon={<SaveIcon fontSize="small" />}
                  onClick={this.props.save}
                >
                  Save
                </Button>
                <Button
                  className="dq-m-0 dq-px-2"
                  variant="outlined"
                  startIcon={<SaveIcon fontSize="small" />}
                  onClick={() => this.setState({ saveModalIsOpen: true })}
                >
                  Save with message
                </Button>
              </ButtonGroup>
              {!this.props.textOnly && (
                <ButtonGroup className="dq-self-start">
                  <Button
                    component="label"
                    className="dq-m-0 dq-px-2"
                    variant="outlined"
                    startIcon={<AddPhotoAlternateIcon fontSize="small" />}
                    disabled={this.state.uploading}
                  >
                    Upload New
                    <input
                      type="file"
                      accept="image/*"
                      data-test-selector="file-upload"
                      hidden
                      onChange={(event) => {
                        this.uploadImage(event.target.files[0]);
                      }}
                    />
                  </Button>
                  <Button
                    className="dq-m-0 dq-px-2"
                    variant="outlined"
                    startIcon={<InsertPhotoIcon fontSize="small" />}
                    disabled={this.state.uploading}
                    onClick={() => this.setState({ imageModalIsOpen: true })}
                  >
                    Add Image
                  </Button>
                  <Button
                    className="dq-m-0 dq-px-2"
                    variant="outlined"
                    startIcon={<VideoCallOutlinedIcon fontSize="small" />}
                    onClick={() => this.setState({ videoModalIsOpen: true })}
                  >
                    Add Video
                  </Button>
                </ButtonGroup>
              )}
              {this.renderImageError()}
              {this.renderImageWarning()}
            </Toolbar>
            {this.state.uploading ? (
              <LinearProgress
                color="secondary"
                data-test-selector="file-upload-progress"
              />
            ) : null}
          </AppBar>
          <Paper variant="outlined" square>
            <textarea
              id={`markdown-editor-${this.props.section}`}
              defaultValue={this.state.current_text}
              ref={this.set_text_area_ref}
            />
          </Paper>
        </Box>
      </>
    );
  }

  renderImageError() {
    if (!this.state.upload_error) return null;
    return (
      <Box
        textAlign="center"
        p={1}
        color="white"
        borderRadius={8}
        bgcolor="error.light"
      >
        <Typography variant="body2">Error: Failed to upload image</Typography>
      </Box>
    );
  }

  uploadImage = (file, overwrite = false) => {
    if (!file) return;

    if (file.size > MAX_IMAGE_SIZE) {
      this.setState({ imageErrorModalIsOpen: true });
      return;
    }

    const doc = this.state.codemirror.getDoc();
    const cursor = doc.getCursor();

    const placeholder = "[Uploading image...]";
    this.insertAtCursor(placeholder);

    const replace_from = { line: cursor.line, ch: cursor.ch };
    const replace_to = {
      line: cursor.line,
      ch: cursor.ch + placeholder.length,
    };

    this.setState({ uploading: true });
    const formData = new FormData();
    formData.append("file", file);
    if (overwrite) formData.append("overwrite", "true");

    return fetch(`/api/lesson/${this.props.lesson_id}/image`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.status === 409) throw new Error("409");
        return response.json();
      })
      .then((image) => {
        doc.replaceRange(
          this.formatImageMarkdown(image.s3_url),
          replace_from,
          replace_to
        );

        this.getImages();
        // We should fetch data with a minor delay to ensure that Celery task on the backend has finished execution.
        setTimeout(
          () => store.dispatch(fetchLesson(this.props.lesson_id)),
          1500
        );
        this.setState({ uploading: false });
        const that = this;
        const img = document.createElement("img");

        img.onload = function () {
          const displayWarning =
            img.width > 1600 || image.height > 1600 || file.size > 750000;

          if (displayWarning)
            that.setState({
              warningMessage:
                "The image was uploaded. We recommend optimizing the image to be less than 1600x1600 pixels and a size less than 750KB",
            });
        };

        img.src = URL.createObjectURL(file);
      })
      .catch((err) => {
        doc.replaceRange("", replace_from, replace_to);
        if (err?.message === "409")
          this.setState({ imageOverwriteFile: file, uploading: false });
        else {
          this.setState({ uploading: false, upload_error: true });
        }
      });
  };

  addVideo = (url, isYoutube) => {
    this.insertAtCursor(this.formatVideoMarkdown(url, isYoutube));
  };

  formatVideoMarkdown = (url, isYoutube) => {
    if (isYoutube)
      return `\n<iframe class="player" type="text/html" allowFullScreen="allowFullScreen" frameBorder="0" src="${url}">\n</iframe>\n`;
    else
      return `\n<video controls>\n<source src="${url}">\nYour browser does not support the video tag.\n</video>\n`;
  };

  formatImageMarkdown(url) {
    return `\n<center>\n<img src="${url}">\n</center>\n`;
  }

  deleteImage = (image_id) => {
    fetch(`/api/image/${image_id}`, {
      method: "DELETE",
    }).then(() => {
      this.getImages();
    });
  };

  getImages = () => {
    fetch(`/api/lesson/${this.props.lesson_id}/images`)
      .then((response) => response.json())
      .then((images) =>
        this.setState({ images: images instanceof Array ? images : [] })
      );
  };

  insertAtCursor(str) {
    const doc = this.state.codemirror.getDoc();
    const cursor = doc.getCursor();

    const pos = {
      line: cursor.line,
      ch: cursor.ch,
    };

    doc.replaceRange(str, pos);
  }
}
