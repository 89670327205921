import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  screenError: {},
};

const errorSlice = createSlice({
  name: "errorSlice",
  initialState: initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setScreenError(state, action) {
      const screenId = action.payload.screenId;
      const error = action.payload.error;
      state.screenError[screenId] = error;
    },
  },
});

export const errorActions = errorSlice.actions;

export default errorSlice.reducer;
