import React, { useEffect } from "react";
import { Alert, AlertTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { lessonDataActions } from "../store/lessonDataSlice";
import _ from "lodash";
import { useInterval } from "../utils/hooks";

export default function BuildNotification({ lesson }) {
  const initialLesson = useSelector((state) => state.lessonData.initialLessons)[
    lesson.id
  ];

  const dispatch = useDispatch();

  const needsRebuild = () => {
    // TODO: I think that there are more cases where we need to rebuild because of JEG
    // We should also add these conditions here.
    if (lesson.custom_check_enabled !== initialLesson.custom_check_enabled)
      return true;
    if (lesson.legacy_check_enabled !== initialLesson.legacy_check_enabled)
      return true;
    return !_.isEqual(lesson.packages, initialLesson.packages);
  };

  const fetchBuildStatus = async () => {
    if (!lesson.has_runner) return null;
    const response = await fetch(`/api/lesson/${lesson.id}`);
    const responseData = await response.json();

    const dependenciesReady = responseData.dependencies_ready;
    const dependencyBuildError = responseData.dependency_build_error;
    if (
      lesson.dependencies_ready !== dependenciesReady ||
      lesson.dependency_build_error !== dependencyBuildError
    ) {
      dispatch(
        lessonDataActions.setBuildStatus({
          lessonId: lesson.id,
          dependencies_ready: dependenciesReady,
          dependency_build_error: dependencyBuildError,
          backend: true,
        })
      );
    }
  };

  useInterval(fetchBuildStatus, 5000);
  if (!lesson.has_runner) return null;

  if (!lesson.last_build) {
    return <Alert severity="warning">Dependencies were not built yet</Alert>;
  }

  if (needsRebuild()) {
    return <Alert severity="warning">Dependencies need to be rebuilt</Alert>;
  }

  if (!lesson.dependencies_ready) {
    return <Alert severity="info">Dependency build in progress</Alert>;
  }

  if (lesson.dependency_build_error) {
    return (
      <Alert severity="error">
        <AlertTitle>Last dependency build failed</AlertTitle>
        {lesson.dependency_build_error}
      </Alert>
    );
  }

  return <Alert severity="success">Dependencies are built</Alert>;
}
