import React, { useState } from "react";
import { MODIFIED_STATUS } from "./constants";
import { Box, Typography, Button, LinearProgress, Alert } from "@mui/material";
import { LabelledOutline } from "../general/LabelledOutline";
import { RateReview as RateReviewIcon } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { lessonDataActions } from "../store/lessonDataSlice";

export default function LessonReview({ lesson }) {
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);

  const startReview = async () => {
    setError("");
    setSaving(true);
    try {
      const response = await fetch(`/api/lesson/${lesson.id}/start-review`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
      const responseData = await response.json();
      if (responseData.error) {
        setError(responseData.error);
      } else {
        dispatch(
          lessonDataActions.updateLessonField({
            lessonId: lesson.id,
            field: "review_status",
            value: responseData.status,
            backend: true,
          })
        );
      }
    } catch (error) {
      console.log(error);
      setError("Could not start the review. Try again later.");
    }
    setSaving(false);
  };

  if (lesson.release_status !== MODIFIED_STATUS) {
    return null;
  }

  return (
    <LabelledOutline label="Review">
      {error && <Alert severity="error">{error}</Alert>}
      {lesson.review_status ? (
        <Typography variant="caption">
          Review status: {lesson.review_status}
        </Typography>
      ) : (
        <>
          <Typography variant="caption">
            Mark this lesson as ready for review.
          </Typography>
          {saving && <LinearProgress color="primary" />}
          <Button
            variant="outlined"
            color="primary"
            disabled={saving || lesson.is_shallow_import}
            startIcon={<RateReviewIcon />}
            onClick={startReview}
            fullWidth
            size="small"
          >
            Ready for Review
          </Button>
        </>
      )}
    </LabelledOutline>
  );
}
