import React, { useState } from "react";

import {
  Alert,
  IconButton,
  DialogTitle,
  FormControl,
  DialogContent,
  Dialog,
  Button,
  CircularProgress,
  Box,
  TextField,
  DialogActions,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { importLessonsIntoRelease } from "./api";

const styles = {
  closeButton: {
    position: "absolute",
    top: "0",
    right: "0",
    "&:hover": {
      cursor: "pointer",
      color: "#F50057",
    },
  },
};

function LessonListInputModal(props) {
  const [lessonList, setLessonList] = useState("");
  const [validationError, setValidationError] = useState();
  const [loading, setLoading] = useState(false);
  const [importErrors, setImportErrors] = useState();

  const handleClose = (needsUpdate) => {
    props.closeModal(needsUpdate);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const match = value.match(new RegExp("[0-9 ,]+"));
    if (value === "" || (match && match[0] === value)) {
      setLessonList(event.target.value);
    }
  };

  const importLessons = () => {
    const lessons = lessonList
      .split(new RegExp("[, ][, ]*"))
      .filter((lesson) => lesson.length > 0);
    const release = {
      name: props.release.name,
      version_major: props.release.version_major,
      version_minor: props.release.version_minor,
    };
    setImportErrors();
    setValidationError();
    setLoading(true);
    importLessonsIntoRelease(
      lessons,
      release,
      (response) => {
        if (response.error) {
          setValidationError(response.error);
          setLoading(false);
        } else {
          const invalidLessons = response.invalidLessons;
          const lessonSequences = Object.keys(invalidLessons);
          setLoading(false);
          if (lessonSequences.length > 0) {
            setValidationError(`
              ${lessonSequences.length === 1 ? "Lesson" : "Lessons"}
              ${lessonSequences.join(", ")}
              could not be imported.`);
            setImportErrors(invalidLessons);
          } else {
            handleClose(true);
          }
        }
      },
      (error) => {
        setLoading(false);
        console.log(error);
        setValidationError("An error occured while importing the lessons.");
      }
    );
  };

  const renderImportErrors = () => {
    if (!importErrors) return null;
    return (
      <>
        {Object.keys(importErrors).map((sequence) => (
          <Box p={1} key={sequence}>
            <Alert severity="error">
              {sequence}: {importErrors[sequence]}
            </Alert>
          </Box>
        ))}
      </>
    );
  };

  return (
    <Dialog
      onClose={() => handleClose(false)}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      maxWidth={"sm"}
      fullWidth
    >
      <IconButton sx={styles.closeButton} onClick={() => handleClose(false)}>
        <CloseIcon />
      </IconButton>
      <DialogTitle id="customized-dialog-title">Import Lessons</DialogTitle>
      {validationError && (
        <Box p={1}>
          <Alert severity="error">{validationError}</Alert>
        </Box>
      )}
      {renderImportErrors()}
      <DialogContent dividers>
        <FormControl fullWidth sx={styles.margin} variant="filled">
          <TextField
            fullWidth
            variant="outlined"
            value={lessonList}
            onChange={handleChange}
            label="List of lessons"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={importLessons}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Import"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LessonListInputModal;
