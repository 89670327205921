import React from "react";
import PropTypes from "prop-types";
import { Stack, CircularProgress, Typography } from "@mui/material";

function Loader(props) {
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <CircularProgress />
      <Typography>{props.loadMessage}</Typography>
    </Stack>
  );
}

Loader.propTypes = {
  loadMessage: PropTypes.string,
};

Loader.defaultProps = {
  loadMessage: "Loading...",
};

export default Loader;
