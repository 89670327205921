import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const WysiwygEditor = ({ initValue, handleOnChange, ...others }) => {
  const [wEState, setWEState] = useState(EditorState.createEmpty());

  useEffect(() => {
    convertToDraft(initValue);
  }, []);

  const convertToDraft = (html) => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      setWEState(editorState);
    }
  };

  const handleOnEditorStateChange = (editorState) => {
    setWEState(editorState);
    handleOnChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <Editor
      editorState={wEState}
      onEditorStateChange={handleOnEditorStateChange}
      toolbar={{
        options: [
          "inline",
          "blockType",
          "list",
          "textAlign",
          "link",
          "embedded",
          "emoji",
          "image",
          "remove",
          "history",
        ],
      }}
      {...others}
    />
  );
};

export default WysiwygEditor;
