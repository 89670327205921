import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "@mui/material";

class GoogleAuthButton extends Component {
  state = {
    auth_open: false,
  };

  openAuthWindow = () => {
    this.setState({ auth_open: true });
    this._popup = window.open(
      "/api/login",
      "google",
      "width=500px,height=500px"
    );

    this.setupPolling();
    if (this._popup && this._popup.focus) {
      this._popup.focus();
    }
  };

  setupPolling() {
    this._polling_interval = window.setInterval(this.poll, 100);
  }

  poll = () => {
    if (!this.state.auth_open) {
      window.clearInterval(this._polling_interval);
      return;
    }

    try {
      const current_url = this._popup.location.pathname;

      if (current_url === "/api/login/success") {
        window.clearInterval(this._polling_interval);
        this.auth_success();
        return;
      }
    } catch (error) {}
    if (!this._popup) {
      window.clearInterval(this._polling_interval);
      this.auth_failure("Provider Popup Blocked");
    } else if (this._popup.closed !== false) {
      window.clearInterval(this._polling_interval);
      this.auth_failure("Authorization Failed");
    }
  };

  auth_success = () => {
    this.close_auth();
    this.props.onSuccess();
  };

  auth_failure = (error) => {
    this.close_auth();
    this.props.onFailure(error);
  };

  close_auth = () => {
    this.setState({ auth_open: false });
    if (this._popup && this._popup.close) {
      this._popup.close();
    }
    this._popup = undefined;
  };

  render() {
    return <Button onClick={this.openAuthWindow}>Log in</Button>;
  }
}

export default withRouter(GoogleAuthButton);
