import React from "react";
import CodeTabs from "../code-tabs/CodeTabs";
import {
  SETUP_CODE_TAB,
  ANSWER_CODE_TAB,
  VALUE_CHECK_TAB,
  CLI_TEST_TAB,
  CUSTOM_CHECK_TAB,
} from "../code-tabs/constants";

export default function CliEditor({ lesson, screen }) {
  const DEFAULT_TAB = ANSWER_CODE_TAB;

  let tabs = [SETUP_CODE_TAB, ANSWER_CODE_TAB, VALUE_CHECK_TAB];
  if (lesson.custom_check_enabled) {
    tabs.push(CUSTOM_CHECK_TAB);
  }
  tabs.push(CLI_TEST_TAB);

  return (
    <CodeTabs
      lesson={lesson}
      screen={screen}
      tabs={tabs}
      defaultTab={DEFAULT_TAB}
    />
  );
}
