import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Chip, Stack, Skeleton, Alert } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function CourseTracks(props) {
  const course = props.course;
  const history = useHistory();

  const [courseTracks, setCourseTracks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const getCourseTracks = async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await fetch(`/api/course/${course.id}/tracks`);
      const courseTracks = await response.json();
      if (courseTracks.error) {
        setError(courseTracks.error);
      } else {
        setCourseTracks(courseTracks);
      }
    } catch (error) {
      setError(
        "An error occurred while loading the course paths. Try again later."
      );
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCourseTracks();
  }, []);

  const renderTracks = () => {
    if (isLoading) {
      return (
        <Stack direction="row" spacing={2}>
          <Skeleton variant="text" width={100} height={42} />
          <Skeleton variant="text" width={100} height={42} />
          <Skeleton variant="text" width={100} height={42} />
        </Stack>
      );
    }
    if (error) {
      return <Alert severity="error">{error}</Alert>;
    }
    if (courseTracks.length === 0) {
      return <Typography>This course does not belong to any paths</Typography>;
    }
    return courseTracks.map((track) => (
      <Box key={track.id} m={1}>
        <Chip
          label={track.name}
          onClick={() => history.push(`/track/${track.id}`)}
        />
      </Box>
    ));
  };

  return (
    <Box>
      <Typography variant="h6">Paths where course is included</Typography>
      {renderTracks()}
    </Box>
  );
}

CourseTracks.propTypes = {
  course: PropTypes.object,
};

CourseTracks.defaultProps = {};
