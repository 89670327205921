import React, { useState } from "react";
import {
  LinearProgress,
  Button,
  Grid,
  Alert,
  Tooltip,
  Box,
} from "@mui/material";
import { LabelledOutline } from "../general/LabelledOutline";
import DeployNotification from "./DeployNotification";
import BuildIcon from "@mui/icons-material/Build";
import { useStateCallback } from "../utils/hooks";
import ConfirmModal from "../general/ConfirmModal";

export default function LessonDeploy({ lesson }) {
  const [error, setError] = useState("");
  const [newLessonDeploy, setNewLessonDeploy] = useStateCallback(null);
  const [deploying, setDeploying] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const getLatestDeploy = async () => {
    const response = await fetch(`/api/lesson/${lesson.id}/deploy`);
    const deploys = await response.json();
    if (deploys.length > 0) return deploys[0];
    return null;
  };

  const deployLesson = async (force = false) => {
    setError("");
    setModalIsOpen(false);
    try {
      const latestDeploy = await getLatestDeploy();
      if (!force && latestDeploy && latestDeploy.status === "running") {
        setModalIsOpen(true);
        return;
      }
      setDeploying(true);
      const response = await fetch(`/api/lesson/${lesson.id}/deploy`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ force }),
      });
      const responseData = await response.json();
      if (responseData.error) {
        setError(responseData.error);
        setDeploying(false);
      } else {
        setNewLessonDeploy(responseData, () => setDeploying(false));
      }
    } catch (error) {
      console.log(error);
      setError("An error occured when trying to deploy the lesson");
      setDeploying(false);
    }
  };

  return (
    <LabelledOutline label="Deploy">
      <ConfirmModal
        isOpen={modalIsOpen}
        cancel={() => setModalIsOpen(false)}
        confirm={() => deployLesson(true)}
        message={`This lesson already has an ongoing deploy. Are you sure that you want to start a new one?`}
      />
      {deploying && <LinearProgress />}
      <Box my={1}>
        <DeployNotification
          newLessonDeploy={newLessonDeploy}
          clearNewLessonDeploy={() =>
            setNewLessonDeploy({ newLessonDeploy: null })
          }
          startingDeploy={deploying}
        />
      </Box>
      {error && (
        <Alert severity="error" sx={{ my: 1 }}>
          {error}
        </Alert>
      )}
      <Box>
        <Tooltip title="Deploy to the preview environment">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<BuildIcon />}
            disabled={deploying || lesson.is_shallow_import}
            onClick={() => deployLesson(false)}
            size="small"
          >
            Deploy
          </Button>
        </Tooltip>
      </Box>
    </LabelledOutline>
  );
}
