import React from "react";
import { Box, Paper, Typography, Grid, IconButton } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { answerCheckerActions } from "../store/answerCheckerSlice";
import ClearIcon from "@mui/icons-material/Clear";
import { Controlled as CodeMirror } from "react-codemirror2";
import { getCheckerCode } from "../utils/pycheck";

function CheckerCard(props) {
  const dispatch = useDispatch();

  const deleteHandler = () => {
    dispatch(answerCheckerActions.deleteCheckerAtIndex(props.index));
  };

  return (
    <Paper elevation={2}>
      <Box my={1} p={1}>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <Typography variant="subtitle2">{props.index + 1}</Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle2">
              {props.checker.metadata.name}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={deleteHandler}>
              <ClearIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <CodeMirror
              value={getCheckerCode(
                props.checker.metadata.name,
                props.checker.arguments
              )}
              options={{
                mode: "python",
                theme: "monokai",
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

function CheckerViewer(props) {
  const answerChecker = useSelector((state) => state.answerChecker);

  return (
    <Box>
      {answerChecker.builtCheckers.map((checker, index) => (
        <CheckerCard key={`checker-${index}`} checker={checker} index={index} />
      ))}
    </Box>
  );
}

export default CheckerViewer;
