import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import MarkdownEditor from "../MarkdownEditor";
import {
  processCleanupHTML,
  processLinks,
  processWithMathJax,
} from "../markup-processors";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { lessonDataActions } from "../store/lessonDataSlice";

export default function TextSection({
  title,
  lesson,
  screen,
  section,
  saveScreen,
  textOnly = false,
}) {
  /*
  If the section is `instructions` then the html field and
  markdown field are `instructions_html` and `instructions_markdown`
  */
  const HTML_FIELD_NAME = textOnly ? section : `${section}_html`;
  const MARKDONW_FIELD_NAME = textOnly ? section : `${section}_markdown`;

  const dispatch = useDispatch();

  const [editing, setEditing] = useState(false);

  useEffect(() => {
    // Reset editing state when we change screens
    setEditing(false);
  }, [screen.id]);

  const save = () => {
    setEditing(false);
    saveScreen();
  };

  const handleChange = (newMarkdownValue) => {
    dispatch(
      lessonDataActions.updateScreenField({
        screenId: screen.id,
        field: MARKDONW_FIELD_NAME,
        value: newMarkdownValue,
      })
    );
  };

  const getHtml = () => {
    /*
    Convert markdown into html so we can display the section content
    when we're not editing it.
    */
    let html = screen[HTML_FIELD_NAME];
    if (!html) return;
    html = processCleanupHTML(html);
    html = processLinks(html);
    html = processWithMathJax(html);
    return html;
  };

  const renderEditorOrPreview = () => {
    if (editing) {
      return (
        <MarkdownEditor
          value={screen[MARKDONW_FIELD_NAME]}
          section={MARKDONW_FIELD_NAME}
          handleChange={handleChange}
          save={save}
          textOnly={textOnly}
          lesson_id={lesson.id}
        />
      );
    }
    return <div dangerouslySetInnerHTML={{ __html: getHtml() }} />;
  };

  return (
    <Box>
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <Typography variant="h6" display="inline">
            {title}
          </Typography>
        </Grid>
        {!editing && (
          <Grid item>
            <IconButton edge="end" onClick={() => setEditing(true)}>
              <EditIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      {renderEditorOrPreview()}
    </Box>
  );
}

TextSection.defaultProps = {
  textOnly: false,
};
