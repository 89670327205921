import React from "react";
import { Button, InputLabel } from "@mui/material";
import DqLoading from "@dataquestio/dq-components/components/common/feedback/DqLoading";
import CodeMirrorTextArea from "../code/CodeMirrorTextArea";

const DataFileEditor = ({ lessonId, fileName, language }) => {
  const [fileContents, setFileContents] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const fetchFileContents = React.useCallback(() => {
    fetch(`/api/lesson/${lessonId}/datafile/${fileName}/contents`)
      .then((response) => response.json())
      .then((contents) => {
        setFileContents(contents);
        setLoading(false);
      });
  }, [lessonId, fileName]);

  const saveFileContents = () => {
    setLoading(true);
    const requestBody = { contents: fileContents };
    return fetch(`/api/lesson/${lessonId}/datafile/${fileName}/contents`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    }).then(() => {
      setLoading(false);
    });
  };

  React.useEffect(() => {
    if (lessonId && fileName) fetchFileContents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonId, fileName]);

  if (loading) return <DqLoading />;

  return (
    <>
      <InputLabel shrink id={`datafile-editor-label-${fileName}`}>
        {fileName}
      </InputLabel>
      <CodeMirrorTextArea
        id={`codemirror-editor-datafile-${fileName}`}
        value={fileContents}
        setValue={setFileContents}
        language={language}
        showOutput={false}
      />
      <Button
        variant="contained"
        onClick={saveFileContents}
        disabled={loading}
        sx={{
          borderRadius: 0,
        }}
      >
        Save
      </Button>
    </>
  );
};

export default DataFileEditor;
