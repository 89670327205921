import React, { useState, useContext } from "react";
import {
  Box,
  Select,
  MenuItem,
  Stack,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Typography,
  Alert,
  LinearProgress,
} from "@mui/material";
import { UserInfoContext } from "../provider/UserInfoProvider";

export default function Debug() {
  const { userInfo } = useContext(UserInfoContext);

  const [url, setUrl] = useState("");
  const [method, setMethod] = useState("GET");
  const [body, setBody] = useState("{\n\n\n\n}");

  const [result, setResult] = useState();
  const [error, setError] = useState();
  const [requesting, setRequesting] = useState(false);

  if (!userInfo || userInfo.external) return null;

  const handleSend = async () => {
    setResult();
    setError("");
    setRequesting(true);
    let bodyJSON = null;
    try {
      bodyJSON = JSON.parse(body);
    } catch (error) {
      console.log(error);
      setError("Invalid body");
      setRequesting(false);
      return;
    }

    const payload = {
      method: method,
    };
    if (method !== "GET") {
      payload.headers = {
        "Content-Type": "application/json",
      };
      payload.body = JSON.stringify(bodyJSON);
    }

    let response = await fetch(`/api/${url}`, payload);
    console.log(response);
    let data = null;
    try {
      data = await response.json();
    } catch (exception) {
      data = {};
    }

    setRequesting(false);
    setResult({
      status: response.status,
      statusText: response.statusText,
      data: data,
    });
  };

  const renderResponse = () => {
    if (!result) return null;

    return (
      <Box>
        <Typography>
          Status: {result.status} ({result.statusText})
        </Typography>
        <Typography>{JSON.stringify(result.data)}</Typography>
      </Box>
    );
  };

  return (
    <Box m={6}>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="flex-end" spacing={0.2}>
          <Typography
            variant="subscript"
            sx={{
              mb: 0.9,
            }}
          >
            /api/
          </Typography>
          <TextField
            label="url"
            variant="standard"
            fullWidth
            value={url}
            onChange={(event) => setUrl(event.target.value)}
          />
        </Stack>

        <FormControl variant="standard" fullWidth>
          <InputLabel id="demo-simple-select-standard-label">Method</InputLabel>
          <Select
            value={method}
            onChange={(event) => setMethod(event.target.value)}
            label="Method"
          >
            <MenuItem value={"GET"}>GET</MenuItem>
            <MenuItem value={"POST"}>POST</MenuItem>
            <MenuItem value={"DELETE"}>DELETE</MenuItem>
            <MenuItem value={"PATCH"}>PATCH</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Payload"
          fullWidth
          multiline
          onChange={(event) => setBody(event.target.value)}
          value={body}
          InputLabelProps={{ shrink: true }}
          minRows={3}
          disabled={method === "GET"}
        />
        <Button onClick={handleSend}>Send</Button>
        {error && <Alert severity="error">{error}</Alert>}
        {requesting && <LinearProgress />}
        {renderResponse()}
      </Stack>
    </Box>
  );
}
