import { createSlice } from "@reduxjs/toolkit";

const ALL_GROUP = "All"; // Represents the group of all checkers

const initialState = {
  checkers: [], // List of all checkers
  checkerGroupNames: [], // List with all checker group names
  selectedGroupName: ALL_GROUP, // Name of the selected group
  checkersInSelectedGroup: [], // List of checkers in selected group
  builtCheckers: [], // List of built checkers to add to the answer checker
  currentChecker: {
    // Data on the checker being built
    metadata: null,
    arguments: {},
  },
  viewOptions: {
    // Checker view options
    showDoc: true,
    showOptional: false,
  },
};

const answerCheckerSlice = createSlice({
  name: "answerCheckerSlice",
  initialState: initialState,
  reducers: {
    reset() {
      return initialState;
    },
    initializeCheckerMetadataAndGroups(state, action) {
      const checkers = action.payload;
      state.checkers = checkers;
      state.checkerGroupNames = [
        ALL_GROUP,
        ...new Set(checkers.map((checker) => checker.group)),
      ];
      state.checkersInSelectedGroup = checkers;
    },
    resetSelectGroup(state) {
      state.selectedGroupName = ALL_GROUP;
      state.currentChecker = {
        metadata: null,
        arguments: {},
      };
      state.checkersInSelectedGroup = state.checkers;
    },
    setCurrentCheckerGroup(state, action) {
      const group = action.payload;
      state.selectedGroupName = group;
      const checkersInGroup = state.checkers.filter(
        (checker) => group === ALL_GROUP || checker.group === group
      );
      state.checkersInSelectedGroup = checkersInGroup;
      state.currentChecker.metadata = checkersInGroup[0];
      state.currentChecker.arguments = {};
    },
    addChecker(state, action) {
      state.builtCheckers = [...state.builtCheckers, action.payload];
    },
    deleteCheckerAtIndex(state, action) {
      state.builtCheckers = state.builtCheckers.filter(
        (checker, index) => index !== action.payload
      );
    },
    selectChecker(state, action) {
      state.currentChecker.metadata = action.payload;
      state.currentChecker.arguments = {};
    },
    updateCheckerArgument(state, action) {
      state.currentChecker.arguments = {
        ...state.currentChecker.arguments,
        ...action.payload,
      };
    },
    toggleShowDoc(state) {
      state.viewOptions.showDoc = !state.viewOptions.showDoc;
    },
    toggleShowOptional(state) {
      state.viewOptions.showOptional = !state.viewOptions.showOptional;
    },
  },
});

export const answerCheckerActions = answerCheckerSlice.actions;

export default answerCheckerSlice.reducer;
