/**
 * Runs answer checking in backend
 * @param {*} lessonId
 * @param {*} screenIndex
 * @param {*} terminalLines
 * @returns JSON object with shape
 * {
 *   custom: boolean,
 *   value: boolean,
 * }
 */
export const checkAnswer = (screenId, terminalLines) => {
  return fetch("/api/cli/check_answer", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      screen_id: screenId,
      terminal_lines: terminalLines,
    }),
  }).then((response) => response.json());
};
