import React, { useState, useEffect } from "react";

import {
  Alert,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Button,
  CircularProgress,
  Box,
  IconButton,
  TextField,
  DialogContentText,
  Grid,
  makeStyles,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const styles = {
  closeButton: {
    position: "absolute",
    top: "0",
    right: "0",
    "&:hover": {
      cursor: "pointer",
      color: "#F50057",
    },
  },
};

function LessonOptionsModal(props) {
  const [error, setError] = useState("");
  const [loadingReleases, setLoadingReleases] = useState(false);
  const [settingRelease, setSettingRelease] = useState(false);
  const [release, setRelease] = useState(props.lesson.release);
  const [releaseList, setReleaseList] = useState([]);
  const [initialRelease, setInitialRelease] = useState();

  const fecthReleases = () => {
    setError("");
    setLoadingReleases(true);
    fetch("/api/releases")
      .then((data) => data.json())
      .then((releases) => {
        const currentRelease = releases.filter(
          (release) => release.id === props.lesson.release_id
        )[0];
        setRelease(currentRelease);
        setInitialRelease(currentRelease);

        releases = releases
          .filter((release) => !release.is_main)
          .map((release) => ({ id: release.id, name: release.name }));

        setReleaseList(releases);
        setLoadingReleases(false);
      })
      .catch((error) => {
        console.log(error);
        setError("An error occured while loading the mission releases.");
        setLoadingReleases(false);
      });
  };

  const setLessonRelease = () => {
    if (!release) {
      setError("You must select one of the releases.");
      return;
    }
    setError("");
    if (release.id === initialRelease.id) {
      props.closeModal();
    }
    setSettingRelease(true);
    if (!release.id) {
      return;
    }
    fetch(`/api/release/${release.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: release.name,
        lessons_list: [props.lesson.sequence],
        from_release: initialRelease.id,
      }),
    })
      .then(() => {
        setSettingRelease(false);
        props.closeModalAndReload();
      })
      .catch((error) => {
        console.log(error);
        setSettingRelease(false);
        setError("An error occured while moving the lesson lessons.");
      });
  };

  const renderDialogueContent = () => {
    if (loadingReleases) {
      return (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      );
    }
    if (releaseList && initialRelease) {
      return (
        <>
          <DialogContentText>
            Selected lesson: <b>{props.lesson.sequence}</b>
          </DialogContentText>
          <DialogContentText>
            Release name: <b>{initialRelease.name}</b>
          </DialogContentText>
          <FormControl fullWidth sx={styles.margin} variant="filled">
            <Autocomplete
              id="release-combo-box"
              value={release}
              options={releaseList}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Release" variant="outlined" />
              )}
              onChange={(e, value) => {
                setRelease(value);
              }}
            />
          </FormControl>
        </>
      );
    }
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        No releases
      </Grid>
    );
  };

  useEffect(() => {
    fecthReleases();
  }, []);

  return (
    <Dialog onClose={props.closeModal} open={true} maxWidth={"sm"} fullWidth>
      <IconButton sx={styles.closeButton} onClick={props.closeModal}>
        <CloseIcon />
      </IconButton>
      <DialogTitle id="select-relase-title" onClose={props.closeModal}>
        Options
      </DialogTitle>
      {error && (
        <Box p={1}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <DialogContent dividers>{renderDialogueContent()}</DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={setLessonRelease}
          disabled={settingRelease || loadingReleases}
        >
          {settingRelease ? (
            <CircularProgress size={24} />
          ) : (
            "Copy Lesson To Selected Release"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LessonOptionsModal;
