import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  List,
  ListItem,
  IconButton,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  Tooltip,
  LinearProgress,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import LabelledOutline from "../general/LabelledOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";

function DatasetUploadModal(props) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const toggleIsDirectory = (fileIndex) => {
    setFiles(
      files.map((file, index) => {
        if (index === fileIndex) file.isDirectory = !file.isDirectory;
        return file;
      })
    );
  };

  const handleClose = () => {
    props.close();
  };

  const handleAddFile = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setFiles([
      ...files,
      {
        file,
        isDirectory: false,
      },
    ]);
  };

  const handleUpload = async () => {
    if (!canUpload()) return;
    setError("");
    setIsUploading(true);
    const formData = buildFormData();
    try {
      const url = props.datasetId
        ? `/api/dataset/${props.datasetId}`
        : `/api/dataset`;
      const method = props.datasetId ? "PATCH" : "POST";
      const response = await fetch(url, {
        method,
        body: formData,
      });
      const responseData = await response.json();
      if (responseData.error) {
        setError(responseData.error);
      } else {
        props.successCallback();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      setError("An internal error occurred.");
    }
    setIsUploading(false);
  };

  const buildFormData = () => {
    const formData = new FormData();
    // Build the request form
    formData.append("name", name);
    formData.append("description", description);
    formData.append("fileCount", files.length);
    formData.append("filesInfo", JSON.stringify(getFilesInfo()));
    // Build the request files
    for (let i = 0; i < files.length; i++) {
      formData.append(`file${i}`, files[i].file);
    }
    return formData;
  };

  const canUpload = () => {
    return (
      !isUploading &&
      (props.datasetId || !!name.trim()) &&
      !!description.trim() &&
      files.length > 0
    );
  };

  const deleteFile = (fileIndex) => {
    setFiles(files.filter((file, index) => index !== fileIndex));
  };

  const changeFileType = (fileIndex) => {
    setFiles(
      files.map((file, index) => {
        if (index === fileIndex) file.isDirectory = !file.isDirectory;
        return file;
      })
    );
  };

  const renderFiles = () =>
    files.map((file, index) => (
      <List key={`file-${index}`}>
        <ListItem>
          <ListItemText
            primary={
              <Box sx={{ display: "flex", alignItems: "center", padding: 0 }}>
                <IconButton
                  onClick={() => changeFileType(index)}
                  sx={{ padding: 0 }}
                  disabled={isUploading}
                >
                  {file.isDirectory ? <FolderIcon /> : <InsertDriveFileIcon />}
                </IconButton>
                {file.file?.name}
              </Box>
            }
          />
          <ListItemSecondaryAction>
            <FormControlLabel
              labelPlacement="end"
              control={<Checkbox size="small" checked={file.isDirectory} />}
              onChange={() => toggleIsDirectory(index)}
              label={<Typography variant="caption">Is a directory</Typography>}
              disabled={isUploading}
            />
            <IconButton
              edge="end"
              onClick={() => deleteFile(index)}
              disabled={isUploading}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    ));

  const getFilesInfo = () =>
    files.map((fileData) => ({
      name: fileData.file.name,
      is_directory: fileData.isDirectory,
      chunkCount: fileData.chunkCount,
    }));

  useEffect(() => {
    if (props.isOpen) {
      setName("");
      setDescription("");
      setFiles([]);
      setError("");
      setIsUploading(false);
    }
  }, [props.isOpen]);

  return (
    <Dialog open={props.isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {props.datasetId ? "Upload new version" : "Upload new dataset"}
      </DialogTitle>
      <DialogContent>
        {!props.datasetId && (
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            name="name"
            label="Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            disabled={isUploading}
          />
        )}
        <TextField
          fullWidth
          multiline
          minRows={3}
          margin="normal"
          variant="outlined"
          name="description"
          label="Description"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          disabled={isUploading}
        />
        <LabelledOutline label="Files">
          <Box p={1}>
            {renderFiles()}
            <Tooltip title="Add files related to the dataset">
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                component="label"
                disabled={isUploading}
              >
                Add file
                <input type="file" hidden onChange={handleAddFile} />
              </Button>
            </Tooltip>
          </Box>
        </LabelledOutline>
        {isUploading && <LinearProgress />}
      </DialogContent>
      <DialogActions>
        <Grid container direction="column" spacing={1}>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
          <Box my={1}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-around"
            >
              <Grid item xs={5}>
                <Button fullWidth variant="contained" onClick={props.close}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  fullWidth
                  disabled={!canUpload()}
                  color="primary"
                  variant="contained"
                  onClick={handleUpload}
                >
                  Upload
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

DatasetUploadModal.propTypes = {
  datasetId: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  successCallback: PropTypes.func,
};

DatasetUploadModal.defaultProps = {
  datasetId: null,
  isOpen: false,
  successCallback: (dataset) => {},
};

export default DatasetUploadModal;
