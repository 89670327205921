import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Stack, Skeleton } from "@mui/material";
import { fetchRelease } from "../api/releases";

export default function CourseRelease(props) {
  const [release, setRelease] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getCourseRelease = () => {
    setIsLoading(true);
    fetchRelease(
      props.course.release_id,
      (release) => {
        setIsLoading(false);
        setRelease(release);
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getCourseRelease();
  }, []);

  const renderRelease = () => {
    if (isLoading) {
      return <Skeleton variant="text" width={200} display="inline-block" />;
    }
    return (
      <Typography display="inline-block">{release.version_major}</Typography>
    );
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography variant="h6" display="inline-block">
        Release:
      </Typography>
      {renderRelease()}
    </Stack>
  );
}

CourseRelease.propTypes = {
  course: PropTypes.object,
};

CourseRelease.defaultProps = {};
