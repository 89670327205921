import React from "react";
import { Select, MenuItem } from "@mui/material";
import { LEVELS } from "./constants";

function SkillLevelSelect(props) {
  return (
    <Select {...props}>
      {LEVELS.map((level, i) => (
        <MenuItem key={i} value={i}>
          {level}
        </MenuItem>
      ))}
    </Select>
  );
}

export default SkillLevelSelect;
