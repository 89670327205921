import React, { useState } from "react";

import Modal from "../general/Modal";

import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  Box,
} from "@mui/material";

const VideoFormModal = (props) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [startTimestamp, setStartTimestamp] = useState("");
  const [endTimestamp, setEndTimestamp] = useState("");
  const [isYoutube, setIsYoutube] = useState(false);
  const [validationError, setValidationError] = useState("");

  const getDelimiter = (url) => (url.indexOf("?") === -1 ? "?" : "&");

  const addVideo = () => {
    setValidationError("");
    let url = videoUrl;
    if (
      !url.startsWith("https://") &&
      !(isYoutube && url.match(/^[0-9a-zA-Z]+$/))
    ) {
      setValidationError(
        "Incorrect url provided. Url should be specified with protocol, video ID should follow alphanumeric format."
      );
      return;
    }
    if (
      (startTimestamp &&
        (startTimestamp.indexOf(":") !== -1 ||
          !startTimestamp.match(/^[0-9]+$/))) ||
      (endTimestamp &&
        (endTimestamp.indexOf(":") !== -1 || !endTimestamp.match(/^[0-9]+$/)))
    ) {
      setValidationError(
        "Incorrect time format, start and end time should be specified in seconds"
      );
      return;
    }
    if (isYoutube) {
      if (!url.startsWith("https://www.youtube.com/embed/")) {
        const match = url.match(/(\/|v=)[0-9a-zA-Z]+$/);
        if (match && match.length) url = match[0].replace(/(\/|v=)/g, "");
        url = `https://www.youtube.com/embed/${url}`;
      }
      if (startTimestamp) {
        url = `${url}${getDelimiter(url)}start=${startTimestamp}`;
      }
      if (endTimestamp) {
        url = `${url}${getDelimiter(url)}end=${endTimestamp}`;
      }
    }
    props.addVideo(url, isYoutube);
    props.closeHandler();
    setVideoUrl("");
    setStartTimestamp("");
    setEndTimestamp("");
    setIsYoutube(false);
    setValidationError("");
  };

  const handleVideoUrl = (evt) => {
    const url = evt.target.value;
    if (
      url.indexOf("https://www.youtube.com") !== -1 ||
      url.indexOf("https://youtu.be") !== -1
    )
      setIsYoutube(true);
    setVideoUrl(url);
  };

  const renderForm = () => {
    return (
      <FormControl fullWidth>
        {validationError && (
          <Box>
            <Alert severity="error">{validationError}</Alert>
          </Box>
        )}
        <FormControlLabel
          control={
            <Switch
              checked={isYoutube}
              onChange={(event) => setIsYoutube(event.target.checked)}
            />
          }
          label="Is Youtube?"
          labelPlacement="end"
        />
        <TextField
          margin="normal"
          variant="outlined"
          name="url"
          label="Url or video ID"
          value={videoUrl}
          onChange={handleVideoUrl}
        />
        <TextField
          margin="normal"
          variant="outlined"
          name="start"
          label="Start (optional, seconds)"
          disabled={!isYoutube}
          value={startTimestamp}
          onChange={(event) => setStartTimestamp(event.target.value)}
        />
        <TextField
          margin="normal"
          variant="outlined"
          name="end"
          label="End (optional, seconds)"
          disabled={!isYoutube}
          value={endTimestamp}
          onChange={(event) => setEndTimestamp(event.target.value)}
        />
        <Button variant="contained" color="primary" onClick={addVideo}>
          Add video
        </Button>
      </FormControl>
    );
  };

  return (
    <Modal
      isOpen={props.isOpen}
      closeHandler={props.closeHandler}
      title="Add Video"
      content={renderForm()}
      muiDialog={{ maxWidth: "xs" }}
    />
  );
};

export default VideoFormModal;
