import React, { useState, useMemo, useEffect } from "react";
import CodeMirrorTextArea from "../../code/CodeMirrorTextArea";
import { useDispatch } from "react-redux";
import { lessonDataActions } from "../../store/lessonDataSlice";
import { Button } from "@mui/material";
import PycheckEditorModal from "../../pycheck-components/PycheckEditorModal";
import AnswerPreview from "./AnswerPreview";

export default function CustomCheckCodeTab({ lesson, screen }) {
  const dispatch = useDispatch();

  const [pycheckEditorIsOpen, setPycheckEditorIsOpen] = useState(false);
  const [needsRerender, setNeedsRerender] = useState(false);

  useEffect(() => {
    if (needsRerender) {
      setNeedsRerender(false);
    }
  }, [needsRerender]);

  const buildCheckerCallback = (customCheckCode) => {
    setNeedsRerender(true);
    dispatch(
      lessonDataActions.updateScreenField({
        screenId: screen.id,
        field: "custom_check_code",
        value: customCheckCode,
      })
    );
  };

  const renderPycheckEditorButton = () => {
    if (lesson.language === "cli") return null;
    return (
      <Button
        variant="contained"
        onClick={() => setPycheckEditorIsOpen(true)}
        sx={{
          borderRadius: 0,
        }}
      >
        Open Pycheck Editor
      </Button>
    );
  };

  const codeEditor = useMemo(
    () => (
      <CodeMirrorTextArea
        id={`codemirror-editor-custom-check-code`}
        value={screen.custom_check_code || ""}
        language={lesson.language}
        setValue={(newCode) =>
          dispatch(
            lessonDataActions.updateScreenField({
              screenId: screen.id,
              field: "custom_check_code",
              value: newCode,
            })
          )
        }
      >
        {renderPycheckEditorButton()}
        <AnswerPreview screen={screen} />
      </CodeMirrorTextArea>
    ),
    [lesson.language, screen.id, needsRerender]
  );

  return (
    <>
      <PycheckEditorModal
        isOpen={pycheckEditorIsOpen}
        closeHandler={() => setPycheckEditorIsOpen(false)}
        language={lesson.language}
        buildCheckerCallback={buildCheckerCallback}
      />
      {codeEditor}
    </>
  );
}
