import React, { useMemo } from "react";
import CodeMirrorTextArea from "../../code/CodeMirrorTextArea";
import { useDispatch } from "react-redux";
import { codeEditorActions } from "../../store/codeEditorSlice";
import AnswerPreview from "./AnswerPreview";
import withCodeEditor from "../../HOC/withCodeEditor";

function CustomTestTab({ lesson, screen, codeEditor }) {
  const customTest = codeEditor.customTest;

  const dispatch = useDispatch();

  const customTestEditor = useMemo(
    () => (
      <CodeMirrorTextArea
        id={`codemirror-editor-custom-test-code`}
        value={customTest.code || ""}
        language={lesson.language}
        setValue={(newCode) =>
          dispatch(
            codeEditorActions.updateCustomTest({
              screenId: screen.id,
              customTest: {
                code: newCode,
              },
            })
          )
        }
      >
        <AnswerPreview screen={screen} />
      </CodeMirrorTextArea>
    ),
    [lesson.language, screen.id]
  );

  return customTestEditor;
}

export default withCodeEditor(CustomTestTab);
