import React from "react";
import { Link } from "react-router-dom";

function DisableableLink(props) {
  return (
    <Link
      to={props.to}
      className={props.disabled ? "dq-pointer-events-none" : ""}
    >
      {props.children}
    </Link>
  );
}

export default DisableableLink;
