import React, { useContext, useState } from "react";
import { UserInfoContext } from "../provider/UserInfoProvider";
import ConfirmModal from "../general/ConfirmModal";
import { useDispatch } from "react-redux";
import { LabelledOutline } from "../general/LabelledOutline";
import { FormControlLabel, Checkbox, Stack, Typography } from "@mui/material";
import { lessonDataActions } from "../store/lessonDataSlice";
import {
  supportsLegacyAnswerChecking,
  supportsCustomAnswerChecking,
} from "./utils";

export default function AnswerCheckingControls({ lesson }) {
  const userInfo = useContext(UserInfoContext).userInfo;
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [disabelingPycheck, setDisabelingPycheck] = useState(false);

  const dispatch = useDispatch();

  const renderCustomCheckControl = () => {
    if (!supportsCustomAnswerChecking(lesson)) return null;

    return (
      <FormControlLabel
        labelPlacement="start"
        control={
          <Checkbox
            size="small"
            checked={lesson.custom_check_enabled}
            disabled={lesson.is_shallow_import}
          />
        }
        onChange={(event) => {
          if (event.target.checked) {
            dispatch(lessonDataActions.enableCustomAnswerChecking(lesson.id));
          } else {
            setDisabelingPycheck(true);
          }
        }}
        label={
          <Typography variant="caption">
            Enable custom answer checking
          </Typography>
        }
      />
    );
  };

  const renderLegacyCheckControl = () => {
    if (userInfo.external_user) return null;
    if (!supportsLegacyAnswerChecking(lesson)) return null;

    return (
      <FormControlLabel
        labelPlacement="start"
        control={
          <Checkbox
            size="small"
            checked={lesson.legacy_check_enabled}
            disabled={lesson.is_shallow_import}
          />
        }
        onChange={(event) => {
          if (event.target.checked) {
            setConfirmModalIsOpen(true);
          } else {
            dispatch(lessonDataActions.disableLegacyAnswerChecking(lesson.id));
          }
        }}
        label={
          <Typography variant="caption">
            Enable legacy answer checking
          </Typography>
        }
      />
    );
  };

  return (
    <LabelledOutline label="Answer Checking">
      <ConfirmModal
        isOpen={confirmModalIsOpen}
        confirm={() =>
          dispatch(lessonDataActions.enableLegacyAnswerChecking(lesson.id))
        }
        cancel={() => setConfirmModalIsOpen(false)}
        message="Are you sure you want to enable legacy custom answer checking? Only use this option if you are importing legacy content."
      />
      <ConfirmModal
        isOpen={disabelingPycheck}
        cancel={() => setDisabelingPycheck(false)}
        confirm={() =>
          dispatch(lessonDataActions.disableCustomAnswerChecking(lesson.id))
        }
        message={`Are you sure that you want to remove custom answer checking?`}
      />
      <Stack direction="row" spacing={2}>
        {renderCustomCheckControl()}
        {renderLegacyCheckControl()}
      </Stack>
    </LabelledOutline>
  );
}
