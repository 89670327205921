import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  lesson: {
    id: null,
    sequence: "",
    dependencies_ready: true,
    dependency_build_error: "",
    release_commit_status: "",
    review_status: null,
    review_error: "",
    newLessonDeploy: null,
    deployLessonError: "",
  },
};

export const fetchLesson = createAsyncThunk("api/lesson", async (lessonId) => {
  const response = await fetch(`/api/lesson/${lessonId}`);
  return response.json();
});

const lessonStatusSlice = createSlice({
  name: "lessonStatusSlice",
  initialState: initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLesson.fulfilled, (state, action) => {
      state.lesson = action.payload;
    });
  },
});

export const lessonStatusActions = lessonStatusSlice.actions;

export default lessonStatusSlice.reducer;
