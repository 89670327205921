import { handleJsonResponse } from "./generic";

export const createReleaseFromZip = (
  releaseName,
  branchName,
  file,
  successCallback,
  errorCallback
) => {
  const formData = new FormData();
  formData.append("releaseName", releaseName);
  formData.append("branchName", branchName);
  formData.append("file", file);
  return fetch("/api/releases/zip", {
    method: "POST",
    body: formData,
  }).then((response) =>
    handleJsonResponse(response, successCallback, errorCallback)
  );
};

export const fetchRelease = (releaseId, successCallback, errorCallback) => {
  fetch(`/api/release/${releaseId}`).then((response) =>
    handleJsonResponse(response, successCallback, errorCallback)
  );
};

export const fetchReleaseLessons = (
  releaseId,
  successCallback,
  errorCallback
) => {
  fetch(`/api/release/${releaseId}/lessons`).then((response) =>
    handleJsonResponse(response, successCallback, errorCallback)
  );
};
