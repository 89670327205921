import React from "react";
import PropTypes from "prop-types";
import Terminal from "@dataquestio/dq-components/components/terminal/Terminal";
import cn from "classnames";
import { getRunnerConnection } from "./api/codeRunning";
import { checkAnswer } from "./api/cli";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { RestartAlt } from "@mui/icons-material";

const initialAnswerCheckState = {
  success: false,
  value: {
    enabled: undefined,
    success: false,
  },
  custom: {
    enabled: undefined,
    success: false,
  },
};

const AuthoringTerminal = ({
  lessonId,
  screenId,
  screenSequence,
  resetTerminal,
}) => {
  const [connectionInfo, setConnectionInfo] = React.useState(null);
  const [connectionNotAvailable, setConnectionNotAvailable] =
    React.useState(false);

  const [answerCheckLoading, setAnswerCheckLoading] = React.useState(false);
  const [answerCheckData, setAnswerCheckData] = React.useState(
    initialAnswerCheckState
  );

  const runAnswerCheck = React.useCallback(
    (terminalLines, _screenIdx) => {
      setAnswerCheckLoading(true);
      checkAnswer(screenId, terminalLines)
        .then((data) => {
          setAnswerCheckData(data);
        })
        .catch((err) => {
          console.error(err);
          setAnswerCheckData({
            value: {
              enabled: true,
              success: undefined,
            },
            custom: {
              enabled: true,
              success: undefined,
            },
            success: undefined,
          });
        })
        .finally(() => setAnswerCheckLoading(false));
    },
    [screenId]
  );

  const resetAnswerChecking = React.useCallback(() => {
    setAnswerCheckData(initialAnswerCheckState);
  }, []);

  const onGetRunnerConnectionSuccess = React.useCallback(
    (info) => {
      if (info)
        setConnectionInfo({
          path: info.url,
          ip: info.ip,
          port: info.port,
          mission_id: lessonId,
        });
      else setConnectionInfo(null);
    },
    [lessonId]
  );

  const onGetRunnerConnectionError = React.useCallback((error) => {
    setConnectionNotAvailable(true);
    console.error(error);
  }, []);

  React.useEffect(() => {
    if (lessonId)
      getRunnerConnection(
        lessonId,
        onGetRunnerConnectionSuccess,
        onGetRunnerConnectionError
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonId]);

  const getCheckStatusText = (check) => {
    if (!check.enabled) return "DISABLED";
    if (check.success) return "SUCCESS";
    return "FAILED";
  };

  const getCheckBgColor = (check) => {
    if (!check.enabled) return undefined;
    if (check.success) return "primary.main";
    return "error.main";
  };

  const getCheckBoxClassNames = (check) => {
    if (!check.enabled) return "answer-check-disabled";
    return "";
  };

  return (
    <div style={{ height: "55vh" }} className="dq-w-full">
      {connectionNotAvailable ? (
        <div
          className={cn(
            "dq-bg-black dq-flex dq-h-full dq-w-full dq-items-center dq-justify-center",
            "dq-text-3xl dq-z-10 dq-bg-opacity-100"
          )}
          data-test-selector="AuthoringTerminalNoRunnerDiv"
        >
          <div className="dq-text-white">
            No runner is available for this lesson
          </div>
        </div>
      ) : (
        <div className="dq-h-2/3">
          <Terminal
            behindPaywall={false}
            currentLessonId={lessonId}
            current_step_index={screenSequence - 1}
            connection_info={connectionInfo}
            check_answer={runAnswerCheck}
            resetAnswerChecking={resetAnswerChecking}
            // Pass in success false as answer check to terminal
            // because terminal uses success state to prevent re-triggering
            // answer check. However, that's not in the spirit of authoring.
            answer_check={{ success: false }}
          />
        </div>
      )}
      <Box className="dq-w-full dq-bg-black">
        {answerCheckLoading ? <LinearProgress color="secondary" /> : null}
        <Box
          p={1}
          color="white"
          bgcolor="primary.main"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={resetTerminal}
            title="Reset terminal session"
            endIcon={<RestartAlt />}
          >
            Reset session
          </Button>
        </Box>
        {answerCheckData.value.enabled !== undefined && (
          <Box
            py={1}
            px={2}
            color="white"
            bgcolor={getCheckBgColor(answerCheckData.value)}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={getCheckBoxClassNames(answerCheckData.value)}
          >
            <Typography>Value Checks</Typography>
            <Typography>{getCheckStatusText(answerCheckData.value)}</Typography>
          </Box>
        )}
        {answerCheckData.custom.enabled !== undefined && (
          <Box
            py={1}
            px={2}
            color="white"
            bgcolor={getCheckBgColor(answerCheckData.custom)}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={getCheckBoxClassNames(answerCheckData.custom)}
          >
            <Typography>Custom Checks</Typography>
            <Typography>
              {getCheckStatusText(answerCheckData.custom)}
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
};

AuthoringTerminal.propTypes = {
  lessonId: PropTypes.number.isRequired,
  screenId: PropTypes.number.isRequired,
  screenSequence: PropTypes.number.isRequired,
  resetTerminal: PropTypes.func.isRequired,
};

export default AuthoringTerminal;
