import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Grid,
  Chip,
  LinearProgress,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Switch,
  FormControlLabel,
} from "@mui/material";
import TextFieldMarkdownEditor from "../general/TextFieldMarkdownEditor";
import LabelledOutline from "../general/LabelledOutline";
import Comment from "./Comment";
import {
  commentOnScreen,
  getScreenComments,
  archiveScreenComment,
  commentOnThread,
  toggleCompelted,
  toggleApproved,
} from "../api/comments";
import FilterListIcon from "@mui/icons-material/FilterList";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { UserInfoContext } from "../provider/UserInfoProvider";

function ReviewPanel(props) {
  const TAGS = [
    "Learn",
    "Instructions",
    "Hint",
    "Multiple-choice",
    "Initial",
    "Display",
    "Answer",
    "Other",
  ];

  const FILTERS = [
    {
      label: "Completed only",
      filter: (comment) => comment.completed,
    },
    {
      label: "Waiting for approval",
      filter: (comment) => comment.completed && !comment.accepted,
    },
    ...TAGS.map((tag) => ({
      label: tag,
      filter: (comment) => comment.tags.includes(tag),
    })),
  ];

  const userInfo = useContext(UserInfoContext).userInfo;

  const [comment, setComment] = useState("");
  const [showCommentEditor, setShowCommentEditor] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [tags, setTags] = useState([]);
  const [comments, setComments] = useState([]);
  const [filteredComments, setFilteredComments] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState([]);
  const [mandatory, setMandatory] = useState(true);

  const filterIsActive = (filter) => {
    return filters.map((f) => f.label).includes(filter.label);
  };

  useEffect(() => {
    setLoadingComments(true);
    setFilters([]);
    getScreenComments(
      props.screenId,
      (comments) => {
        comments = comments.filter((comment) => !comment.archived);
        setLoadingComments(false);
        setComments(comments);
      },
      (error) => {
        setLoadingComments(false);
        console.log(error);
      }
    );
  }, [props.screenId]);

  useEffect(() => {
    applyFilters();
  }, [comments, filters]);

  const applyFilters = () => {
    let newFilteredCommnets = comments;
    filters.forEach((filter) => {
      newFilteredCommnets = newFilteredCommnets.filter(filter.filter);
    });
    setFilteredComments(newFilteredCommnets);
  };

  const resetState = () => {
    setComment("");
    setShowCommentEditor(false);
    setShowPreview(false);
    setTags([]);
  };

  const toggleTag = (tag) => {
    if (tags.includes(tag)) {
      setTags(tags.filter((t) => t !== tag));
    } else {
      setTags([...tags, tag]);
    }
  };

  const renderLabels = () => {
    return (
      <Grid container direction="row" spacing={1}>
        {TAGS.map((tag) => (
          <Grid item key={tag}>
            <Chip
              label={tag}
              variant="outlined"
              onClick={() => toggleTag(tag)}
              color={tags.includes(tag) ? "primary" : undefined}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const completeHandler = (comment) => {
    toggleCompelted(
      comment.id,
      (comment) => {
        setComments(
          comments.map((cmt) => (cmt.id !== comment.id ? cmt : comment))
        );
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const approveHandler = (comment) => {
    toggleApproved(
      comment.id,
      (comment) => {
        setComments(
          comments.map((cmt) => (cmt.id !== comment.id ? cmt : comment))
        );
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const addCommentHandler = () => {
    resetState();
    commentOnScreen(
      props.screenId,
      comment,
      tags,
      mandatory,
      (comment) => {
        setComments([...comments, comment]);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const commentOnThreadHandler = (comment, message) => {
    commentOnThread(
      comment.id,
      message,
      (updatedComment) => {
        setComments(
          comments.map((cmt) => (comment.id === cmt.id ? updatedComment : cmt))
        );
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const archiveCommentHandler = (comment) => {
    archiveScreenComment(
      comment.id,
      (archivedComment) => {
        setComments(comments.filter((cmt) => cmt.id !== archivedComment.id));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const openFilterMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeFilterMenu = () => {
    setAnchorEl(null);
  };

  const toggleFilter = (filter) => {
    let newFilters = null;
    if (filterIsActive(filter)) {
      newFilters = filters.filter((f) => f.label !== filter.label);
    } else {
      newFilters = [...filters, filter];
    }
    setFilters(newFilters);
  };

  const renderCommentEditor = () => {
    if (!showCommentEditor)
      return (
        <Grid container>
          <Grid item xs={11}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => setShowCommentEditor(true)}
              disabled={userInfo.external}
            >
              New Comment
            </Button>
          </Grid>
          <Grid item xs={1}>
            <IconButton color="primary" onClick={openFilterMenu}>
              <FilterListIcon />
            </IconButton>
          </Grid>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeFilterMenu}
          >
            {FILTERS.map((filter) => (
              <MenuItem key={filter.label} onClick={() => toggleFilter(filter)}>
                <ListItemIcon>
                  {filterIsActive(filter) ? (
                    <CheckBoxIcon fontSize="small" />
                  ) : (
                    <CheckBoxOutlineBlankIcon fontSize="small" />
                  )}
                </ListItemIcon>
                {filter.label}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      );

    return (
      <Box>
        <LabelledOutline label="Comment">
          {renderLabels()}
          <TextFieldMarkdownEditor
            code={comment}
            updateCode={(newComment) => setComment(newComment)}
            isPreview={showPreview}
          />
          <FormControlLabel
            control={
              <Switch
                name="checkedB"
                color="primary"
                checked={mandatory}
                onChange={() => setMandatory(!mandatory)}
              />
            }
            label="Mandatory"
          />
          <Box mt={1}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => resetState()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowPreview(!showPreview)}
                  disabled={comment.trim().length === 0}
                >
                  {showPreview ? "Edit" : "Preview"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={comment.trim().length === 0}
                  onClick={addCommentHandler}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Box>
        </LabelledOutline>
      </Box>
    );
  };

  const renderComments = () => {
    if (loadingComments) {
      return <LinearProgress />;
    }
    return (
      <Box>
        {filteredComments.map((comment, index) => (
          <Comment
            key={comment.id}
            comment={comment}
            user={userInfo}
            archiveCommentHandler={archiveCommentHandler}
            commentOnThreadHandler={commentOnThreadHandler}
            completeHandler={completeHandler}
            approveHandler={approveHandler}
          />
        ))}
      </Box>
    );
  };

  return (
    <Box className="editor-text">
      {renderCommentEditor()}
      {renderComments()}
    </Box>
  );
}

export default ReviewPanel;
