import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lessonDataActions } from "../store/lessonDataSlice";
import { multipleChoiceQuestionsActions } from "../store/multipleChoiceQuestionsSlice";
import {
  Box,
  IconButton,
  Typography,
  Grid,
  Paper,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
  ButtonGroup,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SimpleMarkdownEditor from "../general/SimpleMarkdownEditor";
import ConfirmModal from "../general/ConfirmModal";
import MultipleChoiceQuestionPreview from "./MultipleChoiceQuestionPreview";
import SaveModal from "../general/SaveModal";

function MultipleChoiceQuestion(props) {
  const dispatch = useDispatch();
  const screenId = props.screenId;

  const assessmentSkills = useSelector((state) => state.assessmentSkills)[
    screenId
  ];
  const questionIndex = props.questionIndex;

  const question = props.question.question;
  const selectType = props.question.select_type;
  const answerOptions = props.question.answer_options;

  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [saveModalIsOpen, setSaveModalIsOpen] = useState(false);
  const [isPreview, setIsPreview] = useState(false);

  const updateAnswerOption = (index, key) => (value) => {
    const newAnswerOptions = answerOptions.map((answer, idx) =>
      index === idx ? { ...answer, [key]: value } : answer
    );
    dispatch(
      multipleChoiceQuestionsActions.updateMcq({
        screenId,
        questionIndex,
        data: {
          answer_options: newAnswerOptions,
        },
      })
    );
  };

  const addAnswerOption = () => {
    const newAnswerOptions = [
      ...answerOptions,
      { label: "", feedback_message: "", valid: false, skill_id: null },
    ];
    dispatch(
      multipleChoiceQuestionsActions.updateMcq({
        screenId,
        questionIndex,
        data: {
          answer_options: newAnswerOptions,
        },
      })
    );
  };

  const deleteAnswerOption = (index) => {
    const newAnswerOptions = answerOptions.filter(
      (answer, idx) => idx !== index
    );
    dispatch(
      multipleChoiceQuestionsActions.updateMcq({
        screenId,
        questionIndex,
        data: {
          answer_options: newAnswerOptions,
        },
      })
    );
  };

  const updateQuestion = (newQuestion) => {
    dispatch(
      multipleChoiceQuestionsActions.updateMcq({
        screenId,
        questionIndex,
        data: {
          question: newQuestion,
        },
      })
    );
  };

  const updateSelectType = () => {
    const newSelectType = selectType === "multiple" ? "single" : "multiple";
    dispatch(
      multipleChoiceQuestionsActions.updateMcq({
        screenId,
        questionIndex,
        data: {
          select_type: newSelectType,
        },
      })
    );
  };

  const renderAnswerOptionSkills = (index) => {
    if (!props.isAssessment) return null;

    if (!answerOptions[index].valid) return null;

    if (!assessmentSkills) return null;

    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          item
          xs={2}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Typography variant="caption">Skill</Typography>
        </Grid>
        <Grid item xs={10}>
          <Box mx={1}>
            {assessmentSkills.length === 0 ? (
              <Typography variant="caption">
                No skills defined for this screen yet
              </Typography>
            ) : (
              <FormControl fullWidth>
                <Select
                  value={answerOptions[index].skill_id || -1}
                  onChange={(event) =>
                    updateAnswerOption(index, "skill_id")(event.target.value)
                  }
                  size="small"
                >
                  <MenuItem value={-1}>No skill selected</MenuItem>
                  {assessmentSkills.map((skill, index) => (
                    <MenuItem key={`${skill.name}-${index}`} value={skill.id}>
                      {skill.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        </Grid>
      </Grid>
    );
  };

  const renderAnswerOption = (index) => (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={11}>
        <Paper elevation={0} variant="outlined">
          <Box pb={1}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                item
                xs={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography align="right" display="inline" variant="caption">
                  Answer
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <SimpleMarkdownEditor
                  code={answerOptions[index].label}
                  updateCode={updateAnswerOption(index, "label")}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                item
                xs={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography variant="caption">Feedback</Typography>
              </Grid>
              <Grid item xs={10}>
                <SimpleMarkdownEditor
                  code={answerOptions[index].feedback_message}
                  updateCode={updateAnswerOption(index, "feedback_message")}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                item
                xs={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography variant="caption">Is correct?</Typography>
              </Grid>
              <Grid item xs={10}>
                <Switch
                  size="small"
                  color="primary"
                  checked={answerOptions[index].valid}
                  onChange={() =>
                    updateAnswerOption(
                      index,
                      "valid"
                    )(!answerOptions[index].valid)
                  }
                />
              </Grid>
            </Grid>

            {renderAnswerOptionSkills(index)}
          </Box>
        </Paper>
      </Grid>

      <Grid container item xs={1}>
        <IconButton
          disabled={false}
          onClick={() => deleteAnswerOption(index)}
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  const renderEdit = () => (
    <>
      <SimpleMarkdownEditor
        title="Answer"
        isRendered={false}
        mode="props"
        code={question}
        updateCode={updateQuestion}
      />
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              size="small"
              color="primary"
              checked={selectType === "multiple"}
              onChange={updateSelectType}
            />
          }
          label={<Typography variant="caption">Is multiple select?</Typography>}
        />
      </FormGroup>
      <Typography variant="subtitle2">Choices</Typography>
      {answerOptions.map((answer, index) => (
        <Box key={`answer-${index}`} mb={1}>
          {renderAnswerOption(index)}
        </Box>
      ))}
      <Button
        variant="outlined"
        size="small"
        fullWidth
        onClick={addAnswerOption}
      >
        Add Choice
      </Button>
    </>
  );

  const renderPreview = () => (
    <MultipleChoiceQuestionPreview question={props.question} />
  );

  return (
    <Box mb={2}>
      <ConfirmModal
        message={"Are you sure to delete this question?"}
        isOpen={confirmModalIsOpen}
        confirm={() => props.deleteQuestion(props.question)}
        cancel={() => setConfirmModalIsOpen(false)}
      />
      <SaveModal
        isOpen={saveModalIsOpen}
        save={(message) => props.saveQuestion(props.question, message)}
        close={() => setSaveModalIsOpen(false)}
      />
      <Paper elevation={0} variant="outlined">
        <Box p={2}>
          <Box mb={1}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle2">
                Question {props.questionIndex + 1}
              </Typography>
            </Grid>
            {isPreview ? renderPreview() : renderEdit()}
          </Box>
          <Box mt={2}>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => setConfirmModalIsOpen(true)}
              >
                Delete
              </Button>

              <ButtonGroup>
                {isPreview ? (
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => setIsPreview(false)}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => setIsPreview(true)}
                  >
                    Preview
                  </Button>
                )}

                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => setSaveModalIsOpen(true)}
                >
                  Save with message
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => props.saveQuestion(props.question)}
                >
                  Save
                </Button>
              </ButtonGroup>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default MultipleChoiceQuestion;
