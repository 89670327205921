import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
} from "@mui/material";

import PropTypes from "prop-types";

function SaveModal(props) {
  const [saveMessage, setSaveMessage] = useState("");

  const handleSave = () => {
    props.save(saveMessage);
    props.close();
  };

  const handleClose = () => {
    setSaveMessage("");
    props.close();
  };

  return (
    <Dialog open={props.isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Save with message</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          name="message"
          label="Change Description"
          value={saveMessage}
          onChange={(event) => setSaveMessage(event.target.value)}
        />
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={!saveMessage}
              color="primary"
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          </Grid>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

SaveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

SaveModal.defaultProps = {
  isOpen: false,
};

export default SaveModal;
