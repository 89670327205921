import React, { useState } from "react";
import { Grid, IconButton, Box, Divider } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import GenericInput from "./GenericInput";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { getDictionaryTypes } from "../../utils/pycheck";

export default function DictionaryInput(props) {
  const [key, setKey] = useState("");
  const [keyPython, setKeyPython] = useState("");
  const [value, setValue] = useState("");
  const [valuePython, setValuePython] = useState("");
  const [entries, setEntries] = useState([]);

  const addEntryHandler = () => {
    const newEntries = [
      ...entries,
      {
        key: keyPython,
        value: valuePython,
      },
    ];
    setEntries(newEntries);
    setKey("");
    setKeyPython("");
    setValue("");
    setValuePython("");
    props.valueUpdatedCallback(entries, getPythonValue(newEntries));
  };

  const getPythonValue = (entries) => {
    let entriesPython = entries.map((entry) => `${entry.key}: ${entry.value}`);
    entriesPython = entriesPython.join(", ");
    entriesPython = `{${entriesPython}}`;
    return entriesPython;
  };

  const deleteEntry = (entryIndex) => {
    const newEntries = entries.filter((entry, index) => index !== entryIndex);
    setEntries(newEntries);
    props.valueUpdatedCallback(entries, getPythonValue(newEntries));
  };

  const renderEntries = () =>
    entries.map((entry, index) => (
      <Grid key={`entry-${index}`} container spacing={1}>
        <Grid item xs={5}>
          <ReactMarkdown
            children={`\`${entry.key}\``}
            remarkPlugins={[remarkGfm]}
          />
        </Grid>
        <Grid item xs={5}>
          <ReactMarkdown
            children={`\`${entry.value}\``}
            remarkPlugins={[remarkGfm]}
          />
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={() => deleteEntry(index)} color="secondary">
            <DeleteForeverIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    ));

  const updateValue = (value, valuePython) => {
    setValue(value);
    setValuePython(valuePython);
  };

  const updateKey = (key, keyPython) => {
    setKey(key);
    setKeyPython(keyPython);
  };

  const { keyType, valueType } = getDictionaryTypes(props.type);

  return (
    <Box>
      {renderEntries()}
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <GenericInput
            type={keyType}
            label="key"
            value={key}
            valueUpdatedCallback={updateKey}
          />
        </Grid>
        <Grid item xs={5}>
          <GenericInput
            type={valueType}
            label="value"
            value={value}
            valueUpdatedCallback={updateValue}
          />
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={addEntryHandler} color="primary">
            <AddCircleOutlineIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}
