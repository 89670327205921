import React, { useState, useEffect } from "react";
import AuthoringTerminal from "../../AuthoringTerminal";
import { CLI_TEST_TAB } from "./constants";

export default function CliTestTab({ lesson, screen, currentTab }) {
  // This state variable is used to trigger a component re-render as a way to reset the terminal
  const [needsRerender, setNeedsRerender] = useState(false);

  useEffect(() => {
    if (needsRerender) {
      setNeedsRerender(false);
    }
  }, [needsRerender]);

  if (needsRerender || currentTab !== CLI_TEST_TAB) return null;

  return (
    <AuthoringTerminal
      lessonId={lesson.id}
      screenId={screen.id}
      screenSequence={screen.sequence - 1}
      resetTerminal={() => setNeedsRerender(true)}
    />
  );
}
