export const LEVELS = ["Beginner", "Intermediate", "Advanced"];
export const TRACK_TYPES = {
  skill: "Skill Path",
  career: "Career Path",
};

export const MODES = [
  null,
  "python",
  "r",
  "sql",
  "javascript",
  "ecmascript",
  "markdown",
  "json",
  "html",
  "css",
  "bash",
  "diff",
  "dockerfile",
  "yaml",
  "spreadsheet",
  "text",
];

export const CM_MODE_MAP = {
  null: "text/plain",
  undefined: "text/plain",
  python: "text/x-python",
  r: "text/x-rsrc",
  sql: "text/x-sql",
  html: "text/x-django",
  json: "application/x-json",
  javascript: "application/x-javascript",
  ecmascript: "application/x-ecmascript",
  markdown: "text/x-markdown",
  css: "text/css",
  bash: "text/x-sh",
  diff: "text/x-diff",
  dockerfile: "text/x-dockerfile",
  yaml: "text/x-yaml",
  spreadsheet: "text/x-spreadsheet",
  text: "text/plain",
};
