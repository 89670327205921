import React from "react";

import {
  FormControl,
  InputLabel,
  Select,
  Chip,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";

export default function MultiSelectField({
  label,
  selected,
  onChange,
  values,
  disabled,
}) {
  const labelId = `${label.replace(" ", "-").toLowerCase()}-label`;

  return (
    <FormControl variant="outlined" margin="normal">
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        // TODO: we want to put size="small" here but somehow it messes up the style.
        labelId={labelId}
        label={label}
        multiple
        value={selected}
        onChange={onChange}
        renderValue={(selected) => (
          <div>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </div>
        )}
        disabled={disabled}
      >
        {values.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox
              color="primary"
              checked={selected.includes(name)}
              disabled={disabled}
            />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
