import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Stack,
  TextField,
  IconButton,
  CircularProgress,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

function NewEntryForm(props) {
  const [addMode, setAddMode] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [name, setName] = useState("");
  const [sequence, setSequence] = useState("");

  const handleAdd = async () => {
    try {
      setIsAdding(true);
      await props.handleAdd(sequence, name);
    } catch (error) {
      console.log(error);
    }
    setSequence("");
    setName("");
    setIsAdding(false);
    setAddMode(false);
  };

  const handleCancel = () => {
    setSequence("");
    setName("");
    setAddMode(false);
  };

  if (addMode) {
    return (
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="center"
      >
        {props.isNumbered && (
          <TextField
            value={sequence}
            label={"Number"}
            onChange={(event) => setSequence(event.target.value)}
            disabled={isAdding || props.disabled}
            type="number"
            size="small"
            InputProps={{
              inputProps: { min: 0 },
            }}
          />
        )}
        <TextField
          value={name}
          label={props.nameLabel}
          onChange={(event) => setName(event.target.value)}
          disabled={isAdding || props.disabled}
          size="small"
        />
        <IconButton
          color="secondary"
          onClick={handleCancel}
          disabled={isAdding}
        >
          <CancelIcon />
        </IconButton>
        <Button
          color={props.color}
          variant={props.variant}
          disabled={
            isAdding ||
            !name ||
            (props.isNumbered && !sequence) ||
            props.disabled
          }
          onClick={handleAdd}
        >
          {isAdding ? <CircularProgress size={24} /> : props.addLabel}
        </Button>
      </Stack>
    );
  }

  return (
    <Button
      color={props.color}
      variant={props.variant}
      onClick={() => setAddMode(true)}
      disabled={props.disabled}
    >
      {props.newLabel}
    </Button>
  );
}

NewEntryForm.propTypes = {
  handleAdd: PropTypes.func.isRequired,
  newLabel: PropTypes.string,
  addLabel: PropTypes.string,
  nameLabel: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  isNumbered: PropTypes.bool,
  disabled: PropTypes.bool,
};

NewEntryForm.defaultProps = {
  newLabel: "New",
  addLabel: "Add",
  variant: "contained",
  color: "primary",
  nameLabel: "Name",
  isNumbered: true,
  disabled: false,
};

export default NewEntryForm;
