import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  IconButton,
  Stack,
  Alert,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SearchableTable from "../general/SearchableTable";
import { useHistory } from "react-router-dom";
import { LEVELS } from "../general/constants";
import NewEntryForm from "../general/NewEntryForm";
import ImportModal from "../general/ImportModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyModal from "../general/CopyModal";

const FILTERS = [];

function CourseList(props) {
  const history = useHistory();

  const [importModalIsOpen, setImportModalIsOpen] = useState(false);
  const [courseToCopy, setCourseToCopy] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState("");

  const COLUMNS = [
    {
      key: "sequence",
      label: "Number",
      type: "text",
    },
    {
      key: "name",
      label: "Name",
      type: "text",
    },
    {
      key: "release_name",
      label: "Release",
      type: "text",
    },
    {
      key: "skill_level",
      label: "Level",
      type: "component",
      render: (course, col) => LEVELS[course.skill_level],
    },
    {
      key: "num_lessons",
      label: "Lessons",
      type: "text",
    },
    {
      key: "num_projects",
      label: "Projects",
      type: "text",
    },
    {
      key: "duration",
      label: "Time Estimate",
      type: "component",
      render: (course, col) => (course.duration ? `${course.duration}h` : ""),
    },
    {
      key: "deprecated",
      label: "Deprecated",
      type: "component",
      render: (course, col) => (course.deprecated ? "Yes" : "No"),
    },
    {
      key: "edit",
      label: "",
      type: "component",
      render: (course, col) => (
        <Tooltip title={course.is_main ? "View" : "Edit"}>
          <IconButton onClick={() => history.push(`/course/${course.id}`)}>
            {course.is_main ? <VisibilityIcon /> : <EditIcon />}
          </IconButton>
        </Tooltip>
      ),
    },
    {
      key: "copy",
      label: "",
      type: "component",
      render: (course, col) => (
        <Tooltip title="Copy to another release">
          <IconButton onClick={() => setCourseToCopy(course)}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const updateCourseList = async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await fetch("/api/courses");
      const courses = await response.json();
      if (courses.error) {
        setError(courses.error);
      } else {
        setCourses(courses);
      }
    } catch (error) {
      setError(
        "An error occurred while loading the course list. Try again later."
      );
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleCreateCourse = async (sequence, name) => {
    try {
      setError("");
      const response = await fetch("/api/course", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sequence: parseInt(sequence),
          name: name,
        }),
      });
      const data = await response.json();
      if (data.error) {
        setError(data.error);
      } else {
        updateCourseList();
      }
    } catch (error) {
      setError("An error occured while creating the course. Try again later.");
    }
  };

  const importCourses = async (releaseId, courseSequences) => {
    return fetch(`/api/courses/release/${releaseId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sequences: courseSequences,
      }),
    });
  };

  const copyCourse = async (releaseId) => {
    return fetch(`/api/course/${courseToCopy.id}/release/${releaseId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
  };

  useEffect(() => {
    updateCourseList();
  }, []);

  const closeImportHandler = () => {
    setImportModalIsOpen(false);
    updateCourseList();
  };

  const closeCopyHandler = () => {
    setCourseToCopy();
    updateCourseList();
  };

  return (
    <Container sx={{ pb: 4 }}>
      <ImportModal
        isOpen={importModalIsOpen}
        closeHandler={closeImportHandler}
        title="Import Courses"
        import={importCourses}
      />
      <CopyModal
        isOpen={courseToCopy}
        closeHandler={closeCopyHandler}
        title="Copy Course Into Another Release"
        copy={copyCourse}
        renderObjectToCopy={() =>
          courseToCopy && (
            <Typography>
              {`${courseToCopy.sequence} - ${courseToCopy.name} (${courseToCopy.release_name})`}
            </Typography>
          )
        }
      />
      <Box my={5}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4">Courses</Typography>
          <Stack direction="row" spacing={1}>
            <NewEntryForm
              variant="outlined"
              newLabel="New Course"
              addLabel="Create Course"
              nameLabel="Course Name"
              handleAdd={handleCreateCourse}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => setImportModalIsOpen(true)}
            >
              Import
            </Button>
          </Stack>
        </Stack>
      </Box>

      {error && <Alert severity="error">{error}</Alert>}

      <SearchableTable
        cols={COLUMNS}
        rows={courses}
        isLoading={isLoading}
        filters={FILTERS}
        rowsPerPage={10}
      />
    </Container>
  );
}

export default CourseList;
