import React, { useState } from "react";
import {
  Stack,
  TextField,
  Button,
  Alert,
  LinearProgress,
  Grid,
  Box,
  Typography,
  ButtonGroup,
  Badge,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import TooltipButton from "../general/TooltipButton";

export default function StepMetadata(props) {
  const track = props.track;
  const stepIndex = props.stepIndex;
  const numberOfSteps = props.numberOfSteps;
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [hasChanges, setHasChanges] = useState(false);
  const [step, setStep] = useState(props.step);

  const disabled = saving || track.is_main;

  const updateField = (field, value) => {
    setHasChanges(true);
    setStep({ ...step, [field]: value });
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      setError("");
      setHasChanges(false);
      await fetch(`/api/step/${step.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: step.name,
          description: step.description,
          meta_description: step.meta_description,
          meta_title: step.meta_title,
        }),
      });
    } catch (error) {
      console.log(error);
      setError("Could not update the step. Try again later.");
    }
    setSaving(false);
  };

  const handleDelete = async () => {
    try {
      setSaving(true);
      setError("");
      await fetch(`/api/track/${track.id}/step/${step.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });
      setSaving(false);
      props.reloadSteps();
    } catch (error) {
      setSaving(false);
      console.log(error);
      setError("Could not delete the step. Try again later.");
    }
  };

  const handleMoveStep = async (swapWithPrevious) => {
    try {
      setSaving(true);
      setError("");
      await fetch(`/api/track/${track.id}/step/${step.id}/swap`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          swap_with_previous: swapWithPrevious,
        }),
      });
      setSaving(false);
      props.reloadSteps();
    } catch (error) {
      setSaving(false);
      console.log(error);
      setError("Could not move the step. Try again later.");
    }
  };

  const renderForm = () => (
    <Grid container mt={2}>
      {saving && (
        <Grid item xs={12} px={1} mb={3}>
          <LinearProgress />
        </Grid>
      )}
      {error && (
        <Grid item xs={12} px={1} mb={3}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      <Grid item xs={6} px={1}>
        {renderLeftForm()}
      </Grid>
      <Grid item xs={6} px={1}>
        {renderRightForm()}
      </Grid>
      <Grid item xs={12} px={1} my={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            color="secondary"
            disabled={disabled}
            onClick={handleDelete}
          >
            Delete Step
          </Button>
          <Stack direction="row" alignItems="center" spacing={1}>
            <ButtonGroup variant="contained" color="primary">
              <TooltipButton
                title="Move step down"
                onClick={() => handleMoveStep(false)}
                color="primary"
                disabled={stepIndex === numberOfSteps - 1 || disabled}
              >
                <ArrowDropDownIcon />
              </TooltipButton>
              <TooltipButton
                title="Move step up"
                onClick={() => handleMoveStep(true)}
                color="primary"
                disabled={stepIndex === 0 || disabled}
              >
                <ArrowDropUpIcon />
              </TooltipButton>
            </ButtonGroup>
            <Badge color="secondary" variant="dot" invisible={!hasChanges}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={disabled}
              >
                Save Changes
              </Button>
            </Badge>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );

  const renderLeftForm = () => (
    <Stack direction="column" spacing={2}>
      <Box>
        <Typography variant="subtitle2">Step Name</Typography>
        <TextField
          onChange={(event) => updateField("name", event.target.value)}
          value={step.name}
          fullWidth
          disabled={disabled}
          size="small"
        />
      </Box>
      <Box>
        <Typography variant="subtitle2">Meta Title</Typography>
        <TextField
          onChange={(event) => updateField("meta_title", event.target.value)}
          value={step.meta_title}
          fullWidth
          disabled={disabled}
          size="small"
        />
      </Box>
      <Box>
        <Typography variant="subtitle2">Slug</Typography>
        <TextField
          onChange={(event) => updateField("slug", event.target.value)}
          value={step.slug}
          fullWidth
          disabled={disabled}
          size="small"
        />
      </Box>
    </Stack>
  );

  const renderRightForm = () => (
    <Stack direction="column" spacing={2}>
      <Box>
        <Typography variant="subtitle2">Description</Typography>
        <TextField
          onChange={(event) => updateField("description", event.target.value)}
          value={step.description}
          minRows={3}
          multiline
          fullWidth
          disabled={disabled}
          size="small"
        />
      </Box>
      <Box>
        <Typography variant="subtitle2">Meta Description</Typography>
        <TextField
          onChange={(event) =>
            updateField("meta_description", event.target.value)
          }
          value={step.meta_description}
          minRows={3}
          multiline
          fullWidth
          disabled={disabled}
          size="small"
        />
      </Box>
    </Stack>
  );

  return renderForm();
}
