import React from "react";
import ReactDOM from "react-dom";
import { InputLabel, Box } from "@mui/material";
import NotchedOutline from "@mui/material/OutlinedInput/NotchedOutline";

export const LabelledOutline = ({ classes, id, label, children }) => {
  const [labelWidth, setLabelWidth] = React.useState(0);
  const labelRef = React.useRef(null);

  React.useEffect(() => {
    const labelNode = ReactDOM.findDOMNode(labelRef.current);
    setLabelWidth(labelNode != null ? labelNode.offsetWidth : 0);
  }, [label]);

  return (
    <Box my={2} sx={{ position: "relative" }}>
      <InputLabel
        ref={labelRef}
        htmlFor={id}
        variant="outlined"
        shrink
        sx={{
          backgroundColor: "white",
          display: "inline-block",
          px: 0.7,
        }}
      >
        {label}
      </InputLabel>
      <Box px={2} pb={2}>
        {children}
        <NotchedOutline notched labelWidth={labelWidth} />
      </Box>
    </Box>
  );
};

export default LabelledOutline;
