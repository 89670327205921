import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import LessonMetadata from "./LessonMetadata";
import { Box, Button, Grid } from "@mui/material";
import LessonRunnerSpec from "./LessonRunnerSpec";
import LessonReview from "./LessonReview";
import LessonExport from "./LessonExport";
import LessonDeploy from "./LessonDeploy";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import BugReportIcon from "@mui/icons-material/BugReport";
import { UserInfoContext } from "../provider/UserInfoProvider";
import ConfirmModal from "../general/ConfirmModal";
import DeleteIcon from "@mui/icons-material/Delete";

export default function LessonConfiguration(props) {
  const history = useHistory();
  const userInfo = useContext(UserInfoContext).userInfo;

  const lesson = props.lesson;

  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);

  const deleteLesson = async () => {
    await fetch(`/api/lesson/${lesson.id}`, {
      method: "DELETE",
    });
    history.push("/");
  };

  return (
    <Grid container>
      <ConfirmModal
        isOpen={confirmModalIsOpen}
        cancel={() => setConfirmModalIsOpen(false)}
        confirm={deleteLesson}
        message={`Are you sure you want to delete lesson ${lesson.sequence} (${lesson.release_name})? This can't be undone.`}
      />
      <Grid item xs={12} sm={6}>
        <Box p={2}>
          <LessonMetadata lesson={lesson} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box p={2}>
          <LessonRunnerSpec lesson={lesson} />
          <LessonReview lesson={lesson} />
          <LessonExport lesson={lesson} />
          <LessonDeploy lesson={lesson} />
          <Box pt={2}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ChatBubbleIcon />}
              disabled={lesson.is_shallow_import}
              onClick={() => history.push(`/lesson/${lesson.id}/comments`)}
              fullWidth
              size="small"
            >
              View Comments
            </Button>
          </Box>
          <Box pt={2}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<BugReportIcon />}
              disabled={lesson.is_shallow_import}
              onClick={() => history.push(`/lesson/${lesson.id}/test`)}
              fullWidth
              size="small"
            >
              Test lesson
            </Button>
          </Box>
          {!userInfo.external_user && (
            <Box pt={2}>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => setConfirmModalIsOpen(true)}
                fullWidth
                disabled={lesson.is_shallow_import}
                size="small"
              >
                Delete lesson
              </Button>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
