import React from "react";
import { UserInfoContext } from "../provider/UserInfoProvider";

const withUserInfo = () => (WrappedComponent) => {
  const Component = (props) => {
    const { userInfo } = React.useContext(UserInfoContext);

    return <WrappedComponent {...props} userInfo={userInfo} />;
  };

  Component.propTypes = WrappedComponent.propTypes;
  return Component;
};

export default withUserInfo;
