import React, { useState, useEffect } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Grid,
  Container,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import {
  archiveScreenComment,
  commentOnThread,
  toggleCompelted,
  toggleApproved,
  getLessonComments,
} from "../api/comments";
import Comment from "./Comment";

function LessonComments(props) {
  const lessonId = props.match.params.lessonId;

  const [comments, setComments] = useState([]);
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetch("/api/user")
      .then((response) => response.json())
      .then((user) => {
        setUser(user);
        getLessonComments(
          lessonId,
          (comments) => {
            comments = comments.filter((comment) => !comment.archived);
            setLoading(false);
            setComments(comments);
          },
          (error) => {
            setLoading(false);
            console.log(error);
          }
        );
      });
  }, []);

  const completeOrApprove = (action) => (comment) => {
    action(
      comment.id,
      (comment) => {
        setComments(
          comments.map((cmt) => (cmt.id !== comment.id ? cmt : comment))
        );
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const commentOnThreadHandler = (comment, message) => {
    commentOnThread(
      comment.id,
      message,
      (updatedComment) => {
        setComments(
          comments.map((cmt) => (comment.id === cmt.id ? updatedComment : cmt))
        );
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const archiveCommentHandler = (comment) => {
    archiveScreenComment(
      comment.id,
      (archivedComment) => {
        setComments(comments.filter((cmt) => cmt.id !== archivedComment.id));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const renderComments = () => {
    if (loading) {
      return (
        <Grid container alignItems="center" justifyContent="center">
          <Grid item>
            <Box my={1}>
              <CircularProgress />
            </Box>
          </Grid>
        </Grid>
      );
    }
    return comments.map((comment) => (
      <Comment
        key={comment.id}
        comment={comment}
        user={user}
        archiveCommentHandler={archiveCommentHandler}
        commentOnThreadHandler={commentOnThreadHandler}
        completeHandler={completeOrApprove(toggleCompelted)}
        approveHandler={completeOrApprove(toggleApproved)}
      />
    ));
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Button
              style={{ color: "white" }}
              startIcon={<ArrowBackIcon />}
              component={Link}
              to={`/lesson/${lessonId}`}
            >
              Back to lesson
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Container>
        <Box my={5}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography variant="h4">Lesson Comments</Typography>
            </Grid>
          </Grid>
          {renderComments()}
        </Box>
      </Container>
    </div>
  );
}

export default LessonComments;
