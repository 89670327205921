import React, { useState, useEffect } from "react";

import {
  Alert,
  Autocomplete,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import LabelledOutline from "./general/LabelledOutline";

const styles = {
  closeButton: {
    position: "absolute",
    top: "0",
    right: "0",
    "&:hover": {
      cursor: "pointer",
      color: "#F50057",
    },
  },
};

function EditReleaseModal(props) {
  const [releaseName, setReleaseName] = useState(props.release.name);
  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);
  const [fetchingLessons, setFetchingLessons] = useState(false);
  const [editedLessons, setEditedLessons] = useState([]);
  const [lessonsToMove, setLessonsToMove] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState("");

  const handleClose = (needsUpdate) => {
    props.closeModal(needsUpdate);
  };

  const fetchLessons = () => {
    setFetchingLessons(true);
    setError("");
    fetch("/api/lessons")
      .then((data) => data.json())
      .then((editedLessons) => {
        if (editedLessons) {
          editedLessons = editedLessons.filter(
            (lesson) =>
              lesson.release_id !== props.release.id &&
              lesson.release_status !== "Published"
          );
          setEditedLessons(editedLessons);
          if (editedLessons.length > 0) {
            setSelectedLesson(editedLessons[0]);
          }
          setFetchingLessons(false);
        }
      })
      .catch((error) => {
        setFetchingLessons(false);
        setError("An error occured while loading edited lessons.");
      });
  };

  const moveLessons = () => {
    if (releaseName === "") {
      setError("The release name cannot be empty.");
      return;
    }
    if (lessonsToMove.length === 0) {
      renameRelease();
      return;
    }
    setSaving(true);
    setError("");
    const lessonsByRelease = groupLessonsByRelease();
    let promises = Object.keys(lessonsByRelease).map((releaseId) =>
      fetch(`/api/release/${props.release.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: releaseName,
          lessons_list: lessonsByRelease[releaseId].map(
            (lesson) => lesson.sequence
          ),
          from_release: releaseId,
        }),
      })
    );
    Promise.all(promises)
      .then(() => {
        setSaving(false);
        props.closeModal(true);
      })
      .catch((error) => {
        console.log(error);
        setSaving(false);
        setError("An error occured while movign the lessons.");
      });
  };

  const renameRelease = () => {
    fetch(`/api/release/${props.release.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: releaseName,
        lessons_list: [],
        from_release: props.release.id,
      }),
    })
      .then(() => {
        setSaving(false);
        props.closeModal(true);
      })
      .catch((error) => {
        console.log(error);
        setSaving(false);
        setError("An error occured while renaming the release.");
      });
  };

  const groupLessonsByRelease = () => {
    const lessonsByRelease = {};
    lessonsToMove.forEach((lesson) => {
      if (!lessonsByRelease[lesson.release_id]) {
        lessonsByRelease[lesson.release_id] = [];
      }
      lessonsByRelease[lesson.release_id].push(lesson);
    });
    return lessonsByRelease;
  };

  const getRelease = (lesson) => {
    const releases = props.releases.filter(
      (release) => release.id === lesson.release_id
    );
    return releases.length > 0 ? releases[0] : null;
  };

  const getLabel = (lesson) => {
    const release = getRelease(lesson);
    return `${lesson.sequence} (${release ? release.name : "no release yet"})`;
  };

  const renderLessonsToMoveList = () => {
    if (fetchingLessons || !editedLessons) {
      return (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      );
    }
    return (
      <List>
        {lessonsToMove.map((lesson) => (
          <ListItem key={lesson.id}>
            <Typography>{getLabel(lesson)}</Typography>
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                onClick={() => {
                  setLessonsToMove(
                    lessonsToMove.filter((l) => l.id !== lesson.id)
                  );
                }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        <ListItem>
          <Autocomplete
            options={editedLessons}
            getOptionLabel={getLabel}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Lesson (release)"
                variant="outlined"
              />
            )}
            fullWidth
            onChange={(e, value) => {
              setSelectedLesson(value);
            }}
            value={selectedLesson}
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              onClick={() => {
                if (!selectedLesson) return;
                if (lessonsToMove.includes(selectedLesson)) return;
                setLessonsToMove([...lessonsToMove, selectedLesson]);
              }}
            >
              <AddIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    );
  };

  useEffect(() => {
    fetchLessons();
  }, []);

  return (
    <Dialog onClose={handleClose} open={true} maxWidth={"sm"} fullWidth>
      <IconButton sx={styles.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle id="select-relase-title" onClose={handleClose}>
        Edit Release
      </DialogTitle>
      {error && (
        <Box p={1}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <DialogContent dividers>
        <Box p={1}>
          <TextField
            fullWidth
            variant="outlined"
            label="Release Name"
            value={releaseName}
            onChange={(event) => setReleaseName(event.target.value)}
          />
        </Box>
        <LabelledOutline label="Lessons to copy">
          {renderLessonsToMoveList()}
        </LabelledOutline>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={moveLessons}
          disabled={saving || fetchingLessons}
        >
          {saving ? <CircularProgress size={24} /> : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditReleaseModal;
