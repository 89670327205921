import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  AppBar,
  Alert,
  Box,
  Button,
  IconButton,
  MenuItem,
  Toolbar,
  Typography,
  Grid,
  Menu,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import PlayForWorkIcon from "@mui/icons-material/PlayForWork";
import { lessonDataActions } from "../store/lessonDataSlice";
import User from "../User";

const MENU_SX = {
  overflow: "visible",
  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
  mt: 1.5,
  bgcolor: "primary.main",
  color: "white",
  "& .MuiAvatar-root": {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1,
  },
  "&:before": {
    content: "''",
    display: "block",
    position: "absolute",
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    bgcolor: "primary.main",
    transform: "translateY(-50%) rotate(45deg)",
    zIndex: 0,
  },
};

export const LessonMenu = ({ title, save, children }) => {
  const dispatch = useDispatch();

  const params = useParams();
  const lessonId = params.lessonId;
  const screenId = parseInt(params.screenId);
  const history = useHistory();

  const lessonData = useSelector((state) => state.lessonData);
  const lesson = lessonData.lessons[lessonId];

  const screens = lessonData.lessonScreens[lessonId];
  const screenIndex = screens.findIndex((screen) => screen.id === screenId);

  const [hasMandatoryUnapprovedComments, setHasMandatoryUnapprovedComments] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const fetchHasMandatoryUnapprovedComments = async () => {
    const response = await fetch(`/api/lesson/${lessonId}/mandatory`);
    const responseData = await response.json();
    setHasMandatoryUnapprovedComments(responseData);
  };

  useEffect(() => {
    fetchHasMandatoryUnapprovedComments();
  }, []);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const goToScreen = (targetScreenId) => {
    if (targetScreenId === screenId) return;
    save();
    if (!targetScreenId) {
      history.push(`/lesson/${lessonId}`);
    } else {
      history.push(`/lesson/${lessonId}/screen/${targetScreenId}`);
    }
  };

  const createScreen = async (position) => {
    try {
      const response = await fetch(`/api/lesson/${lessonId}/screens`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ sequence: position }),
      });
      const responseData = await response.json();
      if (responseData.error) {
        // TODO: Handle this error
        console.log(responseData.error);
      } else {
        dispatch(
          lessonDataActions.setLessonScreens({
            lessonId: lesson.id,
            screens: responseData.screens,
            backend: true,
          })
        );
        save();
        history.push(`/lesson/${lessonId}/screen/${responseData.newScreenId}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInsert = () => {
    if (screenIndex === -1) {
      createScreen(1);
    } else {
      createScreen(screens[screenIndex].sequence + 1);
    }
  };

  const handleAddAtTheEnd = () => {
    if (screens.length === 0) {
      createScreen(1);
    } else {
      createScreen(screens[screens.length - 1].sequence + 1);
    }
  };

  const hasPrevious = () => {
    return screenIndex !== -1;
  };

  const hasNext = () => {
    return screenIndex < screens.length - 1;
  };

  const renderPreviousArrow = () => {
    if (!hasPrevious()) return null;
    return (
      <IconButton color="inherit" onClick={goToPrevious}>
        <ArrowBackIosIcon fontSize="small" />
      </IconButton>
    );
  };

  const renderNextArrow = () => {
    if (!hasNext()) return null;
    return (
      <IconButton color="inherit" onClick={goToNext}>
        <ArrowForwardIosIcon fontSize="small" />
      </IconButton>
    );
  };

  const goToNext = () => {
    const nextScreen = screens[screenIndex + 1];
    goToScreen(nextScreen.id);
  };

  const goToPrevious = () => {
    if (screenIndex === 0) {
      goToScreen(0);
    } else {
      const previousScreen = screens[screenIndex - 1];
      goToScreen(previousScreen.id);
    }
  };

  const renderMenuButtons = () => {
    if (screens.length === 0) {
      return (
        <MenuItem>
          <Button
            fullWidth
            startIcon={<AddIcon />}
            color="secondary"
            variant="contained"
            onClick={handleAddAtTheEnd}
          >
            Create first screen
          </Button>
        </MenuItem>
      );
    }
    return (
      <>
        <MenuItem>
          <Button
            fullWidth
            startIcon={<PlayForWorkIcon />}
            color="secondary"
            variant="contained"
            onClick={handleInsert}
          >
            {screenId
              ? "Insert screen after current"
              : "Insert screen before first"}
          </Button>
        </MenuItem>
        {screenIndex < screens.length - 1 && (
          <MenuItem>
            <Button
              fullWidth
              startIcon={<AddIcon />}
              color="secondary"
              variant="contained"
              onClick={handleAddAtTheEnd}
            >
              Add screen at the end
            </Button>
          </MenuItem>
        )}
      </>
    );
  };

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="lesson-menu"
        open={open}
        onClose={closeMenu}
        onClick={closeMenu}
        PaperProps={{
          elevation: 0,
          sx: MENU_SX,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box bgcolor="primary.main">
          <MenuItem
            sx={{
              backgroundColor: screenId ? "primary.main" : "primary.light",
            }}
            onClick={() => goToScreen(null)}
          >
            Lesson metadata
          </MenuItem>
          {screens.map((screen) => (
            <MenuItem
              key={screen.id}
              sx={{
                backgroundColor:
                  screenId === screen.id ? "primary.light" : "primary.main",
              }}
              onClick={() => goToScreen(screen.id)}
            >
              {screen.sequence}. {screen.title || `Screen ${screen.sequence}`}
            </MenuItem>
          ))}
          {renderMenuButtons()}
        </Box>
      </Menu>
    );
  };

  const renderCommentAlert = () => {
    if (!hasMandatoryUnapprovedComments) return null;
    return (
      <Alert
        severity="warning"
        style={{ borderRadius: 0 }}
        action={
          <Button
            color="inherit"
            size="small"
            onClick={() => history.push(`/lesson/${lessonId}/comments`)}
          >
            View Comments
          </Button>
        }
      >
        <strong>This lesson has unaccepted mandatory comments</strong>
      </Alert>
    );
  };

  const renderReleaseError = () => {
    if (!lesson.release_commit_status && !lesson.release_deletion_date)
      return null;

    let text = "This lesson";
    if (lesson.release_commit_status)
      text += ` has commit errors: ${lesson.release_commit_status}`;
    else
      text += `'s branch has been deleted in GitHub at ${lesson.release_deletion_date}`;

    return (
      <Alert severity="error" style={{ borderRadius: 0 }}>
        <strong>{text}</strong>
      </Alert>
    );
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Box>
              <IconButton color="inherit" onClick={() => history.push("/")}>
                <HomeIcon />
              </IconButton>
            </Box>
          </Grid>

          <Grid item>
            <Typography
              variant="h5"
              className="dq-flex dq-items-center dq-space-x-6"
            >
              {renderPreviousArrow()}
              <Box>{title}</Box>
              {renderNextArrow()}
            </Typography>
          </Grid>

          <Grid item className="dq-flex dq-space-x-3 dq-items-center">
            {children}
            <User />
            <IconButton
              color="inherit"
              onClick={openMenu}
              disabled={lesson.is_shallow_import}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
      {renderMenu()}
      {renderCommentAlert()}
      {renderReleaseError()}
    </AppBar>
  );
};

export default LessonMenu;
