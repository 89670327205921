import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import UserInfoProvider from "./provider/UserInfoProvider";
import AppLayout from "./AppLayout";
import LessonList from "./LessonList";
import ReleaseList from "./ReleaseList";
import Screen from "./screens/Screen";
import Login from "./Login";
import DatasetVersionList from "./datasets/DatasetVersionList";
import ExternalAuthorManagement from "./ExternalAuthorManagement";
import "./App.css";
import DatasetList from "./datasets/DatasetList";
import LessonTests from "./lesson-tests/LessonTests";
import LessonComments from "./review/LessonComments";
import Debug from "./debug/Debug";
import CourseList from "./courses/CourseList";
import Course from "./courses/Course";
import store from "./store/index";
import TrackList from "./tracks/TrackList";
import Track from "./tracks/Track";
import Lesson from "./lessons/Lesson";

export default function App() {
  const theme = createTheme({
    // Generated via MUI docs at https://mui.com/material-ui/customization/color/#picking-colors
    palette: {
      primary: {
        light: "#757ce8",
        main: "#3f50b5",
        dark: "#002884",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <UserInfoProvider>
            <div className="App">
              <Switch>
                <Route exact path="/debug">
                  <AppLayout>
                    <Debug />
                  </AppLayout>
                </Route>
                <Route exact path="/">
                  <AppLayout>
                    <LessonList />
                  </AppLayout>
                </Route>
                <Route exact path="/releases">
                  <AppLayout>
                    <ReleaseList />
                  </AppLayout>
                </Route>
                <Route exact path="/courses">
                  <AppLayout>
                    <CourseList />
                  </AppLayout>
                </Route>
                <Route path="/course/:courseId" component={Course} />
                <Route exact path="/datasets">
                  <AppLayout>
                    <DatasetList />
                  </AppLayout>
                </Route>
                <Route exact path="/tracks">
                  <AppLayout>
                    <TrackList />
                  </AppLayout>
                </Route>
                <Route path="/track/:trackId" component={Track} />
                <Route
                  path="/datasets/:datasetId"
                  component={DatasetVersionList}
                />
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route path="/lesson/:lessonId/screen/:screenId">
                  <Screen />
                </Route>
                <Route path="/lesson/:lessonId/test" component={LessonTests} />
                <Route
                  path="/lesson/:lessonId/comments"
                  component={LessonComments}
                />
                <Route path="/lesson/:lessonId">
                  <Lesson />
                </Route>
                <Route
                  path="/manage-external"
                  component={ExternalAuthorManagement}
                />
              </Switch>
            </div>
          </UserInfoProvider>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}
