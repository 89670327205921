import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { lessonDataActions } from "../store/lessonDataSlice";
import { errorActions } from "../store/errorSlice";
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { CUSTOM_TEST_TAB, REMOTE_RUN_CODE_TAB } from "./code-tabs/constants";
import DataTable from "../DataTable";
import Zoom from "react-medium-image-zoom";

const MAX_TABLE_LENGTH = 40;

export default function OutputSection({ lesson, screen }) {
  const dispatch = useDispatch();

  const codeEditor = useSelector((state) => state.codeEditor)[screen.id];
  const currentTab = codeEditor.currentTab;

  let header = "Output";
  let output = { stdout: "", stderr: "", images: [] };
  if (currentTab === CUSTOM_TEST_TAB) {
    header = "Output (test)";
    output = codeEditor.customTest.output;
  } else if (currentTab === REMOTE_RUN_CODE_TAB) {
    header = "Application launch results";
    const outputData = codeEditor.runDeployResult;

    if (outputData === null) return null;

    if (outputData?.external_ip && outputData.ports.length) {
      const outputUrl = `http://${outputData.external_ip}:${outputData.ports[0]}`;
      output.stdout = (
        <span>
          Visit the deployed application at:{" "}
          <a href={outputUrl} target="_blank" className="dq-text-white">
            {outputUrl}
          </a>
        </span>
      );
    } else {
      output.stdout =
        "Something went wrong during application launch, please try again.";
    }
  } else if (Object.keys(codeEditor.runResult.output).length > 0) {
    header = "Output (unsaved)";
    output = codeEditor.runResult.output;
  } else {
    output = screen.answer_output;
  }
  if (!output?.stdout && !output?.stderr && !output?.images) return null;

  const { stdout, stderr, images } = output;

  const handleToggleCheckOutput = async () => {
    try {
      let output;
      if (Object.keys(codeEditor.runResult.output).length > 0) {
        output = codeEditor.runResult.output;
      } else if (screen.answer_output) {
        output = screen.answer_output;
      }
      const response = await fetch(
        `/api/lesson/${lesson.id}/screen/${screen.id}/output`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...output,
            check_output: !screen.check_output,
          }),
        }
      );
      const responseData = await response.json();
      if (responseData.error) {
        dispatch(
          errorActions.setScreenError({
            screenId: screen.id,
            error: responseData.error,
          })
        );
      } else {
        dispatch(
          lessonDataActions.setScreenData({
            screen: responseData,
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        errorActions.setScreenError({
          screenId: screen.id,
          error: "An error occurred while trying to toggle check out.",
        })
      );
    }
  };

  const renderCheckOutputToggle = () => {
    if (currentTab === CUSTOM_TEST_TAB || currentTab === REMOTE_RUN_CODE_TAB)
      return null;
    return (
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={screen.check_output}
              size="small"
              onChange={handleToggleCheckOutput}
              sx={{
                color: "white",
                "&.Mui-checked": {
                  color: "white",
                },
              }}
            />
          }
          label="Check output"
          labelPlacement="end"
        />
      </FormControl>
    );
  };

  const renderTableOutput = (output) => {
    let json;
    try {
      json = JSON.parse(output);
    } catch (e) {
      return (
        <Box className="output-text">
          No results. Query may have returned too many rows.
        </Box>
      );
    }
    if (!json) return null;
    const headers = json[0];
    const rows = json.slice(1, Math.min(json.length, MAX_TABLE_LENGTH));
    return (
      <Box className="editor-table-container">
        <Box className="output-text">
          [{rows.length} rows x {headers.length} columns]
        </Box>
        <DataTable headers={headers} rows={rows} />
      </Box>
    );
  };

  const renderTextOutput = (output) => {
    return <Box className="output-text">{output}</Box>;
  };

  const renderStdout = () => {
    if (!stdout) return null;
    if (lesson.language === "sql" && currentTab !== REMOTE_RUN_CODE_TAB) {
      return renderTableOutput(stdout);
    }
    return renderTextOutput(stdout);
  };

  const renderImages = () => {
    if (!images) return null;
    return (
      <div className="dq-py-4 dq-mx-2 dq-max-w-full">
        {images.map((src) => (
          <Zoom key={src}>
            <img src={src} className="dq-max-w-full dq-my-2" />
          </Zoom>
        ))}
      </div>
    );
  };

  const renderStderr = () => {
    if (!stderr) return null;
    return <Box className="output-text output-text-stderr">{stderr}</Box>;
  };

  return (
    <Box>
      <Box
        p={1}
        color="white"
        bgcolor="primary.main"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography>{header}</Typography>
        {renderCheckOutputToggle()}
      </Box>
      {renderStdout()}
      {renderImages()}
      {renderStderr()}
    </Box>
  );
}
