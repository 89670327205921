export function timestampToString(timestamp) {
  return new Date(timestamp).toLocaleString();
}

export function dateToSimpleString(date) {
  const zeroPadded = (num) => `0${num}`.slice(-2);
  const timePart = [date.getHours(), date.getMinutes(), date.getSeconds()]
    .map(zeroPadded)
    .join(":");
  const datePart = [date.getMonth() + 1, date.getDate()]
    .map(zeroPadded)
    .join("-");
  return `${datePart}-${date.getFullYear()} ${timePart}`;
}
