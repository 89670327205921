import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { CircularProgress, Backdrop } from "@mui/material";

export const UserInfoContext = React.createContext({});

const UserInfoProvider = ({ history, children }) => {
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch("/api/user")
      .then((response) => response.json())
      .then((user) => {
        if (!user.email) history.push("/login");

        setUserInfo({ ...user });
        setLoading(false);
      });
  }, []);

  if (loading)
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );

  return (
    <UserInfoContext.Provider value={{ userInfo }}>
      {children}
    </UserInfoContext.Provider>
  );
};

export default withRouter(UserInfoProvider);
