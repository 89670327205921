import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { getRunnerConnection } from "./api/codeRunning";

const AuthoringProjectPanels = ({ lesson_id }) => {
  const [connectionUrl, setConnectionUrl] = React.useState(null);
  const [connectionError, setConnectionError] = React.useState(true);

  const onGetRunnerConnectionSuccess = React.useCallback(
    (info) => {
      if (info?.url) setConnectionUrl(info.url);
      else setConnectionUrl(null);
    },
    [lesson_id]
  );

  const onGetRunnerConnectionError = React.useCallback((error) => {
    setConnectionError(true);
    console.error(error);
  }, []);

  const loadConnection = () => {
    if (!lesson_id) return;

    setConnectionError(false);
    getRunnerConnection(
      lesson_id,
      onGetRunnerConnectionSuccess,
      onGetRunnerConnectionError
    );
  };

  React.useEffect(() => {
    loadConnection();
  }, [lesson_id]);

  if (connectionError)
    return (
      <div
        className={cn(
          "dq-flex dq-flex-col dq-items-center dq-justify-center",
          "dq-bg-black dq-text-white dq-h-full dq-w-full"
        )}
      >
        <span className="dq-my-4">Error loading project.</span>
        <Button variant="contained" color="primary" onClick={loadConnection}>
          Retry
        </Button>
      </div>
    );

  if (!connectionUrl)
    return (
      <div
        className={cn(
          "dq-flex dq-flex-col dq-items-center dq-justify-center",
          "dq-bg-black dq-h-full dq-w-full"
        )}
      >
        <CircularProgress size={36} />
      </div>
    );

  return (
    <div className="dq-bg-black dq-flex dq-flex-col dq-h-full dq-w-full dq-absolute">
      <iframe
        src={connectionUrl}
        className="dq-h-full dq-w-full"
        frameBorder={0}
      />
    </div>
  );
};

AuthoringProjectPanels.propTypes = {
  lesson_id: PropTypes.string.isRequired,
};

export default AuthoringProjectPanels;
