import React from "react";
import { Select, MenuItem } from "@mui/material";
import { MODES } from "./constants";

function FileModeSelect(props) {
  return (
    <Select {...props}>
      {MODES.map((mode) => (
        <MenuItem key={mode} value={mode}>
          {mode}
        </MenuItem>
      ))}
    </Select>
  );
}

export default FileModeSelect;
