import React, { useState, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

import SearchableTable from "../general/SearchableTable";
import PropTypes from "prop-types";

import { fetchDatasetVersions } from "../api/datasets";

function DatasetVersionsModal(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [datasetVersions, setDatasetVersions] = useState([]);
  const [datasetName, setDatasetName] = useState("");
  const [selectedVersion, setSelectedVersion] = useState();

  const COLUMNS = [
    {
      key: "hash",
      label: "Github hash",
      type: "text",
    },
    {
      key: "files",
      label: "Files",
      type: "component",
      render: (datasetVersion) =>
        datasetVersion.files.map((file) => file.file_name).join(", "),
    },
    {
      key: "description",
      label: "Description",
      type: "text",
    },
    {
      key: "upload_date",
      label: "Upload date",
      type: "component",
      render: (datasetVersion) => {
        const date = new Date(datasetVersion.created);
        return date.toLocaleString();
      },
    },
  ];

  const FILTERS = [];

  const updatedSelectedVersion = (datasetVersion) => {
    setSelectedVersion(datasetVersion);
  };

  const canAdd = () => {
    return true;
  };

  const handleChangeVersion = () => {
    props.handleChangeDatasetVersion(props.datasetVersion, selectedVersion);
    props.close();
  };

  useEffect(() => {
    if (!props.datasetVersion) return;

    let isMounted = true;
    setIsLoading(true);
    fetchDatasetVersions(
      props.datasetVersion.dataset_id,
      (datasetVersions) => {
        if (!isMounted) return;
        setIsLoading(false);
        setDatasetName(datasetVersions[0].name);
        setDatasetVersions(
          datasetVersions.sort((d1, d2) => {
            const date1 = new Date(d1.created);
            const date2 = new Date(d2.created);
            return date1 < date2 ? 1 : date1 > date2 ? -1 : 0;
          })
        );
      },
      (error) => {
        if (!isMounted) return;
        setIsLoading(false);
        console.log(error);
      }
    );
    return () => {
      isMounted = false;
    };
  }, [props.datasetVersion]);

  return (
    <Dialog open={props.isOpen} onClose={props.close} maxWidth="lg" fullWidth>
      <DialogTitle align="center">{datasetName} dataset versions</DialogTitle>
      <DialogContent>
        <SearchableTable
          cols={COLUMNS}
          rows={datasetVersions}
          selectMode="single"
          isLoading={isLoading}
          filters={FILTERS}
          rowSelectionCallback={updatedSelectedVersion}
          rowsPerPage={5}
          initialSelection={
            props.datasetVersion ? [props.datasetVersion.id] : []
          }
        />
      </DialogContent>
      <DialogActions>
        <Grid
          container
          item
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={4}>
            <Button fullWidth variant="contained" onClick={() => props.close()}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              disabled={!canAdd()}
              color="primary"
              variant="contained"
              onClick={handleChangeVersion}
            >
              Change to this version
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

DatasetVersionsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

DatasetVersionsModal.defaultProps = {
  isOpen: false,
};

export default DatasetVersionsModal;
