import React, { useMemo } from "react";
import CodeMirrorTextArea from "../../code/CodeMirrorTextArea";
import { useDispatch } from "react-redux";
import { lessonDataActions } from "../../store/lessonDataSlice";
import AnswerPreview from "./AnswerPreview";

export default function LegacyCodeTab({ lesson, screen }) {
  const dispatch = useDispatch();

  const codeEditor = useMemo(
    () => (
      <CodeMirrorTextArea
        id={`codemirror-editor-custom-check-code`}
        value={screen.legacy_check_code || ""}
        language={lesson.language}
        setValue={(newCode) =>
          dispatch(
            lessonDataActions.updateScreenField({
              screenId: screen.id,
              field: "legacy_check_code",
              value: newCode,
            })
          )
        }
      >
        <AnswerPreview screen={screen} />
      </CodeMirrorTextArea>
    ),
    [lesson.language, screen.id]
  );

  return codeEditor;
}
