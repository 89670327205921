const STRING_TYPES = ["<class 'str'>", "str"];

const FLOAT_TYPES = ["<class 'float'>", "float"];

const LIST_REGEX = /^typing\.List\[.+\]$/;
const LIST_TYPE_REGEX = /\[.+\]/;

const DICT_REGEX = /^typing\.Dict\[.+,.+\]$/;
const DICT_TYPE_REGEX = /\[.+\]/;

export const getCheckerCode = (checkerName, checkerArguments) => {
  const argumentString = Object.keys(checkerArguments).map(
    (argName) => `${argName}=${checkerArguments[argName]}`
  );
  return `${checkerName}(${argumentString.join(",")})`;
};

export const escapeDoubleQuotes = (string) => {
  return string.replaceAll('"', '\\"');
};

export const makePythonString = (string) => {
  return `"${escapeDoubleQuotes(string)}"`;
};

export const getDictionaryTypes = (type) => {
  const match = type.match(DICT_TYPE_REGEX)[0];
  const types = match.substring(1, match.length - 1);
  const keyType = types.split(",")[0].trim();
  const valueType = types.split(",")[1].trim();
  return {
    keyType: keyType,
    valueType: valueType,
  };
};

export const getListValueType = (type) => {
  const match = type.match(LIST_TYPE_REGEX)[0];
  const valueType = match.substring(1, match.length - 1);
  return valueType;
};

export const isStringType = (type) => {
  return STRING_TYPES.includes(type);
};

export const isListType = (type) => {
  return LIST_REGEX.test(type);
};

export const isDictType = (type) => {
  return DICT_REGEX.test(type);
};

export const isFloatTolerance = (argument) => {
  return (
    FLOAT_TYPES.includes(argument.type) &&
    (argument.name.includes("eps") || argument.name.includes("tolerance"))
  );
};

export const getInitialValue = (argument) => {
  if (isFloatTolerance(argument)) return 4;
  return "";
};
