import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CircularProgress } from "@mui/material";

export default function DropdownButton(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (props.isLoading) {
    return (
      <Button
        variant="contained"
        color={props.color || "primary"}
        fullWidth
        disabled
      >
        <CircularProgress size={24} />
      </Button>
    );
  }

  return (
    <div>
      <Button
        fullWidth
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color={props.color || "primary"}
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
      >
        {props.buttonText}
      </Button>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {props.actions.map((action) => (
          <MenuItem
            key={action.name}
            onClick={() => {
              handleClose();
              action.action();
            }}
          >
            <ListItemText primary={action.name} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
