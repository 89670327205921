import React, { useState } from "react";
import { TextField } from "@mui/material";

function ListInputTextField(props) {
  const { onChange, ...otherProps } = props;

  const [value, setValue] = useState("");

  const handleChange = (event) => {
    const newValue = event.target.value.replace(/[^0-9 ,]/g, "");
    setValue(newValue);

    const newList = newValue
      .split(new RegExp("[, ][, ]*"))
      .filter((element) => element.length > 0)
      .map((element) => parseInt(element));

    if (onChange) {
      onChange(newList);
    }
  };

  return <TextField {...otherProps} onChange={handleChange} value={value} />;
}

export default ListInputTextField;
