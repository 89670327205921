import React from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import PropTypes from "prop-types";

import { Box, Paper } from "@mui/material";

function SimpleMarkdownEditor(props) {
  const updateCode = (newCode) => {
    props.updateCode(newCode);
  };

  const renderEditor = () => (
    <Box>
      <Paper variant="outlined">
        <CodeMirror
          value={props.code || props.placeholder}
          options={{
            mode: "markdown",
            lineNumbers: false,
          }}
          onBeforeChange={(editor, data, value) => updateCode(value)}
        />
      </Paper>
    </Box>
  );

  const renderContent = () => (
    <Box>
      <ReactMarkdown children={props.code} remarkPlugins={[remarkGfm]} />
    </Box>
  );

  return (
    <Box>
      <Box m={1}>{props.isPreview ? renderContent() : renderEditor()}</Box>
    </Box>
  );
}

SimpleMarkdownEditor.propTypes = {
  code: PropTypes.string.isRequired,
  updateCode: PropTypes.func.isRequired,
  isPreview: PropTypes.bool,
};

SimpleMarkdownEditor.defaultProps = {
  isPreview: false,
};

export default SimpleMarkdownEditor;
