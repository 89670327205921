import React, { useState, useEffect } from "react";

import {
  Alert,
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Grid,
  Container,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchableTable from "../general/SearchableTable";

import { fetchLessonTests } from "../api/tests";
import { Link } from "react-router-dom";

import { runScreenTest } from "../api/codeRunning";

const booleanToYesNo = (booleanValue) => {
  return booleanValue ? "Yes" : "No";
};

const COLUMNS = [
  {
    key: "description",
    label: "Description",
    type: "text",
    default: "No description",
  },
  {
    key: "screen_sequence",
    label: "Screen",
    type: "text",
  },
  {
    key: "is_boolean_test",
    label: "Is default test?",
    type: "component",
    render: (screenTest) => booleanToYesNo(screenTest.is_default),
  },
  {
    key: "is_boolean_test",
    label: "Is boolean?",
    type: "component",
    render: (screenTest) => booleanToYesNo(screenTest.is_boolean_test),
  },
  {
    key: "timestamp",
    label: "Last Run",
    type: "component",
    render: (screenTest) => {
      if (!screenTest.test_result.timestamp) return "";
      const date = new Date(Date.parse(screenTest.test_result.timestamp));
      return date.toLocaleString();
    },
  },
  {
    key: "test_passed",
    label: "Test ok?",
    type: "component",
    render: (screenTest) => booleanToYesNo(screenTest.test_result.test_passes),
  },
  {
    key: "message",
    label: "Failure Reason",
    type: "component",
    render: (screenTest) => screenTest.test_result.message || "",
  },
  {
    key: "test_result",
    label: "Run Status",
    type: "component",
    render: (screenTest) => screenTest.status,
  },
  {
    key: "",
    label: "",
    type: "component",
    render: (screenTest) => (
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to={`/lesson/${screenTest.lesson_id}/screen/${screenTest.screen_sequence}`}
      >
        View screen
      </Button>
    ),
  },
];

const FILTERS = [
  {
    label: "failed only",
    filter: (screenTest) =>
      screenTest.test_result && !screenTest.test_result.test_passes,
    startsActive: false,
  },
];

function LessonTests(props) {
  const lessonId = props.match.params.lessonId;

  const [isLoading, setIsLoading] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [currentTestIndex, setCurrentTestIndex] = useState(-1);
  const [lessonTests, setLessonTests] = useState([]);

  const handleRun = () => {
    setIsRunning(true);
    setTestStatusAll("Scheduled to run");
    setCurrentTestIndex(0);
  };

  const setTestStatusAll = (newStatus) => {
    setLessonTests(
      lessonTests.map((lessonTest, index) => ({
        ...lessonTest,
        status: newStatus,
      }))
    );
  };

  const updateTestStatus = (testIndex, newStatus) => {
    setLessonTests(
      lessonTests.map((lessonTest, index) => {
        if (testIndex === index) {
          return {
            ...lessonTest,
            status: newStatus,
          };
        } else {
          return lessonTest;
        }
      })
    );
  };

  const updateTestResult = (testIndex, newResult) => {
    setLessonTests(
      lessonTests.map((lessonTest, index) => {
        if (testIndex === index) {
          return {
            ...lessonTest,
            status: "Done",
            test_result: newResult,
          };
        } else {
          return lessonTest;
        }
      })
    );
  };

  const renderAlert = () => {
    if (isRunning || isLoading) return null;

    const testsWithResults = lessonTests.filter(
      (lessonTest) => lessonTest.test_result
    );
    if (testsWithResults.length !== lessonTests.length)
      return <Alert severity="info">Some tests weren't executed yet</Alert>;

    const failedTests = lessonTests.filter(
      (lessonTest) => !lessonTest.test_result.test_passes
    );
    if (failedTests.length > 0)
      return <Alert severity="warning">Some tests failed</Alert>;
    return <Alert severity="success">All tests passed!</Alert>;
  };

  useEffect(() => {
    let isMounted = true;
    if (currentTestIndex === -1) return;
    if (!lessonTests) return;
    if (currentTestIndex === lessonTests.length) {
      setIsRunning(false);
      setCurrentTestIndex(-1);
      return;
    }

    updateTestStatus(currentTestIndex, "Running");

    const currentTest = lessonTests[currentTestIndex];
    runScreenTest(
      currentTest.id,
      (result) => {
        if (!isMounted) return;
        if (result.error) {
          updateTestStatus(currentTestIndex, "Run Error");
          setCurrentTestIndex(currentTestIndex + 1);
        } else {
          updateTestResult(currentTestIndex, result);
          setCurrentTestIndex(currentTestIndex + 1);
        }
      },
      (error) => {
        console.log(error);
        if (!isMounted) return;
        updateTestStatus(currentTestIndex, "Internal Error");
        setCurrentTestIndex(currentTestIndex + 1);
      }
    );
    return () => {
      isMounted = false;
    };
  }, [currentTestIndex]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    fetchLessonTests(
      lessonId,
      (lessonTests) => {
        if (!isMounted) return;
        lessonTests = lessonTests.map((lessonTest) => ({
          ...lessonTest,
          status: "Not scheduled",
        }));
        setIsLoading(false);
        setLessonTests(lessonTests);
      },
      (error) => {
        if (!isMounted) return;
        setIsLoading(false);
        console.log(error);
      }
    );
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div>
      <AppBar position="static">
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Button
              style={{ color: "white" }}
              startIcon={<ArrowBackIcon />}
              component={Link}
              to={`/lesson/${lessonId}`}
            >
              Back to lesson
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Container>
        <Box my={5}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography variant="h4">Lesson Tests</Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleRun}
                disabled={isRunning}
              >
                {isRunning ? "Running..." : "Run Tests"}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box>{renderAlert()}</Box>
        {
          <SearchableTable
            sortable
            filters={FILTERS}
            cols={COLUMNS}
            rows={lessonTests}
            isLoading={isLoading}
            rowsPerPage={10}
          />
        }
      </Container>
    </div>
  );
}

export default LessonTests;
