import React, { useState } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import { Typography } from "@mui/material";

export default function CodeInput(props) {
  const updateCode = (newCode) => {
    newCode = newCode.replace(/(\r\n|\n|\r)/gm, "");
    props.valueUpdatedCallback(newCode, newCode);
  };

  return (
    <>
      <Typography variant="caption" color="primary">
        {props.label}
      </Typography>
      <CodeMirror
        value={props.value}
        options={{
          mode: "python",
          theme: "monokai",
        }}
        onBeforeChange={(editor, data, newCode) => updateCode(newCode)}
      />
    </>
  );
}
