import React, { useState, useEffect } from "react";
import fileSaver from "file-saver";

import { Box, Button, Grid, Container, Typography } from "@mui/material";

import SearchableTable from "../general/SearchableTable";
import DropdownButton from "../general/DropdownButton";
import DatasetUploadModal from "./DatasetUploadModal";

import {
  fetchDatasets,
  downloadDataset,
  archiveDataset,
  unarchiveDataset,
} from "../api/datasets";
import { useHistory } from "react-router-dom";

const FILTERS = [
  {
    label: "hide archived",
    filter: (dataset) => !dataset.archived,
    startsActive: true,
  },
];

function DatasetList(props) {
  let history = useHistory();

  const [isDownloading, setIsDownloading] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [datasets, setDatasets] = useState([]);
  const [datasetModalState, setDatasetModalState] = useState({
    isOpen: false,
    datasetId: null,
  });

  const COLUMNS = [
    {
      key: "name",
      label: "Name",
      type: "text",
    },
    {
      key: "description",
      label: "Description",
      type: "text",
    },
    {
      key: "lessons",
      label: "Lessons using latest version",
      type: "component",
      render: (dataset, col) => dataset.lessons.join(", "),
    },
    {
      key: "latest_version",
      label: "Latest version #",
      type: "component",
      render: (dataset, col) => dataset.latest_version_hash,
    },
    {
      key: "version_count",
      label: "Number of versions",
      type: "component",
      render: (dataset, col) => dataset.version_count,
    },
    {
      key: "last_update",
      label: "Last update",
      type: "component",
      render: (dataset, col) => {
        const date = new Date(dataset.last_update);
        return date.toLocaleString();
      },
    },
    {
      key: "action",
      label: "",
      type: "component",
      render: (dataset, col) => {
        if (dataset.archived) {
          return (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleUnarchive(dataset)}
            >
              Unarchive
            </Button>
          );
        }
        return (
          <DropdownButton
            buttonText={"Action"}
            isLoading={isDownloading[dataset.id]}
            actions={[
              { name: "Download", action: () => handleDownload(dataset) },
              {
                name: "Upload new version",
                action: () =>
                  setDatasetModalState({ isOpen: true, datasetId: dataset.id }),
              },
              {
                name: "View version history",
                action: () => history.push(`/datasets/${dataset.id}`),
              },
              { name: "Archive", action: () => handleArchive(dataset) },
            ]}
          />
        );
      },
    },
  ];

  const handleUnarchive = (dataset) => {
    setIsLoading(true);
    unarchiveDataset(
      dataset.id,
      () => {
        updateDatasetList();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleArchive = (dataset) => {
    setIsLoading(true);
    archiveDataset(
      dataset.id,
      () => {
        updateDatasetList();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleDownload = (dataset) => {
    setIsDownloading({ ...isDownloading, [dataset.id]: true });
    downloadDataset(
      dataset.id,
      dataset.latest_version_id,
      (datasetBlob) => {
        setIsDownloading({ ...isDownloading, [dataset.id]: false });
        fileSaver.saveAs(datasetBlob, `${dataset.name}.zip`);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const updateDatasetList = () => {
    setIsLoading(true);
    fetchDatasets(
      (datasets) => {
        setIsLoading(false);
        setDatasets(datasets);
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  useEffect(() => {
    updateDatasetList();
  }, []);

  return (
    <Container>
      <DatasetUploadModal
        close={() => setDatasetModalState({ isOpen: false, datasetId: null })}
        isOpen={datasetModalState.isOpen}
        successCallback={updateDatasetList}
        datasetId={datasetModalState.datasetId}
      />

      <Box my={5}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="h4">Datasets</Typography>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                setDatasetModalState({ isOpen: true, datasetId: null })
              }
            >
              New Dataset
            </Button>
          </Grid>
        </Grid>
      </Box>

      <SearchableTable
        cols={COLUMNS}
        rows={datasets}
        isLoading={isLoading}
        filters={FILTERS}
        rowsPerPage={10}
      />
    </Container>
  );
}

export default DatasetList;
