import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { codeEditorActions } from "../store/codeEditorSlice";

const withCodeEditor = (WrappedComponent) => {
  function WithCodeEditor(props) {
    const dispatch = useDispatch();
    const params = useParams();
    const screenId = params.screenId;

    const codeEditor = useSelector((state) => state.codeEditor)[screenId];

    useEffect(() => {
      if (!codeEditor) {
        dispatch(codeEditorActions.initializeScreen(screenId));
      }
    }, [codeEditor]);

    if (!codeEditor) return null;

    return <WrappedComponent {...props} codeEditor={codeEditor} />;
  }

  return WithCodeEditor;
};

export default withCodeEditor;
