import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Box, Button } from "@mui/material";
import GoogleAuthButton from "./GoogleAuthButton";

class Login extends Component {
  state = {
    email: "",
    external: true,
    error: "",
  };

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser() {
    fetch("/api/user")
      .then((response) => response.json())
      .then((user) => {
        this.setState({ email: user.email, external: user.external });
      });
  }

  onSuccess = () => {
    this.props.history.push("/");
  };

  onFailure = (error) => {
    this.setState({ error });
  };

  renderLoginLogout() {
    if (this.state.email) {
      return (
        <Box className="logged-in-menu">
          <Box p={2}>Logged in as {this.state.email}</Box>
          <Button
            onClick={() => {
              return fetch("/api/logout").then(() => this.fetchUser());
            }}
          >
            Log Out
          </Button>
          <Button onClick={() => this.props.history.push("/")}>
            Lesson list
          </Button>
          {!this.state.external && (
            <Button onClick={() => this.props.history.push("/manage-external")}>
              Manage External Authors
            </Button>
          )}
        </Box>
      );
    }
    return (
      <GoogleAuthButton onSuccess={this.onSuccess} onFailure={this.onFailure} />
    );
  }

  renderError() {
    if (!this.state.error) return null;

    return (
      <Box
        textAlign="center"
        p={2}
        width="400px"
        color="white"
        borderRadius={8}
        bgcolor="error.light"
      >
        Error: {this.state.error}
      </Box>
    );
  }

  render() {
    return (
      <Box className="login">
        {this.renderError()}
        {this.renderLoginLogout()}
      </Box>
    );
  }
}

export default withRouter(Login);
