import React from "react";
import PropTypes from "prop-types";
import { Button, Tooltip, IconButton } from "@mui/material";

function TooltipButton(props) {
  const { title, isIconButton, ...buttonProps } = props;

  const renderButton = () => {
    if (isIconButton) {
      return <IconButton {...buttonProps}>{props.children}</IconButton>;
    }
    return <Button {...buttonProps}>{props.children}</Button>;
  };

  if (props.disabled) {
    return renderButton();
  }

  return <Tooltip title={props.title}>{renderButton()}</Tooltip>;
}

TooltipButton.propTypes = {
  title: PropTypes.string.isRequired,
  isIconButton: PropTypes.bool,
};

TooltipButton.defaultProps = {
  isIconButton: false,
};

export default TooltipButton;
