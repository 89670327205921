import React, { useState, useEffect, useCallback } from "react";
import Modal from "./Modal";
import { Stack, Button, Select, MenuItem, Alert } from "@mui/material";
import Loader from "./Loader";

export default function CopyModal(props) {
  const [sequences, setSequences] = useState([]);
  const [loadingReleases, setLoadingReleases] = useState(false);
  const [error, setError] = useState("");
  const [releases, setReleases] = useState([]);
  const [selectedRelease, setSelectedRelease] = useState("");
  const [copying, setCopying] = useState(false);

  const loadReleases = useCallback(async () => {
    setLoadingReleases(true);
    setError("");
    try {
      const response = await fetch(`/api/releases`);
      const releases = await response.json();
      if (releases.error) {
        setError(releases.error);
      } else {
        setReleases(releases);
      }
    } catch (error) {
      setError("Could not load releases. Try again later.");
    }
    setLoadingReleases(false);
  }, []);

  const handleCopy = async () => {
    setCopying(true);
    setError("");
    try {
      const response = await props.copy(selectedRelease);
      const data = await response.json();
      if (data.error) {
        setError(data.error);
      } else {
        props.closeHandler();
      }
    } catch {
      setError("An error occured while copying. Try again later.");
    }
    setCopying(false);
  };

  useEffect(() => {
    loadReleases();
  }, [loadReleases]);

  useEffect(() => {
    if (!props.isOpen) {
      setSequences([]);
      setLoadingReleases(false);
      setError("");
    }
  }, [props.isOpen]);

  const renderReleases = () => {
    if (loadingReleases) {
      return <Loader loadMessage="Loading releases..." />;
    }
    return (
      <Select
        value={selectedRelease}
        onChange={(event) => setSelectedRelease(event.target.value)}
        disabled={copying}
      >
        <MenuItem value={""} disabled={true}>
          Select Release
        </MenuItem>
        {releases.map((release) => (
          <MenuItem key={release.id} value={release.id}>
            {release.name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderContent = () => (
    <Stack spacing={1}>
      {error && <Alert severity="error">{error}</Alert>}
      {props.renderObjectToCopy()}
      {renderReleases()}
    </Stack>
  );

  const renderActions = () => (
    <Stack direction="row" justifyContent="space-between" flexGrow={1}>
      <Button color="secondary" variant="contained">
        Cancel
      </Button>
      <Button
        color="primary"
        variant="contained"
        disabled={sequences.length === 0 || selectedRelease === ""}
        onClick={handleCopy}
        disabled={copying}
      >
        Copy
      </Button>
    </Stack>
  );

  return (
    <Modal
      isOpen={props.isOpen}
      closeHandler={props.closeHandler}
      title={props.title || "Import"}
      content={renderContent()}
      actions={renderActions()}
    />
  );
}
