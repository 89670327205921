import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import PropTypes from "prop-types";

import { Box, Paper, TextField } from "@mui/material";

function TextFieldMarkdownEditor(props) {
  const updateCode = (newCode) => {
    props.updateCode(newCode);
  };

  const renderEditor = () => (
    <Box>
      <Paper variant="outlined">
        <TextField
          variant="outlined"
          label={props.label}
          fullWidth
          multiline
          value={props.code}
          onChange={(event) => updateCode(event.target.value)}
        />
      </Paper>
    </Box>
  );

  const renderContent = () => (
    <Box>
      <ReactMarkdown children={props.code} remarkPlugins={[remarkGfm]} />
    </Box>
  );

  return (
    <Box>
      <Box m={1}>{props.isPreview ? renderContent() : renderEditor()}</Box>
    </Box>
  );
}

TextFieldMarkdownEditor.propTypes = {
  code: PropTypes.string.isRequired,
  updateCode: PropTypes.func.isRequired,
  isPreview: PropTypes.bool,
  label: PropTypes.string,
};

TextFieldMarkdownEditor.defaultProps = {
  isPreview: false,
  label: "",
};

export default TextFieldMarkdownEditor;
